import axios from "axios";
import { useState } from "react";

const useGetTest = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);

  const fetchTest = async (id) => {
    try {
      const res = await axios.get(
        `https://55aaqcxwk7.execute-api.ap-south-1.amazonaws.com/dev/library/get_test/${id}/`
      );
      return res?.data;
    } catch (error) {
      console.error("Error fetching response:", error);
    }
  };

  return { fetchTest, isDataLoading, setIsDataLoading };
};

export default useGetTest;
