import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Container, Flex, Spinner } from "@chakra-ui/react";
import H1 from "../../Components/Typography/Heading/H1";
import H3 from "../../Components/Typography/Heading/H3";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import ButtonCheckboxPrimary from "../../Components/Button/ButtonCheckboxPrimary";

import axios from "axios";
import {NEW_API_URL} from "../../redux/types";

const SurveryQuestions = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [fetchLoading, setFetchLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [userResponse, setUserResponse] = useState([]);

    useEffect(() => {
      fetchQuestions();
    }, [])

    const fetchQuestions = async () => {
      try {
        setFetchLoading(true);
        await axios
          .get(NEW_API_URL+"community/user_profile_survey/"+location.state.username)
          .then((response) => setQuestions(response.data.survey_questions))
          .catch((e) => console.error(e));
      } catch (e) {
        console.error(e);
      } finally {
        setFetchLoading(false);
      }
    };

    const handleOptionSelect = (questionId, optionId) => {
      // console.log(questionId, optionId);
      const newResponses = [...userResponse];
      const existingResponseIndex = newResponses.findIndex(
        (response) => response.id === questionId
      );

      if (existingResponseIndex !== -1) {
        newResponses[existingResponseIndex].answer = optionId;
      } else {
        newResponses.push({ id: questionId, answer: optionId });
      }
      // console.log(newResponses);
      setUserResponse(newResponses);
    };

    const handleSubmit = () => {
      const sortedResponses = userResponse.sort((a, b) => a.id - b.id);
      console.log(sortedResponses);
      navigate("/join-feed", {
        state: {
          username: location.state.username,
          password: location.state.password,
        },
      });
    };

    const styles = {
      heading: {
        color: "claydeck.brand.primary",
        textAlign: "center",
        fontSize: "32px",
        margin: "16px 0px 32px 0px",
      },
      question: {
        fontSize: "18px",
        letterSpacing: "0.2px",
        marginTop: "32px",
        marginBottom: "8px",
      },
      optionContainer: {
        alignItems: "center",
        paddingX: "12px",
        marginY: "3px",
      },
      optionText: {
        color: "claydeck.text.tertiary",
        fontSize: "16px",
        letterSpacing: "0.1px",
      },
      submitBtn: {
        h: "42px",
        w: "150px",
        color: "#fff",
        bgColor:
          userResponse.length === questions.length ? "#6750A4" : "#ebdef8",
      },
    };

    return (
      <Box bgColor={"claydeck.brand.secondary"} paddingY={"64px"}>
        <Container
          className="shadow-primary"
          maxW={"750px"}
          bgColor={"claydeck.white.primary"}
          borderRadius={"6px"}
          paddingX={"32px"}
          paddingY={"24px"}
        >
          <H1 sx={styles.heading}>Survery Questions</H1>
          {fetchLoading ? (
            <Flex justify={"center"}>
              <Spinner />
            </Flex>
          ) : (
            questions.map((question, index) => (
              <Box style={{ marginBottom: 10 }} key={index}>
                <H3 sx={styles.question}>{question.question}</H3>

                  <Flex sx={styles.optionContainer} key={index}>
                      <ButtonCheckboxPrimary
                          bgColor={"rgba(0,0,0,0)"}
                          checked={userResponse.find(
                              (response) =>
                                  response.id === index &&
                                  response.answer === 'optiona'
                          )}
                          onClick={() => handleOptionSelect(index, 'optiona')}
                      />
                      <Pmd sx={styles.optionText}>{question['optiona']}</Pmd>
                  </Flex>

                  <Flex sx={styles.optionContainer} key={index}>
                      <ButtonCheckboxPrimary
                          bgColor={"rgba(0,0,0,0)"}
                          checked={userResponse.find(
                              (response) =>
                                  response.id === index &&
                                  response.answer === 'optionb'
                          )}
                          onClick={() => handleOptionSelect(index, 'optionb')}
                      />
                      <Pmd sx={styles.optionText}>{question['optionb']}</Pmd>
                  </Flex>

                  <Flex sx={styles.optionContainer} key={index}>
                      <ButtonCheckboxPrimary
                          bgColor={"rgba(0,0,0,0)"}
                          checked={userResponse.find(
                              (response) =>
                                  response.id === index &&
                                  response.answer === 'optionc'
                          )}
                          onClick={() => handleOptionSelect(index, 'optionc')}
                      />
                      <Pmd sx={styles.optionText}>{question['optionc']}</Pmd>
                  </Flex>

                  <Flex sx={styles.optionContainer} key={index}>
                      <ButtonCheckboxPrimary
                          bgColor={"rgba(0,0,0,0)"}
                          checked={userResponse.find(
                              (response) =>
                                  response.id === index &&
                                  response.answer === 'optiond'
                          )}
                          onClick={() => handleOptionSelect(index, 'optiond')}
                      />
                      <Pmd sx={styles.optionText}>{question['optiond']}</Pmd>
                  </Flex>


                {/*{question.options.map((option, index) => (*/}
                {/*  <Flex sx={styles.optionContainer} key={index}>*/}
                {/*    <ButtonCheckboxPrimary*/}
                {/*      bgColor={"rgba(0,0,0,0)"}*/}
                {/*      checked={userResponse.find(*/}
                {/*        (response) =>*/}
                {/*          response.id === question.id &&*/}
                {/*          response.answer === option.id*/}
                {/*      )}*/}
                {/*      onClick={() => handleOptionSelect(question.id, option.id)}*/}
                {/*    />*/}
                {/*    <Pmd sx={styles.optionText}>{option.option}</Pmd>*/}
                {/*  </Flex>*/}
                {/*))}*/}
              </Box>
            ))
          )}
          <Flex justify={"flex-end"} marginTop="32px">
            <ButtonSecondary
              sx={styles.submitBtn}
              // disabled={userResponse.length === questions.length}
              onClick={() => handleSubmit()}
            >
              Submit
            </ButtonSecondary>
          </Flex>
        </Container>
      </Box>
    );
}

export default SurveryQuestions;