import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";

import {
    Avatar,
    Box,
    Button, Center,
    Container,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Select, Table, TableCaption, TableContainer,
    Tag, Tbody, Td, Th, Thead, Tr, useDisclosure,
    VStack,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import {useNavigate, useParams} from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import {ArrowBackIcon, CloseIcon} from "@chakra-ui/icons";
import Plg from "../../Components/Typography/Text/Plg";
import {quiz_map} from "../../data/new_generated/quiz_map";
import {quiz_details} from "../../data/new_generated/quiz_details";
import AskBudAccent from "../../Components/AskBudAccent";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
const TestsQuiz = () => {
    const {topic,subject,area,noteid} = useParams();
    const username = useSelector(state => state.user.username);

  // const [tab,setTab] = useState('personalized');
  const [quizData,setQuizData] = useState(undefined);
  const [quizDataSelected,setQuizDataSelected] = useState({});
  const [currentQuestion,setCurrentQuestion] = useState(0);
  const [hintOpen,setHintOpen] = useState(false);
  const [isPersonalTest,setIsPersonalTest] = useState(false);
  const [resultsOpen,setResultsOpen] = useState(false);
  const [score,setScore] = useState(false);
  const [isPersonal,setIsPersonal] = useState(true);
  const [pageTimes,setPageTimes] = useState([]);
  const [pageStartTime,setPageStartTime] = useState(Date.now());

    const navigate = useNavigate()

  const isCorrect = (question_idx) => {
      if(quizDataSelected[question_idx] && quizData?.questions[question_idx].answer.toLowerCase() === quizDataSelected[question_idx].toLowerCase()){
          return true
      }
      return false
  }


  const getQuizDataBgColor = (question_idx) => {
      if(isCorrect(question_idx) && isPersonal){
          return 'green'
      }
      else if(isPersonal){
          return 'red'
      }

      return '#0d6efd'
  }


    const getTestDetails = async (id, username) => {
        try {
            // console.log(${API_URL}/all_quizes/quiz_details/${id}/1/${username});
            const res = await axios.get(`${NEW_API_URL}all_quizes/quiz_details/${id}/1/${username}`);
            setQuizData(res.data)
            return res?.data;
        } catch (error) {
            console.error("Error fetching quiz data for id:", id, error);
            throw error;
        }
    }

    const submitTest = async (id, username,score) => {
        try {
            let ans = []
            for(let i =0;i<quizData.questions.length;i++){
                // ans.push()
                ans[i] = {question_id:quizData.questions[i].question_id,answer:quizDataSelected[i]||'',time_taken:pageTimes[i]+'s'}
            }


            // console.log(${API_URL}/all_quizes/quiz_details/${id}/1/${username});
            let body = {
                "quiz_id": noteid,
                "user_id": username,
                "topic_id":topic||null,
                "quiz_type":quizData.quiz_type,
                "max_score": 2 * quizData.questions.length,
                "read_status": "read",
                "score": score,
                "answers": ans
            }

            const res = await axios.post(`${NEW_API_URL}all_quizes/quiz-map-tracking/`,body);
            // setQuizData(res.data)
            return res?.data;
        } catch (error) {
            console.error("Error fetching quiz data for id:", id, error);
            throw error;
        }
    }

    // let pageStartTime = Date.now();
    useEffect(()=>{
        getTestDetails(noteid,username)
    },[])


    const { isOpen, onOpen, onClose } = useDisclosure()
    function getTotalTime() {
        return pageTimes.reduce((total, time) => total + time, 0);
    }
    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }


    return (
      <>
        <Container  justifyContent={'center'} alignItems={'center'} w={'95%'} maxW='container.xl' >
          <Flex flexDirection={'column'} alignItems={'space-between'} justifyContent={'space-between'} minH={'95vh'}>
            <Box>
              <Flex mt={'10vh'} alignItems={'center'}>
                  <ArrowBackIcon cursor={'pointer'} onClick={()=>{navigate(-1)}} marginLeft={'-65px'} marginRight={'20px'} boxSize={'45px'}/>


                <Box>
                  <P2xl mb={0} ml={0} mr={0}  >
                    <strong>Test</strong>

                  </P2xl>
                </Box>
              </Flex>

              <hr/>


              <Container maxW={'70%'}>

                  {isPersonal && !resultsOpen && <Flex w={'100%'} justifyContent={'flex-end'}>
                      <ButtonPrimary bg={'none'} border={'1px solid #0962A2'} onClick={() => {
                          // setHintOpen(true)
                          onOpen()
                      }}>
                          <Flex alignItems={'center'}>
                              <Image h={'20px'} w={'20px'} src={'/hintai.png'}></Image>
                              <Psm m={0} color={'#0962A2'} ml={'5px'}>
                                  Get Hint
                              </Psm>
                          </Flex>
                      </ButtonPrimary>

                  </Flex>}
                  {!resultsOpen && <Center mt={12} bgColor={'white'} position={'relative'} borderRadius={'12px'} minH={'180px'}>
                      <Center position={'absolute'} top={'-30px'} bgColor={'white'} fontWeight={'300'} h={'64px'}
                              w={'64px'} borderRadius={'50%'} boxShadow={'0px 2px 2px 0px #00000040'}>
                          {currentQuestion + 1}/{quizData?.questions?.length}
                      </Center>
                      <Pxl m={0} fontWeight={'400'} style={{padding:'20px'}}>{quizData?.questions[currentQuestion].question}</Pxl>

                  </Center>

                  }
                {!hintOpen && !resultsOpen && <>
                  <Flex alignItems={'center'} justifyContent={'space-around'} mt={'20px'}>
                    <Flex cursor={'pointer'} alignItems={'center'} w={'45%'} bgColor={quizDataSelected[currentQuestion] === 'A' ? getQuizDataBgColor(currentQuestion) :'white'}  borderRadius={'100px'} padding={'12px'}
                          boxShadow={'0px 2px 2px 0px #00000040'}
                          onClick={()=>{
                              let cpy = JSON.parse(JSON.stringify(quizDataSelected))
                              cpy[currentQuestion] = 'A'
                              setQuizDataSelected(cpy)
                          }}
                    >
                      <Box ml={'12px'} borderRadius={'50px'}>
                        <Pxl color={quizDataSelected[currentQuestion] === 'A' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>
                          A
                        </Pxl>
                      </Box>
                      <Box w={'100%'}>

                        <Pxl color={quizDataSelected[currentQuestion] === 'A' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>{
                          quizData?.questions[currentQuestion].option_a
                        }</Pxl>

                      </Box>

                    </Flex>
                    <Flex cursor={'pointer'} alignItems={'center'} w={'45%'}  borderRadius={'100px'} padding={'12px'} bgColor={quizDataSelected[currentQuestion] === 'B' ? getQuizDataBgColor(currentQuestion) :'white'}
                          boxShadow={'0px 2px 2px 0px #00000040'}
                          onClick={()=>{
                              let cpy = JSON.parse(JSON.stringify(quizDataSelected))
                              cpy[currentQuestion] = 'B'
                              setQuizDataSelected(cpy)
                          }}
                    >
                      <Box ml={'12px'} borderRadius={'50px'}>
                        <Pxl color={quizDataSelected[currentQuestion] === 'B' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>
                          B
                        </Pxl>
                      </Box>
                      <Box w={'100%'}>

                        <Pxl color={quizDataSelected[currentQuestion] === 'B' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>{
                          quizData?.questions[currentQuestion].option_b
                        }</Pxl>

                      </Box>

                    </Flex>

                  </Flex>

                  <Flex alignItems={'center'} justifyContent={'space-around'} mt={'20px'}>
                    <Flex cursor={'pointer'} alignItems={'center'} w={'45%'} bgColor={quizDataSelected[currentQuestion] === 'C' ? getQuizDataBgColor(currentQuestion) :'white'}  borderRadius={'100px'} padding={'12px'}
                          boxShadow={'0px 2px 2px 0px #00000040'}
                          onClick={()=>{
                              let cpy = JSON.parse(JSON.stringify(quizDataSelected))
                              cpy[currentQuestion] = 'C'
                              setQuizDataSelected(cpy)
                          }}
                    >
                      <Box ml={'12px'} borderRadius={'50px'}>
                        <Pxl color={quizDataSelected[currentQuestion] === 'C' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>
                          C
                        </Pxl>
                      </Box>
                      <Box w={'100%'}>

                        <Pxl color={quizDataSelected[currentQuestion] === 'C' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>{
                          quizData?.questions[currentQuestion].option_c
                        }</Pxl>

                      </Box>

                    </Flex>
                    <Flex cursor={'pointer'} alignItems={'center'} w={'45%'} bgColor={quizDataSelected[currentQuestion] === 'D' ? getQuizDataBgColor(currentQuestion):'white'}  borderRadius={'100px'} padding={'12px'}
                          boxShadow={'0px 2px 2px 0px #00000040'} onClick={()=>{
                              let cpy = JSON.parse(JSON.stringify(quizDataSelected))
                            cpy[currentQuestion] = 'D'
                              setQuizDataSelected(cpy)
                          }}
                    >
                      <Box ml={'12px'} borderRadius={'50px'}>
                        <Pxl color={quizDataSelected[currentQuestion] === 'D' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>
                          D
                        </Pxl>
                      </Box>
                      <Box w={'100%'}>

                        <Pxl color={quizDataSelected[currentQuestion] === 'D' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>{
                          quizData?.questions[currentQuestion].option_d
                        }</Pxl>

                      </Box>

                    </Flex>

                  </Flex>
                </>}


                {
                  hintOpen && <>
                  <Center w={'100%'}>
                      <Center bgColor={'white'} w={'80%'} mr={'20px'}  flexDirection={'column'} mt={'30px'} p={'12px'} boxShadow={'0px 3px 3px 0px #00000040'}
                              borderRadius={'12px'} borderTop={'4px solid #2A6291'}>
                        <Pxl>Hint</Pxl>
                        <Plg><strong>Related Concepts:</strong></Plg>
                        {
                          quizData?.questions[currentQuestion].hint_concepts.map((item,idx)=>(
                              <Plg color='#2A6291' key={idx}>{item.name}</Plg>
                          ))
                        }
                        <Plg><strong>Related News:</strong></Plg>
                        {
                          quizData?.questions[currentQuestion].hint_news.map((item,idx)=>(
                              <Plg color='#2A6291' key={idx}>{item.name}</Plg>
                          ))
                        }
                      </Center>
                  </Center>

                    </>
                }


                  {!resultsOpen && <Flex alignItems={'center'} justifyContent={'space-around'} mt={'60px'}>
                      {currentQuestion > 0 && <ButtonPrimary  minWidth={'180px'} onClick={() => {

                          const currentTime = Date.now();
                          const timeSpent = Math.floor((currentTime - pageStartTime) / 1000);

                          let cp = JSON.parse(JSON.stringify(pageTimes))
                          cp[currentQuestion] = (cp[currentQuestion] || 0) + timeSpent

                          setPageTimes(cp)
                          // pageStartTime = currentTime

                          setPageStartTime(currentTime)

                          setCurrentQuestion(currentQuestion - 1)
                      }}>Previous</ButtonPrimary>}
                      {hintOpen && <ButtonPrimary onClick={() => {
                          setHintOpen(false)
                      }}>Close Hint</ButtonPrimary>}
                      {currentQuestion < quizData?.questions.length - 1 && <ButtonPrimary minWidth={'180px'} onClick={() => {
                          const currentTime = Date.now();
                          const timeSpent = Math.floor((currentTime - pageStartTime) / 1000);

                          let cp = JSON.parse(JSON.stringify(pageTimes))
                          cp[currentQuestion] = (cp[currentQuestion] || 0) + timeSpent

                          setCurrentQuestion(currentQuestion + 1)
                          setPageTimes(cp)
                          // pageStartTime = currentTime
                          setPageStartTime(currentTime)
                      }}>Next</ButtonPrimary>}
                      {currentQuestion >= quizData?.questions.length - 1 && <ButtonPrimary onClick={() => {
                          setResultsOpen(true);
                          let score = 0;
                          for (let i = 0; i < quizData?.questions.length; i++) {
                              if (quizDataSelected[i] && quizData?.questions[i].answer.toLowerCase() === quizDataSelected[i].toLowerCase()) {
                                  score += 2
                              } else if (quizDataSelected[i]) {
                                  score -= 0.66
                              }
                          }
                          setScore(score);
                          submitTest(noteid,username,score)
                          console.log(pageTimes)
                      }}>Results</ButtonPrimary>}

                  </Flex>}

              </Container>

                <CustomStyledModal hint isOpen={isOpen} onOpen={onOpen} onClose={onClose} title={'Hint'} quizData={quizData} currentQuestion={currentQuestion}/>


                {
                    resultsOpen && <>
                        <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'} w={'100%'}>


                            <Flex  bgColor={'#0962A2'} w={'80%'} mr={'20px'} justifyContent={'space-around'} alignItems={'center'} mt={'30px'} p={'12px'}
                                    borderRadius={'12px'} pt={'40px'} pb={'40px'} >
                                <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                    <Flex borderRadius={'50%'} w={'200px'} h={'200px'} bg={'white'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                        <Pxl margin={0} fontSize={'64px'}><strnog>{score.toFixed(0)}</strnog></Pxl>
                                        <Psm  margin={0} fontSize={'24px'}><strong>Out of 12</strong></Psm>
                                    </Flex>
                                    <Psm  margin={0} color={'white'} mt={'10px'} fontSize={'24px'}>Time Taken: {formatTime(getTotalTime())}</Psm>

                                </Flex>

                                <Box w={'50%'}>
                                <Box w={'100%'} position={'relative'} overflow={'hidden'} h={'24px'} bg={'gray'} borderRadius={'24px'}>
                                    <Box position={'absolute'} zIndex={3} h={'100%'} w={'30%'} bg={'green'}></Box>
                                    <Box position={'absolute'} zIndex={2} h={'100%'} w={'60%'} bg={'red'}></Box>
                                    <Box position={'absolute'} zIndex={1} h={'100%'} w={'100%'} bg={'gray'}></Box>
                                </Box>
                                <Flex justifyContent={'space-around'}>
                                    <Flex alignItems={'center'} mt={'14px'}>
                                    <Box borderRadius={'50%'} h={'14px'} w={'14px'} bg={'green'}/>
                                    <Pmd margin={0} ml={'8px'} color={'white'}><strong>Correct 10</strong></Pmd>
                                    </Flex>
                                    <Flex alignItems={'center'} mt={'14px'}>
                                        <Box borderRadius={'50%'} h={'14px'} w={'14px'} bg={'red'}/>
                                        <Pmd margin={0} ml={'8px'} color={'white'}><strong>Wrong 10</strong></Pmd>
                                    </Flex>
                                    <Flex alignItems={'center'} mt={'14px'}>
                                        <Box borderRadius={'50%'} h={'14px'} w={'14px'} bg={'gray'}/>
                                        <Pmd margin={0} ml={'8px'} color={'white'}><strong>Not attempted 10</strong></Pmd>
                                    </Flex>
                                </Flex>

                                    <Flex justifyContent={'space-around'} mt={'12px'}>
                                        <ButtonPrimary display={'flex'} bg={'white'} color={'#0962A2'}>
                                            <Image h={'20px'} w={'20px'} src={'/review_icon.png'}/>
                                            Review</ButtonPrimary>
                                        <ButtonPrimary bg={'white'} color={'#0962A2'} display={'flex'}><Image h={'20px'} w={'20px'} src={'/restart_icon.png'} mr={'3px'}/> Retry</ButtonPrimary>
                                    </Flex>
                                </Box>
                            </Flex>


                            <Box
                            boxShadow={'0px 4px 4px 0px #00000040'}
                            w={'50%'}
                            mt={'20px'}
                            >

                                <TableContainer>
                                    <Table variant='simple'>

                                        <Thead>
                                            <Tr>
                                                <Th></Th>
                                                <Th color={'black'}>Rank</Th>
                                                <Th color={'black'}>Name</Th>
                                                <Th color={'black'}>Time</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td><Image src={'/first.png'} h={'38px'} w={'31px'}/></Td>
                                                <Td>1</Td>
                                                <Td>m2</Td>
                                                <Td>25.4</Td>
                                            </Tr><Tr>
                                                <Td><Image src={'/second.png'} h={'38px'} w={'31px'}/></Td>
                                                <Td>2</Td>
                                                <Td>n1</Td>
                                                <Td>25.4</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                            </Box>



                        </Flex>

                    </>
                }

            </Box>
          </Flex>



            {/*<AskBudAccent/>*/}


        </Container>
      </>

  );
}

export default TestsQuiz;
