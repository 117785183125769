import {
    Box,
    Button,
    Center,
    Flex,
    HStack,
    Image,
    Input, Modal, ModalBody, ModalContent, ModalOverlay,
    Text, useDisclosure,
    VStack
} from "@chakra-ui/react";
import { EditIcon, EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import React, {useEffect, useState} from "react";
import Pmd from "./Typography/Text/Pmd";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import Pxl from "./Typography/Text/Pxl";
import {setSelectedWorkspace} from "../redux/actions/userActions";
import {useDispatch} from "react-redux";
import {NEW_API_URL} from "../redux/types";
import axios from "axios";
function ProfileCard(props) {


    const [firstName,setFirstName] = useState(props.profileData?.first_name || "First Name")
    const [lastName,setLastName] = useState(props.profileData?.last_name || "Last Name")

    const [phoneNumber,setPhoneNumber] = useState(props.profileData?.phone_number || "Phone number")
    const [email,setEmail] = useState(props.profileData?.email || "Email")
    const [studyHabits,setStudyHabits] = useState(props.profileData?.study_hours_week || {
        "Monday":0,
        "Tuesday":0,
        "Wednesday":0,
        "Thursday":0,
        "Friday":0,
        "Saturday":0,
        "Sunday":0
    })

    useEffect(()=>{
        if(props.profileData){
            setFirstName(props.profileData?.first_name)
            setLastName(props.profileData?.last_name)
            setPhoneNumber(props.profileData?.phone_number)
            setEmail(props.profileData?.email)
            setStudyHabits(props.profileData?.study_hours_week)


        }
    },[props.profileData])


    const [isEdit,setIsEdit] = useState(false)
    // const [workspaceModal,setWorkspaceModal] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const workspaceModalOpen = isOpen
    const workspaceModalOnOpen = onOpen
    const workspaceModalOnClose = onClose
    // const { workspaceModalOpen, workspaceModalOnOpen, workspaceModalOnClose } = useDisclosure()
    const dispatch = useDispatch()


    const updateProfile = (username, data) => {
        const url = NEW_API_URL + "user/profile/";
        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${props.token}`
            }
        }
        const body = {
            // username,
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            phone_number: data.phoneNumber,
            study_hours_week: data.studyHabits,
            // exam_date: moment(data.examDate).format("YYYY-MM-DD"),
        };

        axios.post(url, body, axiosConfig)
            .then(res => {
                // console.log("\nupdate profile res", JSON.stringify(profileData));
                props.reload()

                // const profileData = sortProfileData(data);

                // dispatch(setProfile(profileData));

                // if (Platform.OS === "android") {
                //     ToastAndroid.show("Saved Changes", ToastAndroid.SHORT);
                // } else {
                //     Alert.alert("Saved Changes");
                // }
            })
            .catch(error => {
                console.log("\nupdate profile error", JSON.stringify(error?.message))
                // if (Platform.OS === "android") {
                //     ToastAndroid.show("There as an error. Please try again later.", ToastAndroid.SHORT);
                // } else {
                //     Alert.alert("There as an error. Please try again later.");
                // }
            });
    }



    return (
        <Box
            width={'40%'}
        >
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" padding={10}>
            <Center>

            <Image src="./assets/images/profile-img.png" alt="Profile Photo" />
            <Button rightIcon={<EditIcon />} onClick={()=>{
                setIsEdit(!isEdit)
            }} marginLeft={3} colorScheme="teal" variant="outline">
                Edit Profile
            </Button>
            </Center>

            <VStack spacing={2} p="5" justifyContent={'flex-start'} alignItems={'flex-start'}>
                {!isEdit &&
                    <>
                        <Pmd>{firstName}</Pmd>
                        <Pmd>{lastName}</Pmd>
                    </>
                }
                {isEdit && <><Input placeholder="First name" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}}/>
                    <Input placeholder="Last name" value={lastName} onChange={(e)=>{setLastName(e.target.value)}}/></>}
                <HStack justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <PhoneIcon />

                    {isEdit && <><Input placeholder="Phone Number" value={phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}} /></>}
                    {!isEdit && <><Text margin={0}>{phoneNumber}</Text></>}

                </HStack>
                <HStack>
                    <EmailIcon />

                    {isEdit && <><Input placeholder="Email" value={email} onChange={(e)=>{setEmail(e.target.value)}} /></>}
                    {!isEdit && <><Text margin={0}>{email}</Text></>}
                </HStack>
                <hr/>
                <TableContainer width={'100%'}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Study Habits</Th>
                                <Th>0 Hrs</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                studyHabits && Object.keys(studyHabits).map(i=>(
                                    <Tr>
                                        <Td>{i}</Td>
                                        {!isEdit && <Td>{studyHabits[i]} Hrs</Td>}
                                        {isEdit && <Input placeholder="Hrs" value={studyHabits[i]} onChange={(e)=>{setStudyHabits({...studyHabits,[i]:e.target.value})}} />}
                                    </Tr>
                                ))
                            }
                        </Tbody>

                    </Table>
                </TableContainer>
                <hr/>
                <Flex width={'100%'} justifyContent={'space-around'} alignItems={'space-around'}>
                <Flex onClick={()=>{workspaceModalOnOpen()}} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Text>Joined Groups</Text>
                    <Text>{props.workspaces.length}</Text>
                </Flex>
                <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Text>Exam Date</Text>
                    <Text>NOT SET</Text>
                </Flex>
                </Flex>
                {/*<Button leftIcon={<FormsListIcon />} colorScheme="teal">*/}
                {/*    Forms List*/}
                {/*</Button>*/}
                {/*<Button leftIcon={<SubscriptionsIcon />} colorScheme="teal">*/}
                {/*    Subscriptions*/}
                {/*</Button>*/}
                {/*<Button leftIcon={<LogoutIcon />} colorScheme="teal">*/}
                {/*    Logout*/}
                {/*</Button>*/}
            </VStack>

            <Center>
                <Button onClick={()=>{
                    updateProfile('test',{
                        email:email,
                        firstName:firstName,
                        lastName:lastName,
                        phoneNumber:phoneNumber,
                        studyHabits:studyHabits
                    })
                }} marginLeft={3} colorScheme="teal" variant="outline">
                    Save Changes
                </Button>

                <Button onClick={()=>{
                    localStorage.clear()
                    window.location.reload()
                }} marginLeft={3} colorScheme="red" variant="outline">
                    Logout
                </Button>
            </Center>


        </Box>


            <Modal isOpen={workspaceModalOpen} onClose={workspaceModalOnClose}>
                <ModalOverlay />
                <ModalContent>
                    {/*<ModalCloseButton />*/}
                    <ModalBody style={{padding:'40px'}}>
                        {
                            props.workspaces.map((item)=>{
                                console.log(item.workspace_name)
                                return <>
                                    <Pmd style={{margin:10,color:props.currentworkspace.workspace_name === item.workspace_name ?"white":"black",backgroundColor:props.currentworkspace.workspace_name === item.workspace_name ?'#6750A4':"transparent",borderRadius:'20px',paddingLeft:'10px'}} onClick={()=>{

                                        dispatch(setSelectedWorkspace(item));

                                    }}>{item.workspace_name}</Pmd>

                                </>
                            })
                        }



                    </ModalBody>
                </ModalContent>
            </Modal>


        </Box>
    );
}

export default ProfileCard;
