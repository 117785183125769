export const plans = [
  {
    id: "eyde0",
    type: "Free Trial",
    benefits: [
      "Basic Questions and Tests",
      "Access to public rooms",
      "Basic Strategy Lesson Plan",
    ],
  },
  {
    id: "eyde1",
    type: "Basic Plan",
    benefits: [
      "Free Subscription for One Month",
      "Basic Questions and Tests",
      "Access to public rooms",
      "Basic Strategy Lesson Plan",
    ],
    monthlyPrice: 99,
    oneTimeDiscountedPrice: 535,
    oneTimePrice: 594,
  },
  {
    id: "eyde2",
    type: "Advanced Plan",
    benefits: [
      "Free Subscription for One Month",
      "Full Access questions and mock tests",
      "Access to public and mentor led rooms",
      "Advanced Strategy Lesson Plan",
    ],
    monthlyPrice: 199,
    oneTimeDiscountedPrice: 1075,
    oneTimePrice: 1194,
  },
];

export const forumsData = [
  {
    name: "Alice Johnson",
    avatarSrc: "/assets/images/profile.png",
    position: "Software Engineer, ByteTech",
    categoryColor: "#ff9800",
    categoryLabel: "ideas",
    content:
      "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas...",
    likes: 152,
    postDuration: "1M",
    comments: 42,
  },
  {
    name: "John Smith",
    avatarSrc: "/assets/images/profile.png",
    position: "Product Manager, InnovateCorp",
    categoryColor: "#795548",
    categoryLabel: "Questions",
    content:
      "Curabitur nec eros quis urna facilisis laoreet a vel elit. Nulla facilisi. Proin hendrerit euismod ipsum et tristique...",
    likes: 75,
    postDuration: "3W",
    comments: 31,
  },
  {
    name: "Emily Brown",
    avatarSrc: "/assets/images/profile.png",
    position: "UX Designer, DesignScape",
    categoryColor: "#00bcd4",
    categoryLabel: "Trending",
    content:
      "Aenean varius erat vel massa posuere tincidunt. Sed interdum, justo non blandit congue, augue nulla facilisis turpis...",
    likes: 112,
    postDuration: "4D",
    comments: 20,
  },
  {
    name: "Michael Wilson",
    avatarSrc: "/assets/images/profile.png",
    position: "Data Scientist, AnalytixLab",
    categoryColor: "#ff9800",
    categoryLabel: "ideas",
    content:
      "Suspendisse tristique sapien a purus hendrerit, eu feugiat ex fringilla. Nullam et turpis nec justo volutpat mattis...",
    likes: 208,
    postDuration: "1W",
    comments: 50,
  },
  {
    name: "Sophia Miller",
    avatarSrc: "/assets/images/profile.png",
    position: "Marketing Manager, ReachMark",
    categoryColor: "#795548",
    categoryLabel: "Questions",
    content:
      "Fusce eget fringilla sapien, vel consectetur elit. Cras nec tortor nec purus interdum auctor. Suspendisse facilisis...",
    likes: 44,
    postDuration: "2M",
    comments: 12,
  },
  {
    name: "William Jones",
    avatarSrc: "",
    position: "Sales Representative, SaleSphere",
    categoryColor: "#00bcd4",
    categoryLabel: "Trending",
    content:
      "Vestibulum ac malesuada nisl. Nullam eget nisi eu urna pharetra cursus vel ut nisi. Aliquam erat volutpat...",
    likes: 62,
    postDuration: "2D",
    comments: 18,
  },
  {
    name: "Olivia Davis",
    avatarSrc: "/assets/images/profile.png",
    position: "HR Manager, TalentHub",
    categoryColor: "#00bcd4",
    categoryLabel: "Trending",
    content:
      "Praesent cursus quam id felis interdum, a varius odio fermentum. Duis in vestibulum odio, ut pellentesque ligula...",
    likes: 30,
    postDuration: "3M",
    comments: 8,
  },
  {
    name: "Daniel Martinez",
    avatarSrc: "/assets/images/profile.png",
    position: "Finance Analyst, FinanceWise",
    categoryColor: "#ff9800",
    categoryLabel: "ideas",
    content:
      "Cras ut ultricies purus. Nam accumsan ante et finibus. Aliquam eget est vel enim vulputate varius...",
    likes: 95,
    postDuration: "1D",
    comments: 25,
  },
  {
    name: "Isabella Taylor",
    avatarSrc: "",
    position: "Content Writer, WriteNow",
    categoryColor: "#795548",
    categoryLabel: "Questions",
    content:
      "Nullam non tincidunt libero, non efficitur erat. Vivamus venenatis vitae enim eu bibendum. Vivamus eget fermentum purus...",
    likes: 18,
    postDuration: "5H",
    comments: 6,
  },
  {
    name: "James Anderson",
    avatarSrc: "/assets/images/profile.png",
    position: "Researcher, SciQuest",
    categoryColor: "#00bcd4",
    categoryLabel: "Trending",
    content:
      "Mauris vehicula eros vitae nulla eleifend, in semper tortor tincidunt. Integer volutpat justo ut urna hendrerit...",
    likes: 123,
    postDuration: "1M",
    comments: 35,
  },
];
