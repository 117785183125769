import axios from "axios";
import { useState } from "react";

const usePostSubmitTestData = () => {
  const [isLoadingpost, setIsLoadingpost] = useState(false);
  const [errorPost, setErrorPost] = useState(null);

  const postSubmitTestData = async (username, ws_id, test_id, body) => {
    console.log("usePostSubmitTestData");

    try {
      setIsLoadingpost(true);

      const res = await axios.post(
        `https://55aaqcxwk7.execute-api.ap-south-1.amazonaws.com/dev/library/submit_test/${ws_id}/${test_id}/${username}/`,
        body
      );

      console.log(res);

      return res?.data;
    } catch (error) {
      console.error("Error fetching response:", error);
      setErrorPost(error);
      throw error;
    } finally {
      setIsLoadingpost(false);
    }
  };

  return { postSubmitTestData, isLoadingpost, errorPost };
};

export default usePostSubmitTestData;
