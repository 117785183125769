import React, {useEffect, useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Button, useDisclosure, Container, Flex, Select, Center,
} from '@chakra-ui/react'
import Pmd from "../Typography/Text/Pmd";
import ButtonPrimary from "../Button/ButtonPrimary";
import InputOutline from "../Input/InputOutline";
import InputTextAreaOutline from "../Input/InputTextAreaOutline";
import FileUpload from "../FileUpload";
import {AddIcon, CheckIcon, CloseIcon} from "@chakra-ui/icons";
import ButtonSecondary from "../Button/ButtonSecondary";
import Pxl from "../Typography/Text/Pxl";
import Plg from "../Typography/Text/Plg";
import { useToast } from '@chakra-ui/react'
import axios from "axios";
import {NEW_API_URL} from "../../redux/types";
import {useSelector} from "react-redux";

function CustomStyledModal(props) {

    const [relatedItems,setRelatedItems] = useState([]);
    const [title,setTitle] = useState('');
    const [link,setLink] = useState('');
    const [description,setDescription] = useState('');
    const [file,setFile] = useState([]);
    const [preview,setPreview] = useState(false);
    const toast = useToast()
    console.log(props.topicDetail,'files')
    const username = useSelector(state => state.user.username);

    useEffect(() => {
        if(props.force_rel || props.force_topic){
            setRelatedItems([{
                rel:props.force_rel,
                chap:props.force_topic
            }])
        }
    }, [props.force_rel,props.force_topic]);


    const get_type_of_note = (note) =>{
        if(note==='note')
            return 'Concept'
        if(note==='reading')
            return 'Reading'
        if(note==='news')
            return 'News'
        if(note==='timeline')
            return 'TimelineEvent'
    }



    if(props.subscription){
        return <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent borderRadius={'12px'} borderTop={'4px solid #0962A2'} maxWidth={'container.lg'}>
                <ModalHeader textAlign={'center'}>{props.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Pmd>
                        We were not able to find any active subscription on your account linked with {props.username}. Please go to our mobile app and activate your subscription to continue accessing Claydeck

                    </Pmd>
                </ModalBody>
                <ModalFooter textAlign={'center'} justifyContent={'center'}>
                    <Button colorScheme='blue' mr={3} onClick={()=>{
                        window.location.reload()
                    }}>
                        Refresh
                    </Button>
                    <Button colorScheme='red' mr={3} onClick={()=>{
                        localStorage.clear()
                        window.location.reload()
                    }}>
                        Logout
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

    }


    if(props.hint){
        return <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent borderRadius={'12px'} borderTop={'4px solid #0962A2'} maxWidth={'container.lg'}>
                <ModalHeader textAlign={'center'}>{props.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/*<Container maxW={'container.md'}>*/}
                        <Center w={'100%'}>
                            <Center bgColor={'white'} w={'100%'}  flexDirection={'column'} mt={'30px'}  >
                                <Plg><strong>Related Concepts:</strong></Plg>
                                {
                                    props.quizData?.questions[props.currentQuestion].hint_concepts.map((item,idx)=>(
                                        <Plg color='#2A6291' key={idx}>{item.name}</Plg>
                                    ))
                                }
                                <Plg mt={'20px'}><strong>Related News:</strong></Plg>
                                {
                                    props.quizData?.questions[props.currentQuestion].hint_news.map((item,idx)=>(
                                        <Plg color='#2A6291' key={idx}>{item.name}</Plg>
                                    ))
                                }

                                <Plg color='#2A6291' mt={'20px'}><u>Get more hints</u></Plg>
                                {/*<Plg></Plg>*/}
                            </Center>
                        </Center>
                    {/*</Container>*/}

                </ModalBody>

                <ModalFooter textAlign={'center'} justifyContent={'center'}>
                    <ButtonPrimary colorScheme='blue' mr={3} onClick={props.onClose}>
                        Close Hint
                    </ButtonPrimary>
                </ModalFooter>
            </ModalContent>
        </Modal>


    }

    if(props.add_note && preview){
        return (
            <>
                <Modal isOpen={props.isOpen} onClose={props.onClose}>
                    <ModalOverlay />
                    <ModalContent borderRadius={'12px'} borderTop={'4px solid #0962A2'} maxWidth={'container.lg'}>
                        <ModalHeader textAlign={'center'}>
                            {title}
                            <hr/>

                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Container maxW={'container.md'}>
                                <Pmd>{description}</Pmd>
                                {
                                    Array.from(file).map((item,idx)=> (
                                        <img alt="preview image" src={URL.createObjectURL(item)} key={idx}/>

                                    ))

                                }
                                <Pmd>Related Items</Pmd>
                                <Flex>
                                    <Pmd>Links</Pmd>
                                    <Pmd>{link}</Pmd>
                                </Flex>

                                {
                                    relatedItems.map((item, index) => {

                                        // if(item.edit){
                                        return (
                                            <Flex>

                                                {!item.edit && <>
                                                    <Pmd>{item.rel} : {item.chap}</Pmd>

                                                </>}

                                            </Flex>
                                        )
                                        // }

                                    })
                                }

                            </Container>

                        </ModalBody>

                        <ModalFooter textAlign={'center'} justifyContent={'center'}>
                            <ButtonSecondary outline colorScheme='blue' mr={3} onClick={()=>{props.onClose();setPreview(false)}}>
                                Cancel
                            </ButtonSecondary>
                            <ButtonPrimary colorScheme='blue' mr={3} onClick={()=> {

                                let payload = {
                                    // "id": "12345", // send only while editing
                                    "note_type": "user",
                                    "title": title,
                                    "description": description,
                                    "user_id": username,
                                    "category": "note",
                                    "topic_id": props.topic_id, //optional
                                    "topic": props.topic_name, //optional
                                    "area": props.area, //optional
                                    "subject": props.subject, //optional
                                    "links": link.split(","),
                                    "related_items": [
                                        // {
                                        //     "id": "topic001",
                                        //     "title": "Related Topic",
                                        //     "type": "Topic",
                                        //     "topic_id": "topic_id_123"
                                        // },
                                        // {
                                        //     "id": "concept001",
                                        //     "title": "Related Concept",
                                        //     "type": "Concept",
                                        //     "topic_id": "topic_id_123"
                                        // },
                                        // {
                                        //     "id": "reading001",
                                        //     "title": "Related Reading",
                                        //     "type": "Reading",
                                        //     "topic_id": "topic_id_123"
                                        // },
                                        // {
                                        //     "id": "news001",
                                        //     "title": "Related News",
                                        //     "type": "News",
                                        //     "date": "2024-10-10"
                                        // },
                                        // {
                                        //     "id": "event001||timeline001",
                                        //     "title": "Related Timeline Event",
                                        //     "type": "TimelineEvent",
                                        //     "topic_id": "topic_id_123"
                                        // }
                                    ]
                                }


                                payload['related_items'] = relatedItems.map((item,index)=>{
                                    return {
                                        id:item.id,
                                        title:item.chap,
                                        type:get_type_of_note(item.rel),
                                        topic_id:props.topic_id
                                    }
                                })

                                if(props.add_type_topic)
                                    payload['related_items'].push({
                                        "id": props.topic_id,
                                        "title": "Related Topic",
                                        "type": "Topic",
                                        "topic_id": props.topic_id
                                    },)




                                console.log(relatedItems,'relateditems')




                                // console.log(,'relform')

                                axios.post(NEW_API_URL+"notes/create_note/",payload).then(r=>{

                                    toast({
                                        title: 'Note created.',
                                        description: props.toast,
                                        status: 'success',
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                    setPreview(false)
                                    setRelatedItems([])
                                    setTitle('')
                                    setLink('')
                                    setDescription('')

                                })



                                props.onClose()
                            }}>
                                Create
                            </ButtonPrimary>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    }
    else if(props.add_note){
        return (
            <>
                <Modal isOpen={props.isOpen} onClose={props.onClose}>
                    <ModalOverlay />
                    <ModalContent borderRadius={'12px'} borderTop={'4px solid #0962A2'} maxWidth={'container.lg'}>
                        <ModalHeader textAlign={'center'}>
                            {props.title}
                            <hr/>

                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Container maxW={'container.md'}>
                                <Pmd margin={0}>Title</Pmd>
                                <InputOutline  borderRadius={'12px'} background={'#EBEBEB'} variant={'filled'} value={title} onChange={(e)=>{setTitle(e.target.value)}} borderWidth={'2px'}/>
                                <Pmd margin={0}>Description</Pmd>
                                <InputTextAreaOutline borderRadius={'12px'} background={'#EBEBEB'} variant={'filled'} value={description} onChange={(e)=>{setDescription(e.target.value)}} borderWidth={'0px'}/>
                                <Pmd margin={0}>Link</Pmd>
                                <InputOutline borderRadius={'12px'} background={'#EBEBEB'} variant={'filled'} value={link} onChange={(e)=>{setLink(e.target.value)}} borderWidth={'2px'}/>
                                <FileUpload setImageData={setFile} style={{width:'50%'}}/>
                                <Flex mt={'20px'} alignItems={'center'}>
                                <Pmd margin={0}>Related Items</Pmd>
                                    {!props.force_rel && <AddIcon cursor={'pointer'} color={'#0962A2'} ml={'20px'} onClick={() => {
                                        setRelatedItems(relatedItems.concat({edit: true}))
                                    }}/>}
                                </Flex>

                                {
                                    relatedItems.map((item, index) => {

                                        // if(item.edit){
                                            return (
                                                <Flex mt={'20px'} alignItems={'center'}>

                                                    {item.edit && <>

                                                        <Select disabled={props.force_rel} value={item.rel} onChange={(e)=>{
                                                            let cpy = JSON.parse(JSON.stringify(relatedItems))
                                                            cpy[index].rel = e.target.value
                                                            setRelatedItems(cpy)
                                                        }}  key={index} placeholder='Select' borderRadius={'8px'} w={'30%'} border={'0.5px solid #000000'}>
                                                            <option value='note'>Note</option>
                                                            <option value='reading'>Reading</option>
                                                            <option value='news'>News</option>
                                                        </Select>
                                                        <Select disabled={props.force_topic} placeholder='Select' value={item.raw} onChange={(e)=>{
                                                            let cpy = JSON.parse(JSON.stringify(relatedItems))
                                                            cpy[index].chap = JSON.parse(e.target.value).title
                                                            cpy[index].id = JSON.parse(e.target.value).id
                                                            cpy[index].raw = e.target.value
                                                            setRelatedItems(cpy)
                                                            console.log(typeof(e.target.value))
                                                        }} key={index}  borderRadius={'8px'} ml={'15px'} w={'30%'}
                                                                border={'0.5px solid #000000'}>

                                                            {
                                                                relatedItems[index].rel==='note' && props.topicDetail && props.topicDetail.concepts.map((item,index)=> (
                                                                    <option value={JSON.stringify(item)} key={index}>{item.title}</option>
                                                                ))
                                                            }

                                                            {
                                                                relatedItems[index].rel==='reading' && props.topicDetail && props.topicDetail.reading_list.map((item,index)=> (
                                                                    <option value={JSON.stringify(item)} key={index}>{item.title}</option>
                                                                ))
                                                            }

                                                            {
                                                                relatedItems[index].rel==='news' && props.topicDetail && props.topicDetail.news.map((item,index)=> (
                                                                    <option value={JSON.stringify(item)} key={index}>{item.title}</option>
                                                                ))
                                                            }
                                                            {/*<optgroup label="British Conquest">*/}
                                                            {/*<option value='chap1'>chap1</option>*/}
                                                            {/*</optgroup>*/}
                                                            {/*<optgroup label="All">*/}
                                                            {/*    <option value='reading'>chap2-aa</option>*/}
                                                            {/*    <option value='news'>chap3-bb</option>*/}
                                                            {/*</optgroup>*/}
                                                        </Select>

                                                         <CheckIcon
                                                            color={(item.hasOwnProperty('rel') && item.hasOwnProperty('chap') && item.rel.length > 0 && item.chap.length > 0) ? '#0962A2' : 'gray.300'}
                                                            boxSize={'30px'} ml={'10px'} cursor={'pointer'}
                                                            onClick={() => {
                                                                if ((item.hasOwnProperty('rel') && item.hasOwnProperty('chap') && item.rel.length > 0 && item.chap.length > 0)) {
                                                                    let cpy = JSON.parse(JSON.stringify(relatedItems))
                                                                    cpy[index].edit = false
                                                                    setRelatedItems(cpy)
                                                                }
                                                            }}/>
                                                    </>}
                                                    {!item.edit && <>
                                                        <Pmd>{item.rel} : {item.chap}</Pmd>

                                                    </>}

                                                    {!props.force_rel && <CloseIcon color={'#0962A2'} boxSize={'24px'} ml={'10px'}
                                                                cursor={'pointer'} onClick={() => {
                                                        let cpy = JSON.parse(JSON.stringify(relatedItems))
                                                        cpy.splice(index, 1)
                                                        console.log(cpy)
                                                        setRelatedItems(cpy)

                                                    }}/>}

                                                </Flex>
                                            )
                                        // }

                                    })
                                }

                            </Container>

                        </ModalBody>

                        <ModalFooter textAlign={'center'} justifyContent={'center'}>
                            <ButtonSecondary  outline colorScheme='blue' mr={3} onClick={props.onClose}>
                                Cancel
                            </ButtonSecondary>
                            <ButtonPrimary colorScheme='blue' mr={3} onClick={()=>setPreview(true)}>
                                Create
                            </ButtonPrimary>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    }


    if(props.quick_explain){
        return (
            <>
                <Modal isOpen={props.isOpen} onClose={props.onClose}>
                    <ModalOverlay />
                    <ModalContent borderRadius={'12px'} borderTop={'4px solid #0962A2'} maxWidth={'container.lg'}>
                        <ModalHeader textAlign={'center'}>{props.title}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Container maxW={'container.md'}>
                            <table>
                                <tbody>
                                <tr>
                                    <td style={{verticalAlign:'top'}}>
                                       <Pmd> <strong>Q:</strong></Pmd>
                                    </td>
                                    <td>
                                        <Pmd><strong>{props.selectedText}</strong></Pmd>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{verticalAlign:'top'}}>
                                        A:
                                    </td>
                                    <td>
                                        {props.quick_exp}
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                            </Container>

                        </ModalBody>

                        <ModalFooter textAlign={'center'} justifyContent={'center'}>
                            <ButtonPrimary colorScheme='blue' mr={3} onClick={props.onClose}>
                                Close
                            </ButtonPrimary>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    }

    return (
        <>
            <Modal isOpen={props.isOpen} onClose={props.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={props.onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CustomStyledModal;
