import { ALL_CHATS, NEW_SEARCHQUERY } from "../types";

const initialState = {
  all_chats: [],
  new_searchQuery: "",
};

const aiSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CHATS:
      return {
        ...state,
        all_chats: [...state.all_chats, action.payload],
      };

    case NEW_SEARCHQUERY:
      return {
        ...state,
        new_searchQuery: action.payload,
      };

    default:
      return state;
  }
};

export default aiSearchReducer;
