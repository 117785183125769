import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Image,
    Select, useDisclosure,
    Wrap,
    WrapItem,
    ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Modal

} from "@chakra-ui/react";
import Markdown from 'react-markdown'

import axios from "axios";
import {useSelector} from "react-redux";
import {NEW_API_URL} from "../../redux/types";
import H3 from "../../Components/Typography/Heading/H3";
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import Pmd from "../../Components/Typography/Text/Pmd";
// import Modal from "../../Components/Modal";
import H2 from "../../Components/Typography/Heading/H2";
import InputTextAreaOutline from "../../Components/Input/InputTextAreaOutline";
import H1 from "../../Components/Typography/Heading/H1";
import InputOutline from "../../Components/Input/InputOutline";
import {useNavigate} from "react-router-dom";
import Pxl from "../../Components/Typography/Text/Pxl";
const dummyNotes = [
    { topic: "JavaScript Basics", subtopic: "Variables and Data Types" },
    { topic: "HTML", subtopic: "Document Structure" },
    { topic: "CSS", subtopic: "Styling and Layout" },
    { topic: "React", subtopic: "Component Lifecycle" },
    { topic: "Node.js", subtopic: "Server-side JavaScript" },
];

var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default function MapView() {

    const [showAdd, setShowAdd] = useState(false);
    const username = useSelector(state => state.user.username);
    const [lessonPlanList, setLessonPlanList] = useState([]);
    const [notes, setNotes] = useState([]);
    const [currentTitle, setCurrentTitle] = useState('');
    const [topicIdx, setTopicIdx] = useState(-1);
    const [subtopicIdx, setSubtopicIdx] = useState(-1);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [note, setNote] = useState('');
    const [md, setmd] = useState('');
    const [currentMdTopic, setCurrentMdTopic] = useState('');


    const [mapObj, setMapObj] = useState({
        img:'/maps/UPSC2.webp',
        buttons:[
            {title: 'Ethics',top:'22%',left:'15%',click:{
                img:'/maps/UPSC.png',
                buttons:[
                    {title: 'Ethics',top:'22%',left:'15%'},
                    {title: 'Public Administration',top:'18%',left:'50%',click:{
                            img:'/maps/UPSC2.webp',
                            buttons:[
                                {title: 'Ethics',top:'22%',left:'15%',notes:[{}]},
                                {title: 'Public Administration',top:'18%',left:'50%'},
                            ]
                        }},
                    {title: 'Sociology',top:'60%',left:'50%'},
                    {title: 'History',top:'10%',left:'70%'},
                    {title: 'Geography',top:'40%',left:'80%'},
                ]
            }},
            {title: 'Public Administration',top:'18%',left:'50%'},
            {title: 'Sociology',top:'60%',left:'50%'},
            {title: 'History',top:'10%',left:'70%'},
            {title: 'Geography',top:'40%',left:'80%'},
        ]
    });
    const [mapPathStack,setMapPathStack] = useState([mapObj]);


    const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
    let workspaceId = '';
    if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
        workspaceId = selectedWorkspace.workspace_id
    }

    const { isOpen, onOpen, onClose } = useDisclosure()
    const d = useDisclosure()
    const isOpen1 = d.isOpen
    const onClose1 = d.onClose
    const onOpen1 = d.onOpen

    const getLessonPlan = async () => {
        setFetchLoading(true);
        try {
            await axios
                .get(
                    `${NEW_API_URL}map_app/get_map_object/`
                )
                .then((response) => {
                    // console.log("lesson plan res --->", response.data.lesson_plan);
                    if (response.status === 200) {
                        let mp = response.data.map_object.buttons.map(i=>{



                            return {
                                ...i,
                                left:(Math.random()*50)+'%',
                                top:(Math.random()*50)+'%',
                                title:i.subject_name,
                                click:{...i,buttons:i.topics.map(j=>{

                                    return {
                                        ...j,
                                        title:j.topic_name,
                                        left:(Math.random()*50)+'%',
                                        top:(Math.random()*50)+'%',
                                    }
                                    })}
                            }
                        })
                        response.data.map_object.buttons = mp
                        console.log(response.data.map_object)
                        setMapObj(response.data.map_object);
                        setMapPathStack([response.data.map_object])
                    }
                    // setTaskList(response?.data?.metrics?.todays_tasks?.tasks);
                })
                .catch((error) => {
                    console.error("lesson plan error --->", error);
                    // Toast.show({
                    //   type: "error",
                    //   text1: "Error",
                    //   text2: "Couldn't load data",
                    // });
                });
        } catch (e) {
            console.error(e);
            // Toast.show({
            //   type: "error",
            //   text1: "Error",
            //   text2: "Something unexpected happened",
            // });
        } finally {
            setFetchLoading(false);
        }
    };

    useEffect(() => {
        getLessonPlan();
    }, []);




    console.log('topics',lessonPlanList)
    const navigate = useNavigate();

    return (
        <Flex
            width={{base: "100%", xl: "80%"}}
            maxWidth={{base: "100%", xl: "80%"}}

            background={"claydeck.white.primary"} justifyContent={'center'}>

            {/*<Box w={'80%'} position={'relative'}>*/}
            {/*    <ButtonSecondary style={{position:'relative',top:10}}>BUTTON</ButtonSecondary>*/}

            {/*    <Image src={"/maps/UPSC2.webp"}/>*/}

            {/*</Box>*/}

            {
                mapPathStack.length > 1 && <Button

                    colorScheme="teal"
                    onClick={()=>{
                        // console.log(mapPathStack.slice(0, -1))
                        setMapPathStack(mapPathStack.slice(0, -1))
                    }}
                >
                    Back
                </Button>
            }


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody style={{padding:'40px'}}>
                        <Pxl color={'claydeck.brand.secondary'} mt={'20px'}>{currentTitle}</Pxl>

                        {
                            notes.map((i,idx)=>(
                                <Box p={5} className={'shadow-primary'} borderRadius="14px" onClick={()=>{
                                    axios
                                        .get(
                                            i.file
                                        )
                                        .then((response) => {
                                            // console.log("lesson plan res --->", response.data.lesson_plan);
                                            if (response.status === 200) {
                                                console.log(response.data)
                                                setmd(response.data)
                                                setCurrentMdTopic(i.title)
                                                onOpen1()
                                            }
                                        })
                                        .catch((error) => {
                                        });

                                }}>
                                    <Pmd margin={0}>{idx+1} <span style={{color:"#6750A4",fontWeight:'800px',textDecoration:'underline',cursor:'pointer'}}>{i.title}</span></Pmd>
                                </Box>
                            ))
                        }

                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal blockScrollOnMount={false} scrollBehavior="inside" size={'2xl'} isOpen={isOpen1} onClose={onClose1}>
                <ModalOverlay />
                <ModalContent maxW="80vw">
                    <ModalCloseButton />
                    <ModalBody style={{padding:'40px'}}>
                        <Pxl color={'claydeck.brand.secondary'} mt={'20px'}>{currentMdTopic}</Pxl>
                        {md && <Markdown>{md}</Markdown>}


                    </ModalBody>
                </ModalContent>
            </Modal>

            <Box position="relative" display="inline-block">

                <Image
                    src={mapPathStack[mapPathStack.length-1].img}
                    alt="Description of the image"
                    width="100%"
                    height="auto"
                />
                {
                    mapPathStack[mapPathStack.length-1].buttons.map((item,indx)=>(
                        <Button
                            position="absolute"
                            top={item.top}
                            left={item.left}
                            transform="translate(-50%, -50%)"
                            colorScheme="teal"
                            onClick={()=>{
                                if(item.click) {
                                    console.log(item,mapPathStack.concat(item),'itemstk')
                                    setMapPathStack(mapPathStack.concat(item.click))
                                }
                                if(item.notes) {
                                    setNotes(item.notes)
                                    setCurrentTitle(item.title)
                                    onOpen();
                                }

                            }}
                        >
                            {item.title}
                        </Button>
                    ))
                }



            </Box>




        </Flex>
    );
}
