import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";
import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select, Tab, TabList, TabPanel, TabPanels, Tabs,
  Tag, Text, useDisclosure,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import {useNavigate, useParams} from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import {ArrowBackIcon} from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import {news_map_data} from "../../data/new_generated/news_map";
import Plg from "../../Components/Typography/Text/Plg";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
const News = () => {
  // const {topic,subject,area} = useParams();

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  let workspaceId = '1';
  const [dailyNews,setDailyNews] = useState(news_map_data.daily_news);
  const [tredingNews,setTrendingNews] = useState(news_map_data.trending_news);
  const [saved_news,setSavedNews] = useState(news_map_data.saved_news);
  const [tab,setTab] = useState('daily_news');

  const {topic,subject,area} = useParams();

  const [topicDetail,setTopicDetail] = useState({})

  const notesModalStates = useDisclosure()

  const get_map_data = async (username,workspace) => {
    const r = await axios.get(NEW_API_URL+'lessonplan/get_topic_detail/'+username+'/'+workspace+'/')
    setTopicDetail(r.data.topic_detail)
    setDailyNews(r.data.topic_detail.news)


    return r.data
  }


  useEffect(() => {
    get_map_data(username,topic)


  }, []);

  return (
      <>
        <Container  justifyContent={'center'} alignItems={'center'} w={'95%'} maxW='container.xl' >
          <Flex flexDirection={'column'} alignItems={'space-between'} justifyContent={'space-between'} minH={'95vh'}>
            <Box>
              <Flex mt={'10vh'} alignItems={'center'}>
                <ArrowBackIcon cursor={'pointer'} onClick={()=>{navigate(-1)}} marginLeft={'-65px'} marginRight={'20px'} boxSize={'45px'}/>


                <Box>
                  <P2xl mb={0} ml={0} mr={0}  >
                    <strong>News</strong>

                  </P2xl>
                  <Pmd color={'#817D7D'} style={{margin:0}}><strong>History</strong></Pmd>
                </Box>
              </Flex>









              <HeadCard title={'News Articles'}  paddingRight={'10px'} paddingBottom={'30px'} mt={'20px'}>


                <Slider {...settings} draggable={true} arrows={false}>
                  {
                    dailyNews.map((item) => {
                      return (<>
                        <Link to={'/learn/'+subject+"/"+area+"/"+topic+"/news/"+item.id}>
                          <Box backgroundColor={'#ededed'} borderRadius={'12px'} overflow={'hidden'} h={'240px'}
                               w={'300px'} boxShadow={'0px 1px 2px 0px #00000040'} border={'1px solid #FFFFFF80'}
                               mt={'15px'}>

                            <Image src={item.icon_image_link} w={'100%'}></Image>

                            <Flex alignItems={'center'} justifyContent={'space-around'}>
                              <Box>

                                <Pmd pt={'14px'} margin={0}><strong>{item.title}</strong></Pmd>
                                <Psm>{item.summary}</Psm>
                              </Box>

                              {item.read_status ==='Read' && <Image src={'/tick_news.png'} h={'20px'} w={'20px'}></Image>}

                            </Flex>


                          </Box>
                        </Link>
                      </>)
                    })
                  }


                </Slider>


              </HeadCard>

              <HeadCard title={"News test"} mt={'30px'}>

                <Flex ml={'12px'} pb={'15px'}>

                  {topicDetail && topicDetail.news_tests && topicDetail.news_tests.map((item,index)=>(
                      <Box position={'relative'} key={index} mt={'30px'} w={'200px'} p={'12px'} mr={'5px'} boxShadow={'0px 3px 3px 0px #00000040'}
                           borderRadius={'12px'} borderLeft={'4px solid #2A6291'}>
                        {item.read_status === 'Read' && <Image style={{position:'absolute',right:'10px',top:'10px'}} src={'/read_tick.png'} height={'20px'} width={'20px'}/>}

                        <Psm>{item.title}</Psm>
                        <Flex alignItems={'center'}>
                          <Image height={'64px'} width={'53px'} src={'/news_test.png'}></Image>
                          <Plg margin={0} pl={'20px'}>{item.score} / {item.max_score}</Plg>
                        </Flex>
                      </Box>
                  ))}


                </Flex>


              </HeadCard>


              <CustomStyledModal

                  add_type_topic={false}
                  area={''} subject={''} topic_name={topicDetail.topic_name} topic_id={topic}

                  toast={'Note successfully created!'} topicDetail={topicDetail} add_note isOpen={notesModalStates.isOpen} onOpen={notesModalStates.onOpen} onClose={notesModalStates.onClose} title={'Notes'} selectedText={''}/>
              <Flex onClick={()=>{notesModalStates.onOpen()}} cursor={'pointer'} w={'80px'} h={'80px'} alignItems={'center'} justifyContent={'center'} bg={"claydeck.brand.primary"} border={'2px solid white'} boxShadow={'0px 4px 4px 0px #00000040'} position={'fixed'} bottom={'80px'} right={'20px'} borderRadius={'50px'}>
                <Text color={'white'} margin={0} fontSize={'32px'}>+</Text>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </>

  );
}

export default News;
