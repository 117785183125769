import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SET_FORUM_DATA } from "../redux/types";

const useForumGetData = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://kk2z2518c6.execute-api.ap-south-1.amazonaws.com/staging/forum/get_all_forum_posts/"
        );

        setData(response.data);
        dispatch({ type: SET_FORUM_DATA, payload: response.data });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching forum data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading };
};

export default useForumGetData;
