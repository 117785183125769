import React, {useEffect, useRef, useState} from "react";
import H1 from "../Components/Typography/Heading/H1";
import H2 from "../Components/Typography/Heading/H2";
import {Box, Checkbox, Container, Select} from "@chakra-ui/react";
import H3 from "../Components/Typography/Heading/H3";
import Pxl from "../Components/Typography/Text/Pxl";
import Plg from "../Components/Typography/Text/Plg";
import Pmd from "../Components/Typography/Text/Pmd";
import Psm from "../Components/Typography/Text/Psm";
import ButtonPrimary from "../Components/Button/ButtonPrimary";
import ButtonSecondary from "../Components/Button/ButtonSecondary";
import ButtonCheckboxPrimary from "../Components/Button/ButtonCheckboxPrimary";
import ButtonCheckboxSecondary from "../Components/Button/ButtonCheckboxSecondary";
import InputOutline from "../Components/Input/InputOutline";
import InputPassword from "../Components/Input/InputPassword";
import P2xl from "../Components/Typography/Text/P2xl";
import TimelineQuiz from "../Components/TimelineQuiz";
import ButtonImageDrag from "../Components/ButtonImageDrag";
import { Timeline,DataSet } from 'vis-timeline/standalone';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import moment, * as moments from 'moment';
import TimelineViz from "../Components/TimelineViz";


function DesignSheet(props) {
  const [radio, setRadio] = useState("student");



  return (
      <Container mt={30}>
        <H1 mb={2}>Typography</H1>
        <H1>H1</H1>
        <H2>H2</H2>
        <H3>H3</H3>
        <P2xl>Text - 2X Large - P2xl</P2xl>
        <Pxl>Text - X Large - Pxl</Pxl>
        <Plg>Text - Large - Plg</Plg>
        <Pmd>Text - Medium - Pmd</Pmd>
        <Psm>Text - Small - Psm</Psm>
        <H1 mt={30} mb={2}>
          Colors Background
        </H1>

        <Box
            minWidth={30}
            minHeight={30}
            bg={"claydeck.brand.primary"}
            color={"white"}
        >
          <Pmd color={"white"}>claydeck.brand.primary</Pmd>{" "}
        </Box>
        <Box
            minWidth={30}
            minHeight={30}
            bg={"claydeck.brand.secondary"}
            color={"white"}
        >
          <Pmd color={"white"}>claydeck.brand.secondary</Pmd>
        </Box>
        <Box
            minWidth={30}
            minHeight={30}
            bg={"claydeck.white.primary"}
            color={"black"}
        >
          <Pmd>claydeck.white.primary</Pmd>
        </Box>
        <Box
            minWidth={30}
            minHeight={30}
            bg={"claydeck.white.secondary"}
            color={"black"}
        >
          <Pmd>claydeck.white.secondary</Pmd>
        </Box>
        <H3 mt={2} mb={2}>
          Text
        </H3>

        <Pmd color={"claydeck.text.primary"}>claydeck.text.primary</Pmd>
        <Pmd color={"claydeck.text.secondary"}>claydeck.text.secondary</Pmd>
        <Pmd color={"claydeck.text.tertiary"}>claydeck.text.tertiary</Pmd>

        <H3 mt={2} mb={2}>
          Icons
        </H3>
        <Box
            minWidth={30}
            minHeight={30}
            bg={"claydeck.icon.primary"}
            color={"white"}
        >
          <Pmd>claydeck.icon.primary</Pmd>{" "}
        </Box>
        <Box
            minWidth={30}
            minHeight={30}
            bg={"claydeck.icon.active"}
            color={"white"}
        >
          <Pmd>claydeck.icon.active</Pmd>
        </Box>
        <Box
            minWidth={30}
            minHeight={30}
            bg={"claydeck.icon.hover"}
            color={"black"}
        >
          <Pmd>claydeck.icon.hover</Pmd>
        </Box>

        <H1 mt={30} mb={2}>
          Shadows CSS
        </H1>
        <Box
            minWidth={30}
            minHeight={30}
            mt={10}
            mb={5}
            bg={"shadow-primary"}
            color={"white"}
            className={"shadow-primary"}
        >
          <Pmd>shadow-primary</Pmd>{" "}
        </Box>

        <Box
            minWidth={30}
            minHeight={30}
            mt={10}
            mb={5}
            bg={"shadow-secondary"}
            color={"white"}
            className={"shadow-secondary"}
        >
          <Pmd>shadow-secondary</Pmd>{" "}
        </Box>
        <Box
            minWidth={30}
            minHeight={30}
            mt={10}
            mb={5}
            bg={"shadow-hover"}
            color={"white"}
            className={"shadow-hover"}
        >
          <Pmd>shadow-hover</Pmd>{" "}
        </Box>
        <Box
            minWidth={30}
            minHeight={30}
            mt={10}
            mb={5}
            bg={"shadow-default"}
            color={"white"}
            className={"shadow-default"}
        >
          <Pmd>shadow-default</Pmd>{" "}
        </Box>

        <H1 mt={30} mb={2}>
          Buttons
        </H1>
        <ButtonPrimary
            onClick={() => {
              alert("Don't press me :(");
            }}
        >
          ButtonPrimary
        </ButtonPrimary>
        <ButtonSecondary
            onClick={() => {
              alert("PLS! Don't press me :(");
            }}
        >
          ButtonSecondary
        </ButtonSecondary>

        <br/>
        <ButtonCheckboxPrimary
            onClick={() => {
              setRadio("student");
            }}
            checked={radio === "student"}
        >
          Student Login
        </ButtonCheckboxPrimary>
        <ButtonCheckboxPrimary
            onClick={() => {
              setRadio("teacher");
            }}
            checked={radio === "teacher"}
        >
          Teacher Login
        </ButtonCheckboxPrimary>

        <br/>
        <ButtonCheckboxSecondary
            onClick={() => {
              setRadio("student");
            }}
            checked={radio === "student"}
        >
          Student Login
        </ButtonCheckboxSecondary>
        <ButtonCheckboxSecondary
            onClick={() => {
              setRadio("teacher");
            }}
            checked={radio === "teacher"}
        >
          Teacher Login
        </ButtonCheckboxSecondary>
        <H1 mt={30} mb={2}>
          Inputs
        </H1>
        <Checkbox>Test box</Checkbox>

        <H1 mt={30} mb={2}>
          Inputs
        </H1>
        <Pmd color={"claydeck.text.tertiary"}>InputOutline</Pmd>
        <InputOutline/>
        <Pmd color={"claydeck.text.tertiary"}>InputPassword</Pmd>
        <InputPassword/>

        <TimelineQuiz/>
        <ButtonImageDrag imageUrl={'/img.png'}/>

          <TimelineViz/>

          <div style={{margin:'50px'}}></div>
      </Container>
  );
}

export default DesignSheet;
