import React from "react";
import Nav from "./Nav";
import { Box } from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import MainHeading from "../../Components/Typography/Heading/MainHeading";

export default function TermAndConditions() {
  return (
    <>
      {/* Navbar */}
      <Nav />

      {/* Main Content */}
      <Box className="p-5 mx-5">
        <MainHeading>Terms of Service</MainHeading>
        <Pmd className="mt-3 mb-5">
          Please read these Terms of Service carefully as they contain important
          information about your legal rights, remedies and obligations. By
          accessing our “Website” (“www.claydeck.com”) you agree to comply with
          and be bound by these Terms of Service.
        </Pmd>
        <MainHeading>Privacy Policy</MainHeading>
        <Pmd className="mt-3 mb-5">
          Privacy Policy Arc Tech Labs built the Claydeck platform as an
          educational platform. This page is used to inform visitors regarding
          our policies with the collection, use, and disclosure of Personal
          Information if anyone decided to use our Service. If you choose to use
          our Service, then you agree to the collection and use of information
          in relation to this policy. The Personal Information that we collect
          is used for providing and improving the Service. We will not use or
          share your information with anyone except as described in this Privacy
          Policy. The terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions, which is accessible at claydeck unless
          otherwise defined in this Privacy Policy. Information Collection and
          Use for a better experience, while using our Service, we may require
          you to provide us with certain personally identifiable information,
          including but not limited to name, email, phone number, date of exam.
          The information that we request will be retained by us and used as
          described in this privacy policy. The app use third party services
          from google that may collect information used to identify you. Link to
          privacy policy of third party service providers used by the app,
          Google Play Services, log data. We want to inform you that whenever
          you use our Service, in a case of an error in the app we collect data
          and information (through third party products) on the cloud as log
          data. This Log Data may include information such as your device the
          configuration of the platform when utilizing our Service, the time and
          date of your use of the Service, and other statistics. Cookies :
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory. This Service does not use these “cookies” explicitly. However,
          the app may use third party code and libraries that use “cookies” to
          collect information and improve their services. You have the option to
          either accept or refuse these cookies and know when a cookie is being
          sent to your device. If you choose to refuse our cookies, you may not
          be able to use some portions of this Service. Service Providers We may
          employ third-party companies and individuals due to the following
          reasons: To facilitate our Service; To provide the Service on our
          behalf; To perform Service-related services; or To assist us in
          analyzing how our Service is used. We want to inform users of this
          Service that these third parties have access to your Personal
          Information. The reason is to perform the tasks assigned to them on
          our behalf. However, they are obligated not to disclose or use the
          information for any other purpose. Security : We value your trust in
          providing us your Personal Information, thus we are striving to use
          commercially acceptable means of protecting it. But remember that no
          method of transmission over the internet, or method of electronic
          storage is 100% secure and reliable, and we cannot guarantee its
          absolute security. Links to Other Sites This Service may contain links
          to other sites. If you click on a third-party link, you will be
          directed to that site. Note that these external sites are not operated
          by us. Therefore, we strongly advise you to review the Privacy Policy
          of these websites. We have no control over and assume no
          responsibility for the content, privacy policies, or practices of any
          third-party sites or services. Children’s Privacy These Services do
          not address anyone under the age of 13. We do not knowingly collect
          personally identifiable information from children under 13. In the
          case we discover that a child under 13 has provided us with personal
          information, we immediately delete this from our servers. If you are a
          parent or guardian and you are aware that your child has provided us
          with personal information, please contact us so that we will be able
          to do necessary actions. Changes to This Privacy Policy We may update
          our Privacy Policy from time to time. Thus, you are advised to review
          this page periodically for any changes. We will notify you of any
          changes by posting the new Privacy Policy on this page. This policy is
          effective as of 2021-08-11 Contact Us If you have any questions or
          suggestions about our Privacy Policy, do not hesitate to contact us at
          rahulkhatry@arctechlabs.com
        </Pmd>
        <MainHeading>Pricing Plan</MainHeading>
        <Pmd className="mt-3 mb-5">
          Mentorship course usually available as decided by the mentors Free
          model : Many claydeck groups are available to be created and joined
          for free Subscription model : Pricing like 199* Rs/month for
          subscription are also available for mentorship courses * Subscription
          prices also decide by mentors
        </Pmd>
        <MainHeading>Payment Terms and Conditions</MainHeading>
        <Pmd className="mt-3 mb-5">
          Mentorship course usually available as decided by the mentors Free
          model : Many claydeck groups are available to be created and joined
          for free Subscription model : Pricing like 199* Rs/month for
          subscription are also available for mentorship courses * Subscription
          prices also decide by mentors Payment Terms and Conditions 1 The
          Website/Platform makes available an educational technology eco-system,
          by providing (i) Subscription Services to various mentoring programs
          in the platform; These Terms of Service govern the use and access of
          the Website and/or, where applicable the services and/or the Platform.
          2 The Website/Platform also provides the facility to make payment
          towards the services that are being provided via the Website/Platform.
          3 Due to the nature of the Internet, the Website/Platform cannot
          guarantee the continuous and uninterrupted availability and
          accessibility of the Website and to the Platform. Claydeck may
          restrict the availability of the Website or certain areas or features
          thereof, if this is necessary in view of capacity limits, the security
          or integrity of our servers, or to carry out maintenance measures that
          ensure the proper or improved functioning of the Website and the
          Platform. The Website and the Platform may be improved, enhanced and
          modified to introduce new services from time to time. 4 Subscription
          services shall only be accessible to registered and paid users. The
          access to or use of the subscription Services shall be subject to
          Subscription Terms and Condition, which are in addition these Terms of
          Services. If there is a conflict between these Terms and the
          Subscription Terms and Conditions, then the Subscription Terms and
          Conditions shall supersede the Terms and Conditions stated herein so
          far as the subscribed/paid Services on the Platform are concerned, for
          all other use and access related to the Website/Platform/Services,
          these terms shall govern. 5 You are responsible for maintaining the
          confidentiality and security of your User Account credentials and may
          not disclose your credentials to any third party. You are liable for
          any and all activities conducted through your User Account. All
          payments received pursuant to the use of the User Account shall be
          deemed to have been paid by you, it shall be considered as a genuine
          consideration and Claydeck shall not be responsible or liable for any
          refunds due to any fraudulent charges. 6 You will not use, copy,
          adapt, modify, prepare derivative works of, distribute, license, sell,
          transfer, publicly display, publicly perform, transmit, broadcast or
          otherwise exploit the Website. No licenses or rights are granted to
          you by implication or otherwise under any intellectual property rights
          owned or controlled by Website or its licensors, except for the
          licenses and rights expressly granted in these Terms. 7 Refund and
          Cancellation: You may cancel your subscription to the Platform at any
          time. As a general rule, Claydeck is not bound to make any refunds for
          the amount of consideration already paid by you, unless otherwise
          stated/ agreed upon between the Parties hereto, under the Services as
          described in the Subscription Terms and Conditions. 8 You acknowledge
          and agree that, to the maximum extent permitted by law, the entire
          risk arising out of your access to and use of the Website, and/or use
          of Services via the Website or the Platform or any other interaction
          you have with Claydeck personnel whether in person or online remains
          with you. Neither Claydeck nor any other party involved in creating,
          producing, or delivering the Website will be liable for any
          incidental, special, exemplary or consequential damages, including
          lost profits, loss of data or loss of goodwill, service interruption,
          computer damage or system failure or the cost of substitute products
          or services, or for any damages for personal or bodily injury or
          emotional distress arising out of or in connection with (i) these
          Terms, and (ii) from the use of or inability to use the Website. In no
          event will Claydeck’s aggregate liability arising out of or in
          connection with these Terms and your use of the Website/Platform
          including, but not limited to, payment of the charges for the services
          provided and/or any other applicable charges via the Website/Platform,
          or from the use of or inability to use the Website/Platform, exceed
          the amounts you have paid via the Website/Platform
        </Pmd>
        <MainHeading>Contact Details</MainHeading>
        <Pmd className="mt-3 mb-5">
          House No 117/22, Dev Colony, Rohtak, 124001 email:
          rahulkhatry@arctechlabs.com Ph: +91-7206988274
        </Pmd>
      </Box>
    </>
  );
}
