import React from "react";
import {Input, Textarea} from "@chakra-ui/react";

const InputTextAreaOutline = (props) => {
  return (
    <Textarea
      size="md"
      height={{ base: "42px", lg: "56px" }}
      bg="claydeck.white.primary"
      color="claydeck.text.primary"
      variant="outline"
      fontSize={{ base: "16px", lg: "18px" }}
      _hover={{ bg: "blue.100" }}
      _placeholder={{
        color: "claydeck.text.tertiary",
        fontSize: { base: "14px", lg: "16px" },
        letterSpacing: "0.6px",
      }}
      fontWeight={"500"}
      line-height="140%"
      letterSpacing="0.6px"
      borderRadius={"8px"}
      border={"1px solid #AEAAAE"}
      {...props}
    />
  );
};

export default InputTextAreaOutline;
