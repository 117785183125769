import React, {useState, useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import { Container, Flex, Box, Icon } from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";

import {AiOutlineClockCircle, AiOutlineFilePdf} from "react-icons/ai";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdDelete, MdEdit } from "react-icons/md";
import axios from "axios";
import {NEW_API_URL} from "../../redux/types";
import {useSelector} from "react-redux";
import {GoCheckCircleFill} from "react-icons/go";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import Modal from "../../Components/Modal";
import {PlusSquareIcon} from "@chakra-ui/icons";
import InputOutline from "../../Components/Input/InputOutline";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";
const colors = ["#B79BFF", "#A6F7E2", "#FFE5A5", "#C7FFA5"];

const LessonPlanDetails = () =>  {
    const navigate = useNavigate();
    const [topicName, setTopicName] = useState('');
    const [subtopicURL, setsubtopicURL] = useState('');
    const [timeRequired, setTimeRequired] = useState('');
    const [subtopic_id, setSubtopic_id] = useState('');

    const {id} = useParams();
    const location = useLocation();
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);


   const [planDetails, setPlanDetails] = useState([]);
   const [planDetailsSelected, setplanDetailsSelected] = useState([]);

    const [fetchLoading, setFetchLoading] = useState(false);
    const [isEditingModal, setIsEditingModal] = useState(false);
    const [lessonPlanList, setLessonPlanList] = useState([]);

    const username = useSelector(state => state.user.username);
    // const workspaceId = useSelector(state => state.user.selectedWorkspace.workspace_id);


    const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
    let workspaceId = '';
    if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
        workspaceId = selectedWorkspace.workspace_id
    }

    const addTopic = async () => {
        try {
            if (topicName.trim() !== "" && timeRequired.trim() !== "") {
                // setLoading(true)
                const requestBody = {
                    name: topicName.trim(),
                    time_estimate: timeRequired,
                    url: subtopicURL.trim() !== "" ? subtopicURL.trim() : "nan",
                    type: "reading/test","subtopic_question_attempted" : 0, "subtopic_score": 0
                }
                await axios.post(`${NEW_API_URL}/lessonplan/post_subtopic/${username}/${workspaceId}/topics/${id}/subtopics/`, requestBody)
                    .then(() => {
                        getLessonPlan()
                    })
                    .catch((e) => {
                        console.error(e)
                        // Toast.show({
                        //   type: 'error',
                        //   text1: 'Error',
                        //   text2: "Couldn't create topic"
                        // })
                    })
            } else {
                // Toast.show({
                //   type: 'error',
                //   text1: 'Error',
                //   text2: "Please enter valid details"
                // })
            }
        } catch (e) {
            console.error(e)
            // Toast.show({
            //   type: 'error',
            //   text1: 'Error',
            //   text2: "Something unexpected happened"
            // })
        } finally {
            // setLoading(false)
        }
    }


    const getLessonPlan = async () => {
        setFetchLoading(true);
        try {
            await axios
                .get(
                    `${NEW_API_URL}lessonplan/get_lessonplan/${username}/${workspaceId}/`
                )
                .then((response) => {
                    console.log("lesson plan res --->", response.data.lesson_plan);
                    if (response.status === 200) {
                        setLessonPlanList(response.data.lesson_plan.topics);
                        // console.log("plan details",response.data.lesson_plan.topics.filter(i=>{
                        //     // console.log(i)
                        //     // console.log()
                        //     return i.topic_id === parseInt(id)
                        // }),id)
                        setPlanDetails(response.data.lesson_plan.topics.filter(i=>{
                            // console.log(i)
                            // console.log()
                            return i.topic_id === parseInt(id)
                        })[0].subtopics);
                        setplanDetailsSelected(response.data.lesson_plan.topics.filter(i=>{
                            // console.log(i)
                            // console.log()
                            return i.topic_id === parseInt(id)
                        })[0])
                    }
                    // setTaskList(response?.data?.metrics?.todays_tasks?.tasks);
                })
                .catch((error) => {
                    console.error("lesson plan error --->", error);
                    // Toast.show({
                    //   type: "error",
                    //   text1: "Error",
                    //   text2: "Couldn't load data",
                    // });
                });
        } catch (e) {
            console.error(e);
            // Toast.show({
            //   type: "error",
            //   text1: "Error",
            //   text2: "Something unexpected happened",
            // });
        } finally {
            setFetchLoading(false);
        }
    };

   useEffect(() => {
       console.log(location.state,'locations tate topic',!(location.hasOwnProperty('state') && location['state'] && location['state'].hasOwnProperty('topics')))
     setPlanDetails(location?.state?.topics);
     // if(!(location.hasOwnProperty('state') && location['state'] && location['state'].hasOwnProperty('topics'))){
     //     getLessonPlan();
     //
     // }
       getLessonPlan();

   }, [location]);
    const updateLessonPlan = async(username, workspace_Id, topicId, subTopicId, status) => {

        let res = await axios.get(`${NEW_API_URL}/lessonplan/update_lessonplan/${username}/${workspace_Id}/${topicId}/${subTopicId}/${status}/`)
            .then(function(res) {
                console.log("get lessonplan res", JSON.stringify(res.data))
                getLessonPlan()

                return res.data
            })
            .catch((err) => console.log("update lessonplan err", JSON.stringify(err)));
        console.log('res');
        console.log(res);
        return res
    }

    const changeStatus = async (e,topicId,subtopic_id) => {
        let status_send = e ? "True" : "False";
        let res = await updateLessonPlan(
            username,
            workspaceId,
            topicId,
            subtopic_id,
            status_send
        );
        if (res.status === 200) {
            // setIsChecked(e);
            getLessonPlan();
        }
    };

    const updateSubtopic = async () => {
        try {
            console.log(topicName,timeRequired)
            if (topicName.trim() !== "" && timeRequired.trim() !== "") {
                // setLoading(true)
                const timeEstimate = parseFloat(timeRequired.trim())
                const requestBody = {
                    name: topicName.trim(),
                    time_estimate: timeEstimate,
                    url: subtopicURL.trim() !== "" ? subtopicURL.trim() : "nan",
                    type: "reading/test"
                }
                console.log(requestBody)
                await axios.put(`${NEW_API_URL}/lessonplan/update_subtopic/${username}/${workspaceId}/${id}/subtopics/${subtopic_id}/`,
                    requestBody)
                    .then(() => {
                        getLessonPlan()
                    })
                    .catch((e) => {
                        console.error(e)
                        // Toast.show({
                        //     type: 'error',
                        //     text1: 'Error',
                        //     text2: "Couldn't update subtopic"
                        // })
                    })
            } else {
                // Toast.show({
                //     type: 'error',
                //     text1: 'Error',
                //     text2: "Please enter valid details"
                // })
            }
        } catch (e) {
            console.error(e)
            // Toast.show({
            //     type: 'error',
            //     text1: 'Error',
            //     text2: "Something unexpected happened"
            // })
        } finally {
            // setLoading(false)
        }
    }



    const deleteSubtopic = async (topicId, subtopicId) => {
        try {
            await axios.delete(`${NEW_API_URL}/lessonplan/delete_subtopic/${username}/${workspaceId}/${topicId}/${subtopicId}/`)
                .then(() => getLessonPlan())
                .catch((e) => console.error(e))
        } catch (e) {
            console.error(e)
        }
    }



   console.log(planDetails,'plan details');

    const styles = {
        task: {
            width: "100%",
            bgColor: "claydeck.white.primary",
            borderRadius: "5px",
            padding: { base: "12px 8px", md: "14px 10px", xl: "16px 12px" },
            _hover: {
                bg: "claydeck.brand.hover",
                boxShadow: " 0px 10px 20px -5px rgba(0, 0, 0, 0.05)",
            },
        },
        taskTitle: {
            color: "#1C1B1F",
            fontSize: { base: "14px", lg: "16px" },
            fontWeight: 500,
            lineHeight: "120%",
            letterSpacing: "0.15px",
            marginBottom: { base: "3px", lg: "4px" },
        },
        taskDetail: {
            margin:0,
            color: "claydeck.text.tertiary",
            fontSize: { base: "10px", lg: "12px" },
            fontWeight: 500,
        },
    };

   return (
     <Box width={"100%"} bgColor={"claydeck.white.primary"}>
       <Container
         minHeight={"100vh"}
         minWidth={{
           base: "max-content",
           md: "350px",
           lg: "500px",
           xl: "650px",
         }}
       >
         <Flex
           width={"100%"}
           gap={{ base: "16px", lg: "24px" }}
           direction={"column"}
           margin={{ base: "36px 0px", lg: "72px 0px" }}
         >
           <Flex justifyContent={"space-between"} alignItems={"center"}>
             <H2 style={{cursor:'pointer'}} onClick={()=>{
                 navigate('/lesson-plan')
             }} fontSize="18px">{'<'}</H2>
             <H2 fontSize="18px">{fetchLoading? "":planDetailsSelected.name}</H2>
             <Flex as={"button"} >
               <Icon
                   onClick={()=>{setShowAdd(true)}}
                 as={PlusSquareIcon}
                 boxSize="24px"
                 color="claydeck.text.tertiary"
               />
                 <Icon
                     as={MdEdit}
                     style={{marginLeft:'10px'}}
                     boxSize="24px"
                     onClick={()=>{
                         setIsEditingModal(!isEditingModal);
                     }}
                     color="claydeck.text.tertiary"
                 />
             </Flex>

           </Flex>
           {planDetails &&  planDetails.map((plan, index) => {
               // console.log(plan,'pll')
               return (
                   <Box
                       key={index}

                       sx={styles.task}
                       borderLeft={`9px solid ${colors[index % 4]} !important`}
                   >
                       <Flex justifyContent={"space-between"} alignItems={"center"}>
                           <Box>
                               <Pmd cursor={'pointer'}
                                    onClick={()=>{
                                        if(plan.url?.includes("http")) {
                                            window.open(plan.url, '_blank')
                                        }
                                        // window.location.href = plan.url
                                    }} sx={styles.taskTitle}>{plan.name}</Pmd>
                               <Flex gap={"3px"} align={"center"}>
                                   <Icon
                                       as={AiOutlineClockCircle}
                                       color={"claydeck.text.tertiary"}
                                   />
                                   {plan.url?.includes("http") && <Icon
                                       as={AiOutlineFilePdf}
                                       color={"claydeck.text.tertiary"}
                                   />}
                                   <Psm sx={styles.taskDetail}>
                                       {plan.time_estimate} hours
                                   </Psm>
                               </Flex>
                           </Box>
                           <Flex
                               style={{cursor:'pointer'}}
                               onClick={() => {
                                   changeStatus(!plan.completed,id,plan.subtopic_id);
                               }}
                               as={"button"}
                               height={"20px"}
                               width="20px"
                               alignItems={"center"}
                               border={plan.completed ? "0px" : "0.5px solid #828282"}
                               borderRadius={"15px"}
                           >
                               <Icon
                                   as={GoCheckCircleFill}
                                   boxSize="22px"
                                   color={"#28ad39"}
                                   display={plan.completed ? "initial" : "none"}
                                   borderRadius="15px"
                               />
                           </Flex>
                           {
                               isEditingModal && <div>
                                    <ButtonSecondary style={{marginRight:'10px'}} onClick={()=>{
                                        deleteSubtopic(id,plan.subtopic_id);
                                    }}><MdDelete/></ButtonSecondary>
                                    <ButtonSecondary onClick={()=>{
                                        setShowEdit(true);
                                        setTopicName(plan.name);
                                        setTimeRequired(plan.time_estimate+"");
                                        setsubtopicURL(plan.url);
                                        setSubtopic_id(plan.subtopic_id)
                                    }}><MdEdit/></ButtonSecondary>
                               </div>
                           }

                       </Flex>
                   </Box>
               );
           })}
         </Flex>
       </Container>
         <Modal show={showEdit} handleClose={()=>{setShowEdit(false)}}>

             <H2>Edit Lesson Plan</H2>
             <InputOutline value={topicName} onChange={(e)=>{setTopicName(e.target.value)}} style={{marginTop:10,marginBottom:10}} placeholder={'Enter sub topic name'} />
             <InputOutline value={timeRequired} onChange={(e)=>{setTimeRequired(e.target.value)}} style={{marginTop:10,marginBottom:10}} type={'numeric'} placeholder={'Time required*'} />
             <InputOutline value={subtopicURL} onChange={(e)=>{setsubtopicURL(e.target.value)}} style={{marginTop:10,marginBottom:10}} placeholder={'Subtopic Url'} />
             <ButtonPrimary onClick={()=>{updateSubtopic()}} style={{width:'100%'}}>Edit Plan</ButtonPrimary>

         </Modal>

         <Modal show={showAdd} handleClose={()=>{setShowAdd(false)}}>
             <H2>Add Lesson Plan</H2>
             <InputOutline value={topicName} onChange={(e)=>{setTopicName(e.target.value)}} style={{marginTop:10,marginBottom:10}} placeholder={'Enter sub topic name'} />
             <InputOutline value={timeRequired} onChange={(e)=>{setTimeRequired(e.target.value)}} style={{marginTop:10,marginBottom:10}} type={'numeric'} placeholder={'Time required*'} />
             <InputOutline value={subtopicURL} onChange={(e)=>{setsubtopicURL(e.target.value)}} style={{marginTop:10,marginBottom:10}} placeholder={'Subtopic Url'} />
             <ButtonPrimary onClick={()=>{addTopic()}} style={{width:'100%'}}>Create Plan</ButtonPrimary>


         </Modal>
     </Box>
   );
}

export default LessonPlanDetails;