import React, {useEffect, useState} from 'react';
import {Avatar, Box, Flex, Image} from "@chakra-ui/react";
import Pmd from "./Typography/Text/Pmd";
import Plg from "./Typography/Text/Plg";
import {CloseIcon} from "@chakra-ui/icons";
import Psm from "./Typography/Text/Psm";
import InputOutline from "./Input/InputOutline";
import ButtonPrimary from "./Button/ButtonPrimary";
import {useSelector} from "react-redux";
import axios from "axios";
import {NEW_API_URL} from "../redux/types";

function AskBudAccent(props) {
    const [budOpen,setBudOpen] = useState(false);
    const [text,setText] = useState('');
    const username = useSelector(state => state.user.username);

    useEffect(()=>{
        if(props.forceOpen){
            setBudOpen(true);
            props.setForceOpen(false);
        }
    },[props.forceOpen])


    useEffect(()=>{
        console.log(props.context)
        if(props.context){

            if(chat.length<=0){
                setChat([{
                    from:'bud',
                    text:'Hi '+username+', ' +
                        'I’m here to help you clarify any doubts regarding '+props.context,
                    time:new Date().toISOString()
                }])
            }
        }
    },[props.context])


    const [chat,setChat] = useState([
    ])
    function formatTime(isoString) {
        const date = new Date(isoString);

        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // If the hour is 0, set it to 12
        const minutesStr = minutes < 10 ? '0' + minutes : minutes; // Add leading zero to minutes if needed

        return `${hours}:${minutesStr} ${ampm}`;
    }




    const [socket, setSocket] = useState(null);
    // const [messages, setMessages] = useState([]);

    useEffect(() => {
        // if(budOpen && socket)
        //     return
        // Create a WebSocket connection
        // const ws = new WebSocket('wss://wy150ww898.execute-api.ap-south-1.amazonaws.com/dev/');
        //
        // // Set the WebSocket instance to the state
        // setSocket(ws);
        //
        // // WebSocket open event
        // // ws.onopen = () => {
        // //     console.log('WebSocket connection opened');
        // //
        // //     // Send initial payload on connect
        // //     const initialPayload = {
        // //         "action" : "message",
        // //         "messages" : [
        // //             {"role": "system", "content": "You are a UPSC assistant. Try to use succint to the point stoic language with academic inclination"},
        // //             // {"role": "user", "content": "Which subject gets the most questions in mains"},
        // //             // {"role": "assistant", "content": "History gets the most questions although it is also sometimes closely matched with Polity"},
        // //             // {"role": "user", "content": "Which chapters in history are considered most important?"}
        // //         ]
        // //     };
        // //     ws.send(JSON.stringify(initialPayload));
        // // };
        //
        // // WebSocket message event
        // ws.onmessage = (event) => {
        //     console.log('Message from server:', event.data);
        //     // Add the received message to the state
        //     // setMessages((prevMessages) => [...prevMessages, event.data]);
        //     setChat((prev)=>[...prev,{'from':'bud',text:JSON.parse(event.data).message,time:new Date().toISOString()}])
        // };
        //
        // // WebSocket error event
        // ws.onerror = (error) => {
        //     console.error('WebSocket error:', error);
        // };
        //
        // // WebSocket close event
        // ws.onclose = () => {
        //     console.log('WebSocket connection closed');
        // };
        //
        // // Cleanup on component unmount
        // return () => {
        //     ws.close();
        // };
    }, [budOpen]); // Empty dependency array ensures useEffect runs once on mount

    // Function to send a payload later
    const sendMessage = async (msgs) => {
        const payload = {
            "action" : "message",
            "messages" : [
                // {"role": "system", "content": "You are a UPSC assistant. Try to use succint to the point stoic language with academic inclination"},
                // {"role": "user", "content": "Which subject gets the most questions in mains"},
                // {"role": "assistant", "content": "History gets the most questions although it is also sometimes closely matched with Polity"},
                // {"role": "user", "content": "Which chapters in history are considered most important?"}
                ...msgs
            ]
        }
        const r = await axios.post(NEW_API_URL+'gamification/ask_bud/',payload)

            setChat((prev)=>[...prev,{'from':'bud',text:r.data.message,time:new Date().toISOString()}])

        // if (socket) {
        //
        //     socket.send(JSON.stringify(payload));
        // }
    };






    return (
        <>
            <Flex onClick={()=>{setBudOpen(!budOpen)}} cursor={'pointer'} w={'80px'} h={'80px'} alignItems={'center'} justifyContent={'center'} bg={"claydeck.brand.primary"} border={'2px solid white'} boxShadow={'0px 4px 4px 0px #00000040'} position={'fixed'} bottom={'20px'} right={'20px'} borderRadius={'50px'}>
                <Image h={'20px'} w={'20px'} src={'/hintai.png'}></Image>
                <Pmd margin={0} color={'white'} ml={'5px'}><strong>Ask<br/>Bud</strong></Pmd>
            </Flex>

            {
                budOpen && <Box overflow={'hidden'} w={'30vw'} bg={'#003D5F'} h={'60vh'} position={'fixed'} right={'130px'} bottom={'20px'} borderRadius={'12px'}>
                    <Flex h={'100%'}  flexDirection={'column'}>
                    <Box px={'20px'} h={'70px'}  bg={'white'} borderRadius={'12px'} boxShadow={'0px 2px 2px 0px #0000001A'}>
                        <Flex h={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                            <Plg margin={0}><strong>Ask Bud</strong></Plg>
                            <CloseIcon  color={'black'} boxSize={'24px'} ml={'10px'} cursor={'pointer'} onClick={()=>{
                                setBudOpen(false)
                            }}/>
                        </Flex>
                    </Box>
                    <Box mb={'12px'} flex={1} overflow={'auto'}>
                    {
                        chat.map((item,index)=>(
                            <Box  key={index}>
                                {item.from === 'bud' && <Box mt={'16px'} ml={'16px'}>
                                    <Flex>
                                        <Avatar src={'/budavatar.png'}></Avatar>
                                        <Box ml={'12px'} borderRadius={'12px'} p={'10px'} maxW={'60%'} bg={'white'}>
                                            <Psm margin={0}>{item.text}</Psm>
                                            <Psm fontSize={'8px'} float={'right'} margin={0}
                                                 mt={'6px'}>{formatTime(item.time)}</Psm>
                                        </Box>
                                    </Flex>
                                </Box>}
                                {item.from === 'you' && <Box mt={'16px'} mr={'16px'}>
                                    <Flex justifyContent={'flex-end'}>
                                        <Box mr={'12px'} borderRadius={'12px'} p={'10px'} maxW={'60%'} bg={'white'}>
                                            <Psm margin={0}>{item.text}</Psm>
                                            <Psm fontSize={'8px'} float={'left'} margin={0}
                                                 mt={'6px'}>{formatTime(item.time)}</Psm>
                                        </Box>
                                        <Avatar src={'/avt.png'}></Avatar>

                                    </Flex>
                                </Box>}
                            </Box>
                        ))
                    }
                    </Box>
                    <Flex px={'12px'} mb={'12px'} alignItems={'center'}>
                        <InputOutline value={text} onChange={(e)=>{setText(e.target.value)}} mr={'12px'} border={'1px solid white'} bg={'transparent'} placeholder={'Type here'}></InputOutline>
                        <ButtonPrimary onClick={()=>{
                            let updatedChat = chat.concat(
                                {
                                    from:'you',
                                    text:text,
                                    time:new Date().toISOString()
                                })
                            setChat(updatedChat)
                            sendMessage(
                                [
                                    {"role": "system", "content":props.initText?props.initText : "You are a UPSC assistant. Try to use succint to the point stoic language with academic inclination"},

                                    ...updatedChat.slice(Math.max(updatedChat.length - 10,updatedChat.length === 1? 0:1)).map((item)=>{
                                    return {"role":item.from==='bud'?'assistant':'user',content:item.text}
                                })]
                            )


                            setText('')
                        }} border={'1px solid white'} bg={'transparent'}><Image h={'40px'} w={'40px'} src={'/sendicon.png'}/></ButtonPrimary>
                    </Flex>
                    </Flex>
                </Box>
            }
        </>
    );
}

export default AskBudAccent;
