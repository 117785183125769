import React from 'react';
import { Heading } from '@chakra-ui/react'
function H2(props) {
    return (
      <Heading fontWeight={600} {...props} fontSize="md" fontFamily={"Roboto"}>
        {props.children}
      </Heading>
    );
}

export default H2;
