import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import { Box, Flex, Image } from "@chakra-ui/react";
import H1 from "../../Components/Typography/Heading/H1";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";

import { useDispatch } from "react-redux";
import {
  loginUser,
  addFeed
} from "../../redux/actions/userActions";

const JoinFeed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedFeeds, setSelectedFeeds] = useState([]);

  const feeds = [
    { id: "1", agency: "Hindustan Times", logo: "./assets/images/HTLogo.png" },
    { id: "2", agency: "Times of India", logo: "./assets/images/TOILogo.png" },
    { id: "3", agency: "The Hindu", logo: "./assets/images/HinduLogo.png" },
  ];

  const styles = {
    cardsContainer: {
      wrap: "wrap",
      gap: "18px",
      p: "32px",
    },
    submitBtn: {
      h: "42px",
      w: "150px",
      color: "#fff",
      bgColor: selectedFeeds.length > 0 ? "#6750A4" : "#ebdef8",
    },
  };

 const handleSubmit = () => {
   if (selectedFeeds.length < 1) {
     alert("Select at least one workspace before proceeding");
     return;
   }

   selectedFeeds.map((feed) => {
     dispatch(addFeed(feed));
   });

   if (!location.state?.registeredUser) {
     setSelectedFeeds([]);
     dispatch(
       loginUser({
         username: location.state.username,
         password: location.state.password,
       })
     )
     navigate("/home");
    }
 };

  const Card = ({ item }) => {
    const index = selectedFeeds.findIndex((feed) => feed.id === item.id);
    const selected = index !== -1;

    const styles = {
      cardContainer: {
        // height: "150px",
        width: "400px",
        bgColor: "#FFFBFE",
        border: selected ? "2px solid #6750A4" : "0.2px solid #dbdbdd",
        borderRadius: "8px",
      },
      cardHeadingContainer: {
        height: "72px",
        bgColor: "#E8DEF8",
        alignItems: "center",
        // border: "0.2px solid #CAC4D0",
        borderRadius: "8px 8px 0px 0px",
        paddingX: "16px",
      },
      cardTitle: {
        color: "#6750A4",
        fontSize: "16px",
        letterSpacing: "0.15px",
        marginLeft: "18px",
      },
    };

    return (
      <Box className="shadow-primary" sx={styles.cardContainer}>
        <Flex sx={styles.cardHeadingContainer}>
          <Image
            height={"40px"}
            objectFit={"contain"}
            src={item.logo}
            alt={"img"}
          />
          <H2 sx={styles.cardTitle}>{item.agency}</H2>
        </Flex>
        <Flex direction={"column"} justify={"flex-end"} padding={"16px"}>
          <ButtonSecondary
            alignSelf={"flex-end"}
            borderColor={selected ? "#6750A4" : ""}
            onClick={() => {
              if (selected) {
                const arr = selectedFeeds.filter((feed) => feed.id !== item.id);
                setSelectedFeeds(arr);
              } else {
                setSelectedFeeds((prevState) => [...prevState, item]);
              }
            }}
          >
            <H3>{selected ? "Selected" : "Select"}</H3>
          </ButtonSecondary>
        </Flex>
      </Box>
    );
  };

  return (
    <Flex direction={"column"} align={"center"}>
      <H1 sx={{ fontSize: "32px" }} marginTop={"72px"}>
        Join Feed
      </H1>
      <H2 color={"claydeck.text.secondary"} marginY={"32px"}>
        Select at least one feed
      </H2>
      <Box>
        <Flex sx={styles.cardsContainer}>
          {feeds.map((item, index) => (
            <Card item={item} key={index} />
          ))}
        </Flex>
        <Flex justify={"flex-end"} p={"32px"}>
          <ButtonSecondary sx={styles.submitBtn} onClick={() => handleSubmit()}>
            Continue
          </ButtonSecondary>
        </Flex>
      </Box>
    </Flex>
  );
};

export default JoinFeed;
