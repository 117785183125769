import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import { Box, Flex, Image, Spinner } from "@chakra-ui/react";
import H1 from "../../Components/Typography/Heading/H1";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { addWorkspaces, joinWorkspace, setSelectedWorkspace } from "../../redux/actions/userActions";

const JoinWorkspace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const username = useSelector(state => state.user.username);
  const firstName = useSelector(state => state.user.profile.firstName);
  const lastName = useSelector(state => state.user.profile.lastName);
  console.log("location state workspace",location.state,username,firstName,lastName)
  const [loading, setLoading] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);

  useEffect(() => {
    getAllWorkspaces();
  }, []);

  const styles = {
    cardsContainer: {
      wrap: "wrap",
      gap: "18px",
      p: "32px",
    },
    submitBtn: {
      h: "42px",
      w: "150px",
      color: "#fff",
      bgColor: selectedWorkspaces.length > 0 ? "#6750A4" : "#ebdef8",
    },
  };

  const getAllWorkspaces = async () => {
    setLoading(true);
    await axios
      .get(NEW_API_URL + "community/get_all_workspaces/")
      .then((res) => {
        if (res.status === 200) {
          setWorkspaces(res.data.workspace);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("workspace err", err);
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (selectedWorkspaces.length < 1) {
      alert("Select at least one workspace before proceeding");
      return;
    }

    selectedWorkspaces.map((workspace) => {
      console.log(username,firstName,lastName,'mmmmm')

      const workspaceId = workspace.workspace_id;
      if (location.state?.registeredUser) {
        dispatch(joinWorkspace(workspaceId, username, firstName, lastName));
      } else {
        dispatch(
          joinWorkspace(
            workspaceId,
            location.state.username,
            location.state.first_name,
            location.state.last_name
          )
        );
      }
    });

    dispatch(addWorkspaces(selectedWorkspaces));

    if (location.state?.registeredUser) {
      setSelectedWorkspaces([]);
      navigate("/home");
      return;
    }
    // set the first workspace in the array as default selected workspace
    dispatch(setSelectedWorkspace(selectedWorkspaces[0]));

    setSelectedWorkspaces([]);
    navigate("/survey-questions", {
      state: {
        username: location.state.username,
        password: location.state.password,
      },
    });
  };

  const Card = ({ item }) => {
    const index = selectedWorkspaces.findIndex(
      (workspace) => workspace.workspace_id === item.workspace_id
    );
    const selected = index !== -1;

    const rooms_list = Array.from(
      item.rooms.map((room) => {
        return room.name;
      })
    ).join(" ");

    const styles = {
      cardContainer: {
        height: "215px",
        width: "400px",
        bgColor: "#FFFBFE",
        border: selected ? "2px solid #6750A4" : "0.2px solid #dbdbdd",
        borderRadius: "8px",
      },
      cardHeadingContainer: {
        height: "72px",
        bgColor: "#E8DEF8",
        alignItems: "center",
        borderRadius: "8px 8px 0px 0px",
        paddingX: "16px",
      },
      cardTitle: {
        color: "#6750A4",
        fontSize: "16px",
        letterSpacing: "0.15px",
        marginLeft: "18px",
      },
      roomText: {
        fontSize: "14px",
        fontWeight: "700",
        letterSpacing: "0.25px",
        margin: "0px",
        marginRight: "6px",
      },
    };

    return (
      <Box className="shadow-primary" sx={styles.cardContainer}>
        <Flex sx={styles.cardHeadingContainer}>
          <Image
            height={"40px"}
            objectFit={"contain"}
            src={"./assets/images/profile.png"}
            alt={"img"}
          />
          <H2 sx={styles.cardTitle}>{item.workspace_name}</H2>
        </Flex>
        <Flex
          height={"143px"}
          direction={"column"}
          justify={"space-between"}
          p={"16px"}
        >
          {rooms_list ? (
            <Flex>
              <Pmd sx={styles.roomText}>Rooms:</Pmd>
              <Pmd sx={{ fontSize: "14px" }}>{rooms_list}</Pmd>
            </Flex>
          ) : (
            <></>
          )}
          <ButtonSecondary
            onClick={() => {
              if (selected) {
                const arr = selectedWorkspaces.filter(
                  (workspace) => workspace.workspace_id !== item.workspace_id
                );
                setSelectedWorkspaces(arr);
              } else {
                setSelectedWorkspaces((prevState) => [...prevState, item]);
              }
            }}
            alignSelf={"flex-end"}
            borderColor={selected ? "#6750A4" : ""}
            marginTop={"24px"}
          >
            <H3>{selected ? "Selected" : "Select"}</H3>
          </ButtonSecondary>
        </Flex>
      </Box>
    );
  };

  return (
    <Flex direction={"column"} align={"center"}>
      <H1 sx={{ fontSize: "32px" }} marginTop={"72px"}>
        Join Workspace
      </H1>
      <H2 color={"claydeck.text.secondary"} marginY={"32px"}>
        Select at least one workspace
      </H2>
      {loading ? (
        <Spinner />
      ) : (
        <Box>
          <Flex sx={styles.cardsContainer}>
            {workspaces.map((item, index) => (
              <Card item={item} key={index} />
            ))}
          </Flex>
          <Flex justify={"flex-end"} p={"32px"}>
            <ButtonSecondary
              sx={styles.submitBtn}
              disabled={selectedWorkspaces.length === 0}
              onClick={() => handleSubmit()}
            >
              Continue
            </ButtonSecondary>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default JoinWorkspace;