export const news_map_data = {
    "daily_news": [
      {
        "id": "1",
        "title": "Daily News Title 1",
        "summary": "Summary of the daily news 1.",
        "date": "2024-07-12",
        "icon": "/sample_news_img.png",
        "priority": "High",
        "read_status": false
      },
      {
        "id": "2",
        "title": "Daily News Title 2",
        "summary": "Summary of the daily news 2.",
        "date": "2024-07-12",
        "icon": "/sample_news_img.png",
        "priority": "Low",
        "read_status": true
      }
      ,
      {
        "id": "2",
        "title": "Daily News Title 2",
        "summary": "Summary of the daily news 2.",
        "date": "2024-07-12",
        "icon": "/sample_news_img.png",
        "priority": "Low",
        "read_status": true
      }
      ,
      {
        "id": "2",
        "title": "Daily News Title 2",
        "summary": "Summary of the daily news 2.",
        "date": "2024-07-12",
        "icon": "/sample_news_img.png",
        "priority": "Low",
        "read_status": true
      }
      ,
      {
        "id": "2",
        "title": "Daily News Title 2",
        "summary": "Summary of the daily news 2.",
        "date": "2024-07-12",
        "icon": "/sample_news_img.png",
        "priority": "Low",
        "read_status": true
      }
      ,
      {
        "id": "2",
        "title": "Daily News Title 2",
        "summary": "Summary of the daily news 2.",
        "date": "2024-07-12",
        "icon": "/sample_news_img.png",
        "priority": "Low",
        "read_status": true
      }
      ,
      {
        "id": "2",
        "title": "Daily News Title 2",
        "summary": "Summary of the daily news 2.",
        "date": "2024-07-12",
        "icon": "/sample_news_img.png",
        "priority": "Low",
        "read_status": true
      }
      ,
      {
        "id": "2",
        "title": "Daily News Title 2",
        "summary": "Summary of the daily news 2.",
        "date": "2024-07-12",
        "icon": "/sample_news_img.png",
        "priority": "Low",
        "read_status": true
      }
    ],
    "trending_news": [
      {
        "id": "1",
        "title": "Trending News Title 1",
        "summary": "Summary of the trending news 1.",
        "date": "2024-07-12",
        "icon": "/sample_news_img.png",
        "priority": "High",
        "read_status": false
      },
      {
        "id": "2",
        "title": "Trending News Title 2",
        "summary": "Summary of the trending news 2.",
        "date": "2024-07-12",
        "icon": "/sample_news_img.png",
        "priority": "Low",
        "read_status": true
      }
    ],
    "saved_news": [
      {
        "id": "1",
        "title": "Saved News Title 1",
        "summary": "Summary of the saved news 1.",
        "icon": "https://example.com/saved_icon.png",
        "read_status": false
      }
    ],
    "news_tests": [
      {
        "id": "1",
        "topics": ["Technology", "Business"],
        "score": 95,
        "icon": "https://example.com/test_icon1.png",
        "read_status": false
      },
      {
        "id": "2",
        "topics": ["Health", "Science"],
        "score": 88,
        "icon": "https://example.com/test_icon2.png",
        "read_status": true
      }
    ]
  }
