export const roomData = [
  {
    id: 1,
    title: "Coffee Room",
    total_member: 280,
    isOnline: true,
    img: "assets/images/TOILogo.png",
  },
  {
    id: 2,
    title: "Study Group",
    total_member: 120,
    isOnline: false,
    img: "assets/images//TOILogo.png",
  },
  {
    id: 3,
    title: "Gaming Lounge",
    total_member: 75,
    isOnline: true,
    img: "assets/imagesTOI/Logo.png",
  },
  {
    id: 4,
    title: "Artists' Corner",
    total_member: 50,
    isOnline: false,
    img: "assets/images/TOILogo.png",
  },
  {
    id: 5,
    title: "Fitness Club",
    total_member: 180,
    isOnline: false,
    img: "assets/images/TOILogo.png",
  },
  {
    id: 6,
    title: "Tech Talk",
    total_member: 220,
    isOnline: false,
    img: "assets/images/TOILogo.png",
  },
  {
    id: 7,
    title: "Cooking Workshop",
    total_member: 90,
    isOnline: false,
    img: "assets/images/TOILogo.png",
  },
  {
    id: 8,
    title: "Music Jam",
    total_member: 40,
    isOnline: false,
    img: "assets/images/TOILogo.png",
  },
];

export const eventData = [
  {
    id: 1,
    organizer: "John Patel",
    event_title: "Polity Topic Discussion Call",
    timestamp: "Fri Sep 01 2023 12:38:12 GMT+0530",
    canEnter: true,
  },
  {
    id: 2,
    organizer: "Jane Smith",
    event_title: "Science Symposium",
    timestamp: "Sat Sep 02 2023 10:15:00 GMT+0530",
    canEnter: false,
  },
  {
    id: 3,
    organizer: "Bob Johnson",
    event_title: "Art Exhibition",
    timestamp: "Sun Sep 03 2023 15:45:30 GMT+0530",
    canEnter: true,
  },
  {
    id: 4,
    organizer: "Sarah Lee",
    event_title: "History Lecture Series",
    timestamp: "Mon Sep 04 2023 09:30:00 GMT+0530",
    canEnter: false,
  },
  {
    id: 5,
    organizer: "David Wilson",
    event_title: "Technology Conference",
    timestamp: "Tue Sep 05 2023 14:20:45 GMT+0530",
    canEnter: false,
  },
  {
    id: 6,
    organizer: "Emily Davis",
    event_title: "Music Concert",
    timestamp: "Wed Sep 06 2023 20:00:00 GMT+0530",
    canEnter: false,
  },
  {
    id: 7,
    organizer: "Michael Brown",
    event_title: "Business Workshop",
    timestamp: "Thu Sep 07 2023 16:45:15 GMT+0530",
    canEnter: false,
  },
  {
    id: 8,
    organizer: "Linda Garcia",
    event_title: "Fashion Show",
    timestamp: "Fri Sep 08 2023 18:30:30 GMT+0530",
    canEnter: false,
  },
];

export const MaterialData = [
  {
    name: "NCERT file.png",
    type: "image/png",
  },
  {
    name: "Math problem solving video.mp4",
    type: "video/mp4",
  },
  {
    name: "Work sample.jpg",
    type: "image/jpeg",
  },
  {
    name: "NCERT PDF -1 (History).pdf",
    type: "application/pdf",
  },
  {
    name: "Document.docx",
    type: "application/docx",
  },
  {
    name: "Presentation.pptx",
    type: "application/pptx",
  },
];

export const joinmemberData = [
  {
    id: 1,
    name: "John Patel",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 2,
    name: "Jane Smith",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 3,
    name: "Bob Johnson",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 4,
    name: "Alice Brown",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 5,
    name: "Mike Williams",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 6,
    name: "Emily Davis",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 7,
    name: "Chris Lee",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 8,
    name: "Sarah Wilson",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 9,
    name: "Michael Johnson",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 10,
    name: "Lisa Anderson",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 11,
    name: "Daniel Garcia",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 12,
    name: "Laura Wilson",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 13,
    name: "Matthew Smith",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 14,
    name: "Megan Davis",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 15,
    name: "Ethan Brown",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 16,
    name: "Olivia Jones",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 17,
    name: "Aiden Smith",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 18,
    name: "Sophia White",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 19,
    name: "Liam Johnson",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 20,
    name: "Emma Davis",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 21,
    name: "Noah Brown",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 22,
    name: "Ava Wilson",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 23,
    name: "Lucas Johnson",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 24,
    name: "Oliver Davis",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 25,
    name: "Isabella Smith",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 26,
    name: "Mia Brown",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 27,
    name: "Liam Wilson",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 28,
    name: "Ella Johnson",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 29,
    name: "Lucas Davis",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 30,
    name: "Avery Smith",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 31,
    name: "Elijah Patel",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 32,
    name: "Scarlett Smith",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 33,
    name: "Benjamin Johnson",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 34,
    name: "Grace Davis",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 35,
    name: "Henry Wilson",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 36,
    name: "Lily Brown",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 37,
    name: "Alexander Smith",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 38,
    name: "Zoe Johnson",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 39,
    name: "Leo Davis",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 40,
    name: "Chloe Patel",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 41,
    name: "Michael Smith",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 42,
    name: "Nora Wilson",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 43,
    name: "William Johnson",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
  {
    id: 44,
    name: "Hannah Davis",
    isBlocked: false,
    isOnline: false,
    avatar: "",
  },
  {
    id: 45,
    name: "James Brown",
    isBlocked: true,
    isOnline: true,
    avatar: "",
  },
];
