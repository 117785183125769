import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";
import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex, Grid, GridItem,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import Plg from "../../Components/Typography/Text/Plg";
import P2xl from "../../Components/Typography/Text/P2xl";
const Home = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  let workspaceId = '';
  if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
    workspaceId = selectedWorkspace.workspace_id
  }


  const ArrowLeft = (props) => (
      <Image src={'/arrowleft.png'} width={'20px'} height={'22px'} {...props}></Image>

  );
  const ArrowRight = (props) => (
      <Image src={'/arrowright.png'} width={'20px'} height={'22px'} {...props}></Image>
      // <button
      //     {...props}
      //     className={'next'}/>
  );



  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [


    ]
  };



  const [continueLearning,setContinueLearning] = useState([
    {
      'title':"British History",
      "topic":"Histroy",
      "progress":'40%',
      "image":"/test_history.png"
    },
    {
      'title':"Earthquakes",
      "topic":"Geography",
      "progress":'40%',
      "image":"/test_history.png"
    },
    {
      'title':"Mughals",
      "topic":"History",
      "progress":'40%',
      "image":"/test_history.png"
    },
    {
      'title':"british",
      "topic":"history",
      "progress":'40%',
      "image":"/test_history.png"
    },{
      'title':"british",
      "topic":"history",
      "progress":'40%',
      "image":"/test_history.png"
    },{
      'title':"british",
      "topic":"history",
      "progress":'40%',
      "image":"/test_history.png"
    },{
      'title':"british",
      "topic":"history",
      "progress":'40%',
      "image":"/test_history.png"
    },{
      'title':"british",
      "topic":"history",
      "progress":'40%',
      "image":"/test_history.png"
    },{
      'title':"british",
      "topic":"history",
      "progress":'40%',
      "image":"/test_history.png"
    },
  ])




  const fetchHomeData = async () => {
    try {
      // setIsLoading(true);
      const res = await axios.get(
          `${NEW_API_URL}gamification/home_user_data/${username}/`
      );
      console.log(res.data);

      setContinueLearning(res.data.continue_learning)

      // setRecoCards(res.data.reco_cards);
      // setDailyTasks(res.data.daily_tasks);
      // setRecentTopics(res.data.continue_learning);
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      console.error("home data fetch failed", error);
    }
  };

  useEffect(()=>{
    fetchHomeData()
  },[])






  return (
      <Container mt={'10vh'} justifyContent={'center'} alignItems={'center'} w={'95%'} maxW='container.xl' >

        <Flex mb={'36px'} mt={'24px'}>

          <Box width={'30%'} mb={'16px'}>
          <Slider {...settings} draggable={true} arrows={true} indicator={true}>

            {continueLearning.map(item=>(
                <Box key={item} borderRadius={'12px'} overflow={'hidden'}
                     background={'linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url("'+item.topic_image+'") no-repeat center center'}
                     h={'200px'} w={'211px'}
                     mt={0}
                     backgroundSize={'cover'}
                >
                  <Container h={'100%'}>
                    <Flex flexDirection={'column'} justifyContent={'flex-end'} h={'100%'} pb={'12px'}>
                      <Pxl margin={0} color={'claydeck.white.primary'}>{item.topic_name}</Pxl>
                      <Pmd margin={0} color={'claydeck.white.primary'}>{item.subject_name}</Pmd>
                      <Box backgroundColor={'#E9E9EB'} w={'100%'} h={'4px'} borderRadius={50} overflow={'hidden'}>
                        <Box backgroundColor={'green'} w={item.percentageComplete} h={'4px'} borderRadius={50}></Box>

                      </Box>
                    </Flex>
                  </Container>
                </Box>
            ))}
          </Slider>
          </Box>
          <Flex position={'relative'} justifyContent={'space-around'} borderRadius={'20px'} boxShadow={'0px 4px 4px 0px #00000040'} backgroundColor='#CAF4FF' ml={'30px'} width={'100%'}>

            <Box position={'relative'} width={'300px'}>
            <Box position={'absolute'} top={'-20px'}>
          <Image src={'/welcomeimg.png'} width={'222px'} height={'217px'}/>
            </Box>
            </Box>

            <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
            <P2xl color={'#003D5F'} fontSize={'36px'} fontWeight={'700'}>Welcome to Claydeck</P2xl>

              {/*<Link to={'/learn'} style={{padding:'10px'}}>*/}
            <ButtonPrimary onClick={()=>{

              navigate("/learn")
            }}  width={'80%'} fontWeight={'300'}>
              <Image mr={'10px'} src={'/learnbook.png'} width={'20px'} height={'20px'}/>
              Start Learning

            </ButtonPrimary>
              {/*</Link>*/}
            </Flex>

          </Flex>

        </Flex>


        <Flex>
          <Box flex={1} padding={'10px'} mb={'96px'} >
        <Plg fontWeight={'700'}>Recent Topics</Plg>
        <HeadCard title={'Continue Learning'}
                  // titleButton={'Start a new topic'}
                  pb={'30px'}
                  shadow
                  border
                  maxHeight={'70vh'} overflowY={'auto'}
        >
          <Container>
          {continueLearning.map(item=>(
              <Flex borderRadius={'12px'} bgColor={'#D3EDFF'} mt={'24px'} key={item} boxShadow={'0px 4px 4px 0px #00000040'}>
                <Image borderRadius={'12px 0px 0px 12px'} src={item.topic_image} h={'170px'}></Image>
              {/*<Box  borderRadius={'12px'} overflow={'hidden'}*/}
              {/*     background={'linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url("'+item.topic_image+'") no-repeat center center'}*/}
              {/*     h={'100%'} w={'211px'}*/}
              {/*     mt={10}*/}
              {/*     backgroundSize={'cover'}*/}
              {/*>*/}
              {/*</Box>*/}
                <Box ml={'15px'} px={'10px'} mt={'10px'}>
                  <Flex flexDirection={'column'} justifyContent={'flex-start'} h={'100%'} pb={'12px'}>
                    <Pxl margin={0} color={'#323232'}>{item.topic_name}</Pxl>
                    <Pmd margin={0} color={'#747474'}>{item.subject_name}</Pmd>
                    <Box backgroundColor={'#BDBDBD'} w={'100%'} h={'4px'} borderRadius={50} overflow={'hidden'}>
                      <Box backgroundColor={'#413939'} w={item.percentageComplete} h={'4px'} borderRadius={50}></Box>

                    </Box>

                    <Flex justifyContent={'flex-end'}>
                    <Box mt={'10px'}>
                    <ButtonPrimary fontWeight={'300'}>Go</ButtonPrimary>
                    </Box>
                    </Flex>
                  </Flex>
                </Box>

              </Flex>
          ))}
          </Container>

        </HeadCard>
          </Box>
          <Box flex={0.5} padding={'10px'}>
            <Plg fontWeight={'700'}>Daily Tasks</Plg>
            <HeadCard title={'Master your skills'}
                // titleButton={'Start a new topic'}
                      pb={'30px'}
                      shadow
                      border
                      maxHeight={'70vh'} overflowY={'auto'}

            >

              <Center
                  justifyContent={'flex-start'} mt={10} pb={'20px'} px={'20px'}>

                <Wrap>
                  <WrapItem>
                    <Link style={{marginRight:'25px'}} to={'/learn/history/geo/eco/notes/123?home=1'}>
                      <Center bgColor={'#E4FAFF'} w={'170px'} h={'170px'} borderRadius={12} flexDirection={'column'} position={'relative'}>
                        <Image src={'/reading_book.png'} h={'80px'} w={'80px'}></Image>
                        {/*<Box w={'25px'} h={'25px'} bgColor={'red'} position={'absolute'} borderRadius={'50px'} top={-2} right={-2}></Box>*/}
                        <Psm pt={2} px={8} textAlign={'center'}>Personalised revision</Psm>

                      </Center>
                    </Link>
                  </WrapItem>
                  <WrapItem>
                    <Center  mr={'25px'} bgColor={'#C7E5FF'} w={'170px'} h={'170px'} borderRadius={12} flexDirection={'column'} position={'relative'} >
                      <Image src={'/daily_news.png'} h={'80px'} w={'80px'}></Image>
                      {/*<Box w={'25px'} h={'25px'} bgColor={'red'} position={'absolute'} borderRadius={'50px'} top={-2} right={-2}></Box>*/}

                      <Psm pt={2} px={8} textAlign={'center'}>Daily News</Psm>

                    </Center>
                  </WrapItem>
                  <WrapItem>
                    <Center  mr={'25px'} bgColor={'#E8EBFF'} w={'170px'} h={'170px'} borderRadius={12} flexDirection={'column'} position={'relative'}>
                      <Image src={'/daily_test.png'} h={'80px'} w={'80px'}></Image>
                      {/*<Box w={'25px'} h={'25px'} bgColor={'red'} position={'absolute'} borderRadius={'50px'} top={-2} right={-2}></Box>*/}

                      <Psm pt={2} px={8} textAlign={'center'}>Daily Test</Psm>

                    </Center>
                  </WrapItem>
                </Wrap>






              </Center>

            </HeadCard>
          </Box>
          <Box flex={0.5} padding={'10px'}>
            <Plg fontWeight={'700'}>Quick Access</Plg>
            <HeadCard title={'Notes'}
                      bgColor={'#FFF9D0'}
                // titleButton={'Start a new topic'}
                      pb={'30px'}
                      shadow
                      border
            >
              {/*<Flex justifyContent={'space-around'} alignItems={'center'} mt={'15px'}>*/}




              {/*</Flex>*/}

              {/*<Center mt={10} pb={5}>*/}
              {/*<ButtonPrimary link={'/learn'} fontWeight={'400'}>*/}
              {/*  <Image src={'/learning.png'} w={'20px'} mr={'10px'}/>*/}
              {/*  Start a new topic</ButtonPrimary>*/}
              {/*</Center>*/}

            </HeadCard>
          </Box>
        </Flex>

      </Container>
  );
}

export default Home;
