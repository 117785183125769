import axios from "axios";
import { useState } from "react";

const useGetMockTestSubs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorsub, setErrorSub] = useState({});

  const fetchMockTestSubs = async (ws_id, username) => {
    try {
      const res = await axios.get(
        `https://55aaqcxwk7.execute-api.ap-south-1.amazonaws.com/dev/library/get_mock_tests/${ws_id}/${username}`
      );
      return res?.data;
    } catch (error) {
      console.error("Error fetching response:", error);
      setErrorSub(error);
    }
  };

  return { fetchMockTestSubs, errorsub, isLoading, setIsLoading };
};

export default useGetMockTestSubs;
