import React from 'react';
import {Button, Flex, Heading, Text, Image, Center} from '@chakra-ui/react'
function ButtonCircularIcon(props) {
    return (
        <Button height={'60px'} bg='claydeck.white.primary' color="claydeck.text.primary" _hover={{bg:"claydeck.brand.hover",color:'white'}} borderRadius={"50px"} fontWeight={'400'} {...props} >
            <Center p={2}>
                <Image mt={4} mb={4} src={props.icon} height={'40px'} width={'40px'} mr={'10px'}></Image>
                {props.children}
            </Center>

        </Button>
    );
}

export default ButtonCircularIcon;
