import {
  Box,
  Image,
  Text,
  Card,
  Button,
  Grid,
  GridItem,
} from "@chakra-ui/react";

const RoomCard = ({ room }) => {
  return (
    <Box
      display={{ lg: "inline-block", md: "inline-block", base: "grid" }}
      minWidth={{ md: "220px", base: "150px" }}
      border="1px solid #ccc"
      borderRadius="md"
      shadow={"md"}
      boxShadow={"md"}
      m={{ lg: "2", md: "4", base: "2" }}
      backgroundColor={"white"}
      _hover={{
        transform: "scale(1.01)",
        transition: "transform 0.2s",
      }}
    >
      <Card
        flexShrink={"0"}
        direction={{ lg: "column", md: "column", base: "row" }}
        overflow="hidden"
        variant="outline"
        justifyContent={"space-between"}
        alignContent={"space-between"}
        minW={{ lg: "200px", md: "240px", base: "150px" }}
        height={{ base: "62px", md: "auto", lg: "auto" }}
      >
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(9, 1fr)"
          gap={"1"}
        >
          <GridItem colSpan={{ lg: "9", md: "9", base: "1" }} rowSpan={"1"}>
            <Image
              objectFit={{ lg: "cover", md: "cover", base: "cover" }}
              maxW={{ lg: "220px", md: "260px", base: "95px" }}
              src="https://img.freepik.com/free-photo/japandi-living-room-interior-design_53876-145502.jpg?w=1060&t=st=1693464420~exp=1693465020~hmac=74c0dc923b6585287a757e909680d4e5da3da0625bdaebde2de913f4741dce14"
              alt="Caffe Latte"
            />
          </GridItem>
          <GridItem colSpan={{ lg: "9", md: "9", base: "1" }} rowSpan={"1"}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={{ lg: "center", md: "center", base: "flex-start" }}
              p={"8px"}
            >
              <Text
                fontWeight={"500"}
                marginTop={"4px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                fontSize={{ lg: "16px", md: "16px", base: "12px" }}
                gap={"1"}
              >
                {room.title}
                {room.isOnline && (
                  <Box
                    rounded={"full"}
                    bg={"#0DDF61"}
                    width={"8px"}
                    height={"8px"}
                    border={"0.5px solid #ecfff4"}
                  />
                )}
              </Text>
              <Box as="div" display={"flex"} marginY={"4px"} gap={"1"}>
                <Image
                  src={
                    room.isOnline
                      ? "assets/icons/onlineParticipants.svg"
                      : "assets/icons/offlineParticipants.svg"
                  }
                />
                <Text
                  fontSize={{ lg: "12px", base: "10px" }}
                  fontWeight={"light"}
                  color={"#828282"}
                >
                  {room.total_member}
                </Text>
              </Box>
            </Box>
          </GridItem>
          <GridItem
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            colSpan={{ lg: "9", md: "9" }}
            rowSpan={"1"}
            colStart={9}
            colEnd={10}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              paddingBottom={"8px"}
              marginRight={{ base: "8px", md: 0, lg: 0 }}
            >
              <Button
                isDisabled={!room.isOnline}
                _disabled={{
                  color: "#21005D",
                  _hover: {
                    background: "white",
                  },
                  cursor: "not-allowed",
                }}
                size={"sm"}
                rounded={"md"}
                minWidth={{ lg: "160px", md: "160px", base: "75px" }}
                minHeight={"36px"}
                fontSize={{ lg: "14px", md: "14px", base: "12px" }}
                fontWeight={"light"}
                background={room.isOnline ? "#21005D" : "white"}
                border={"1px solid #21005D"}
                color={room.isOnline ? "white" : "#21005D"}
                marginBottom={{ lg: "8px", md: "8px", base: "0px" }}
                _hover={{
                  background: "#9531F3",
                }}
              >
                Join call
              </Button>
            </Box>
          </GridItem>
        </Grid>
      </Card>
    </Box>
  );
};

export default RoomCard;
