import {
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  STOP_LOADING_UI,
  LOGIN_LOADING_UI,
  STOP_LOGIN_LOADING_UI, START_SIGNUP_LOADING_UI, STOP_SIGNUP_LOADING_UI, FAILED_SIGNIN
} from '../types';

const initialState = {
  loading: false,
  loginLoading: false,
  signupLoading:false,
  errors: null,
  failedSignin:null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
      };
    case LOADING_UI:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false,
      };
    case LOGIN_LOADING_UI:
      return {
        ...state,
        loginLoading: true,
      };
    case STOP_LOGIN_LOADING_UI:
      return {
        ...state,
        loginLoading: false,
      };
    case FAILED_SIGNIN:
      return {
        ...state,
        failedSignin: new Date().toISOString(),
      };
    case START_SIGNUP_LOADING_UI:
      return {
        ...state,
        signupLoading: true,
      };
    case STOP_SIGNUP_LOADING_UI:
      return {
        ...state,
        signupLoading: false,
      };
    default:
      return state;
  }
}
