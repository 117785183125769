import React, { useEffect, useState } from "react";
import { NEW_API_URL } from "../../../../redux/types";
import axios from "axios";
import {
  Box,
  Text,
  Flex,
  Heading,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import COLORS from "../../../../Components/colors";
import { useLocation } from "react-router-dom";
import H1 from "../../../../Components/Typography/Heading/H1";
import H2 from "../../../../Components/Typography/Heading/H2";
import Psm from "../../../../Components/Typography/Text/Psm";
import { Link } from 'react-router-dom';

export default function MustReads() {
  const location = useLocation();
  const item = location.state.item;

  const [data, setData] = useState([]);
  const [state, setState] = useState(0);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(`${NEW_API_URL}/${item.api_path}`);
      setData(response.data.must_reads);
    } catch (error) {
      console.error(error);
      alert("Couldn't load data");
    }
  };

  if (state === 0) {
    return (
      <Box w={"100%"} background={"claydeck.white.primary"}>
        <H1 margin={4} marginTop={8} marginBottom={3}>
          Must Reads
        </H1>
        <Flex flexWrap="wrap" gap={4} p={4}>
          {data.map((item) => (
            <Box
              key={item.name}
              onClick={() => {
                setSelectedData(item);
                setState(1);
              }}
              backgroundColor={COLORS.lightPink}
              borderRadius={4}
              paddingY={3}
              paddingX={4}
              cursor={"pointer"}
            >
              <H2 color={COLORS.darkPurple} marginBottom={"0"}>
                {item.name}
              </H2>
            </Box>
          ))}
        </Flex>
      </Box>
    );
  }
  return (
    <Box w={"100%"} background={"claydeck.white.primary"} p={6}>
      <Flex gap={4} alignItems="center" marginTop={2} marginBottom={6}>
        <AiOutlineArrowLeft
          className="cursor"
          onClick={() => {
            setState(0);
            setSelectedData({});
          }}
        />
        <H1 marginBottom={"0px"}>Must Reads - {selectedData.name}</H1>
      </Flex>
      <Flex gap={4} flexWrap="wrap">
        {selectedData?.items?.map((item) => (
          <Box
            key={item.name}
            bgColor={COLORS.lightPink}
            borderRadius={4}
            paddingY={3}
            paddingX={4}
          >
            <Link to={item.url}>
              <Flex>
                <Image src={item.book_image} alt={item.book_name} />
                <Psm
                  fontWeight={"500"}
                  marginBottom={"0px"}
                  color={COLORS.darkPurple}
                >
                  {item.name}
                </Psm>
              </Flex>
            </Link>
          </Box>
        ))}
      </Flex>
    </Box>
  );
}
