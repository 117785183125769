import {
  Avatar,
  Text,
  Flex,
  AvatarBadge,
  Image,
  Grid,
  GridItem,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";

const ParticipantMembers = ({ data }) => {
  const [dataState, setDataState] = useState(data);
  const [openModalId, setOpenModalId] = useState(null);

  const handleModalClick = (id) => {
    if (openModalId === id) {
      setOpenModalId(null);
    } else {
      setOpenModalId(id);
    }
  };

  const handleBlock = (id) => {
    const updatedData = dataState.map((item) => {
      if (item.id === id) {
        item.isBlocked = !item.isBlocked;
      }
      return item;
    });

    setDataState(updatedData);
  };

  return (
    <Box
      maxHeight={{ lg: "520px", base: "400px" }}
      overflowY={"auto"}
      overflowX={"hidden"}
      sx={{
        "::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          borderRadius: "5px",
          backgroundColor: "white",
        },
        "::-webkit-scrollbar": {
          width: "7px",
          backgroundColor: "white",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: "5px",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: "rgba(103, 80, 164, 0.8)",
        },
      }}
    >
      {dataState.map((item) => (
        <Flex
          key={item.id}
          flexDirection={"row"}
          m={"5px"}
          alignItems={"center"}
          justifyContent={"center"}
          w={"full"}
          mb={"6px"}
        >
          <Grid templateColumns={"repeat(12, 1fr)"}>
            <GridItem colSpan={"1"}>
              <Avatar w={"30px"} h={"30px"}>
                {item.isOnline && (
                  <AvatarBadge boxSize="0.6em" bg="green.500" />
                )}
              </Avatar>
            </GridItem>
            <GridItem colStart={"2"} colEnd="10" colSpan={"2"}>
              <Text ml={"6px"} fontSize={"13px"} lineHeight={"140%"}>
                {item.name}
              </Text>
            </GridItem>
            <GridItem
              colStart={"12"}
              colEnd={"14"}
              colSpan={"2"}
              position={"relative"}
            >
              <Image
                onClick={() => handleModalClick(item.id)}
                marginRight={"100px"}
                cursor={"pointer"}
                justifySelf={"end"}
                w={"18px"}
                h={"18px"}
                src="assets/icons/3dots.svg"
              />
              {openModalId === item.id && (
                <Box
                  zIndex={5}
                  backgroundColor={"white"}
                  position={"absolute"}
                  left={{ base: "-55px", lg: "-58px" }}
                  width={"80px"}
                  p={"1"}
                  px={"5px"}
                  border={"1px solid black"}
                  rounded={"md"}
                >
                  <Box
                    onClick={() => handleBlock(item.id)}
                    cursor={"pointer"}
                    display={"flex"}
                    justifyContent={"start"}
                    alignItems={"center"}
                    gap={"1"}
                  >
                    <Image src="assets/icons/block.svg" />
                    <Text
                      fontSize={"12px"}
                      fontWeight={"600"}
                      lineHeight={"140%"}
                      color={item.isBlocked ? "red" : "black"}
                    >
                      {item.isBlocked ? "Unblock" : "Block"}
                    </Text>
                  </Box>
                </Box>
              )}
            </GridItem>
          </Grid>
        </Flex>
      ))}
    </Box>
  );
};

export default ParticipantMembers;
