import React, {useEffect, useMemo, useRef, useState} from "react";
import { Box, Container, Flex, Spinner } from "@chakra-ui/react";
import FeedItem from "./FeedItem";
import H1 from "../../Components/Typography/Heading/H1";
import H2 from "../../Components/Typography/Heading/H2";
import TrendingFeedItem from "./TrendingFeedItem";

import { getTodaysHeadline } from "../../utils/Request";

import "./FeedItem.css";
import COLORS from "../../Components/colors";
import {useSelector} from "react-redux";

function useOnScreen(ref) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
  ), [ref])


  useEffect(() => {
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return isIntersecting
}

export default function Feed() {
  const [todaysHeadline, setTodaysHeadline] = useState([]);
  const [loading, setLoading] = useState(false);
  const username = useSelector((state) => state.user.username);

  const bottomRef = useRef();

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (bottomRef.current && isInViewport(bottomRef.current)) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Initial check
    handleScroll();

    // Listen to scroll events
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  // const workspaceId = useSelector(
  //   (state) => state.user.selectedWorkspace.workspace_id
  // );
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const [showTo, setTo] = useState(10);

  let workspaceId = '';
  if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')) {
    workspaceId = selectedWorkspace.workspace_id
  }
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      let res = await getTodaysHeadline(workspaceId,username);
      console.log(res.data,'this is res aaa')
      setTodaysHeadline(res.data);
    } catch (error) {
      console.log("get feed error:", error);
    } finally {
      setLoading(false);
    }
  };

  const [isVisible,setIsVisible] = useState(false)

  // const reachedBottom = useOnScreen(bottomRef);
  // console.log(reachedBottom)
  useEffect(() => {
    if(isVisible){
      console.log("BOTTOM IS VISIBLE  NOW!")
      setTo(showTo+10)
    }
  }, [isVisible]);

  // console.log('visibl',reachedBottom)

  return (
    <Flex
      width={"100%"}
      backgroundColor={"claydeck.white.primary"}
      direction={{ base: "column", "2xl": "row" }}
      justify="space-between"
    >
      <Container>
        <H1 color={COLORS.black} fontSize={20} marginTop={10}>
          Today's Headlines
        </H1>
        {loading ? (
          <Spinner color="claydeck.brand.secondary" />
        ) : (
            <div style={{backgroundColor:'red',minHeight:'min-content'}}>
              {
              todaysHeadline.map((item,index) => {

                if(index<showTo)
                return <FeedItem key={index} todaysHeadlineItem={item}/>
                else return <></>
              })
              }
              <div style={{marginBottom:'20px'}} ref={bottomRef}/>
            </div>
        )}
      </Container>
      <Box
        className="shadow-1"
        marginTop={50}
        borderTopRightRadius={10}
        borderTopLeftRadius={10}
        padding={5}
      >
        <Box
          backgroundColor={COLORS.darkPurple}
          height={20}
          marginTop={-5}
          marginLeft={-5}
          marginRight={-5}
          borderTopLeftRadius={10}
        />
        <H2
          fontSize={20}
          color={COLORS.purple}
          fontWeight="bold"
          margin="24px 0px"
        >
          Trending Feed
        </H2>
        {[1, 2, 3].map(() => (
          <TrendingFeedItem />
        ))}
      </Box>
    </Flex>
  );
}
