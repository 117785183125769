import EventCard from "./eventCard";
import React, { useState, useEffect } from "react";
import { Box, Collapse, Text, useMediaQuery, Flex } from "@chakra-ui/react";
import EventListSkeleton from "./skeleton-components/eventListSkeleton";

const EventsList = ({ data }) => {
  const [isSmallorMediumDevice] = useMediaQuery("(max-width: 1024px)");
  const [displayCount, setDisplayCount] = useState(2);
  const [showAll, setShowAll] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSkeleton(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!isSmallorMediumDevice) {
      setDisplayCount(data.length);
    } else {
      setDisplayCount(2);
    }
  }, [isSmallorMediumDevice, data.length]);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      {showSkeleton ? (
        <EventListSkeleton />
      ) : (
        <Box>
          <Flex flexWrap="wrap" flexDir={"column"}>
            {data.slice(0, displayCount).map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
            {isSmallorMediumDevice && (
              <Collapse in={showAll} animateOpacity>
                <Flex flexWrap="wrap" flexDir={"column"}>
                  {data.slice(2).map((event) => (
                    <EventCard key={event.id} event={event} />
                  ))}
                </Flex>
              </Collapse>
            )}
            {isSmallorMediumDevice && data.length > 2 && (
              <Box
                as="div"
                onClick={handleShowMore}
                display={"flex"}
                width={"full"}
                justifyContent={"center"}
                alignItems={"center"}
                cursor={"pointer"}
              >
                <Text fontSize={"14px"} color={"#828282"}>
                  {showAll ? "Show Less" : "Show More"}
                </Text>
                {isSmallorMediumDevice && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M11.3136 11.3137V13.3078L19.212 13.3148L10.6065 21.9203L12.0207 23.3345L20.6262 14.7291L20.6333 22.6274H22.6273V11.3137H11.3136Z"
                      fill="#828282"
                    />
                  </svg>
                )}
              </Box>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default EventsList;
