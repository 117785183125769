import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag, Text, useDisclosure,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight, AiOutlineSearch} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import {Link, useNavigate, useParams} from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import {ArrowBackIcon} from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import NotesCard from "../../Components/NotesCard";
import {topic_details} from "../../data/new_generated/topic_details";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
const Readings = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {topic,subject,area} = useParams();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  let workspaceId = '';
  if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
    workspaceId = selectedWorkspace.workspace_id
  }
  console.log('workspace_ID',workspaceId,selectedWorkspace,user)

  const [readings,setReadings] = useState();

  const notesModalStates = useDisclosure()


  const [topicDetail,setTopicDetail] = useState({})


  const get_map_data = async (username,workspace) => {
    const r = await axios.get(NEW_API_URL+'lessonplan/get_topic_detail/'+username+'/'+workspace+'/')
    setTopicDetail(r.data.topic_detail)
    setReadings(r.data.topic_detail.reading_list)
    return r.data
  }


  useEffect(() => {
    get_map_data(username,topic)


  }, []);


  return (
      <>
      <Container  justifyContent={'center'} alignItems={'center'} w={'95%'} maxW='container.xl' >
        <Flex flexDirection={'column'} alignItems={'space-between'} justifyContent={'space-between'} minH={'95vh'}>
        <Box>
        <Flex mt={'10vh'} alignItems={'center'}>
          <ArrowBackIcon cursor={'pointer'} onClick={()=>{navigate(-1)}} marginLeft={'-65px'} marginRight={'20px'} boxSize={'45px'}/>

          <Box>
        <P2xl mb={0} ml={0} mr={0}  >
          <strong>Readings</strong>

        </P2xl>
        <Pmd color={'#817D7D'} style={{margin:0}}><strong>{topicDetail['topic_name']}</strong></Pmd>
          </Box>
        </Flex>
        <hr/>
        <Container minW={'container.lg'}>
          <Flex flexWrap={'wrap'} w={'100%'} justifyContent={'center'} spacing={'10px'}>
          {
              readings && readings.map((item,idx)=>(


<>
                    {

                              <HeadCard shadow style={{position:'relative'}}
                      margin={'20px'} key={idx} title={item.title} minW={'400px'}>
                          <Box mt={'10px'}>

                            {item.read_status === 'Read' && <Image style={{position:'absolute',right:'20px',top:'20px'}} src={'/read_tick.png'} height={'20px'} width={'20px'}/>}
                            {item.read_status !== 'Read' && <Image style={{position:'absolute',right:'20px',top:'20px'}} src={'/read_circle.png'} height={'20px'} width={'20px'}/>}


                            <Psm><strong style={{marginLeft:'15px'}}>{item.subtitle}</strong></Psm>
                            <Container maxW={'90%'}>
                            <Flex cursor={item.pdf_link?'pointer':'auto'} onClick={()=>{
                              if(item.pdf_link)
                              axios.post(NEW_API_URL+"lessonplan/update_activity/",{
                                "user_id":username,
                                "topic_id":topic,
                                "activity_type":"reading_list",
                                "activity_id":item.id,
                                "read_status":"Read"
                              }).then(r=>{})


                            }}  spacing={'20px'} mt={4} alignItems={'center'}>

                              <Image src={'/notetime.png'} h={'24px'} w={'20px'}></Image>
                              <Pmd color={item.pdf_link?'black':'gray'} margin={0} flex={1} marginLeft={'20px'}>
                              <strong>  Read Now</strong>
                              </Pmd>
                              {item.pdf_link && <Image src={'/bluearrow.png'} height={'15px'}></Image>}


                            </Flex>
                            <hr/>

                              <Flex cursor={item.pdf_link?'pointer':'auto'} onClick={()=>{


                              }}  spacing={'20px'} mt={4} alignItems={'center'}>

                                <Image src={'/notetime.png'} h={'24px'} w={'20px'}></Image>
                                <Pmd color={item.pdf_link?'black':'gray'} margin={0} flex={1} marginLeft={'20px'}>
                                  <strong>  Share Link</strong>
                                </Pmd>
                                {item.pdf_link && <Image src={'/bluearrow.png'} height={'15px'}></Image>}


                              </Flex>
                              <hr/>

                              <Flex spacing={'20px'} mt={4} alignItems={'center'}>

                                <Image src={'/notetime.png'} h={'24px'} w={'20px'}></Image>
                                <Pmd  margin={0} flex={1} marginLeft={'20px'}>
                                  <strong>  Add/ View Notes</strong>
                                </Pmd>
                                <Link to={'/learn/'+subject+'/'+area+'/'+topic+'/readings/'+item.id}>
                                <Image src={'/bluearrow.png'} height={'15px'}></Image>
                                </Link>

                              </Flex>
                            </Container>
                            {/*<Flex spacing={'20px'} justifyContent={'space-around'} alignItems={'center'} mt={4}>*/}



                            {/*  {*/}

                            {/*        <Box>*/}
                            {/*          <NotesCard shadow w={'180px'} title={item2.title} icon={'/notes_preview.png'}/>*/}
                            {/*        </Box>*/}

                            {/*  }*/}


                            {/*</Flex>*/}
                            <Center>
                            <ButtonPrimary onClick={()=>{
                              axios.post(NEW_API_URL+"lessonplan/update_activity/",{
                                "user_id":username,
                                "topic_id":topic,
                                "activity_type":"reading_list",
                                "activity_id":item.id,
                                "read_status":"Read"
                              }).then(r=>{
                                get_map_data(username,topic)

                              })

                            }} bg={'transparent'} isDisabled={item.read_status === 'Read'} color={'#0962A2'} border={'1px solid #0962A2'} mt={'22px'} mb={'12px'} minW={'60%'}>Mark as Read</ButtonPrimary>
                            </Center>
                          </Box>
                              </HeadCard>

                    }
</>





              ))
          }

          </Flex>
        </Container>

        </Box>
        </Flex>
      </Container>


        {/*<CustomStyledModal*/}
        {/*    add_type_topic={true}*/}
        {/*    area={''} subject={''} topic_name={topicDetail.topic_name} topic_id={topic}*/}
        {/*    toast={'Note successfully created!'} topicDetail={topicDetail} add_note isOpen={notesModalStates.isOpen} onOpen={notesModalStates.onOpen} onClose={notesModalStates.onClose} title={'Notes'} selectedText={''}/>*/}

        {/*<Flex onClick={()=>{notesModalStates.onOpen()}} cursor={'pointer'} w={'80px'} h={'80px'} alignItems={'center'} justifyContent={'center'} bg={"claydeck.brand.primary"} border={'2px solid white'} boxShadow={'0px 4px 4px 0px #00000040'} position={'fixed'} bottom={'80px'} right={'20px'} borderRadius={'50px'}>*/}
        {/*  <Text color={'white'} margin={0} fontSize={'32px'}>+</Text>*/}
        {/*</Flex>*/}
      </>

  );
}

export default Readings;
