import React, { useState } from "react";
import { datadogLogs } from '@datadog/browser-logs';

import {
  Box,
  Text,
  Button,
  Textarea,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import useGetSubFeedback from "../../library/ExamPracticeMAterialComps/utils/getSubjectiveFeedback";
import { useRef } from "react";

let t1 =  Math.floor(Date.now());

const SubQuizBox = ({ questions, isQuizCompleted, setQuizCompleted }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState([]);

  const { fetchSubFeedback, isLoading, setIsLoading } = useGetSubFeedback();

  const [userAnswers, setUserAnswers] = useState(
    questions.map((question) => ({ question: question.question, answer: "" }))
  );

  function countWords(s){
    s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
    s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
    return s.split(' ').filter(function(str){return str!="";}).length;
    //return s.split(' ').filter(String).length; - this can also be used
  }

  function wordCountNeeded(s){
    var word_count = s.match(/\d+ words/)[0];
    var matches = Number(word_count.match(/\d+/)[0]);
    
    if (matches == null){
     matches = 250;
    }
    return matches 
  }

  const toast = useToast();

  const handleclickFeedback = async () => {
    let res = {
      Feedback : ""
    }
    setShowFeedback(true);
    setIsLoading(true);
    let word_count_needed = wordCountNeeded(questions[currentQuestionIndex].question)
    let word_count = countWords(userAnswers[currentQuestionIndex].answer)
    if (word_count >= word_count_needed){
    res = await fetchSubFeedback("rahulk30s@gmail.com", 2, {
        question: questions[currentQuestionIndex].question,
        answer: userAnswers[currentQuestionIndex].answer,
      });
    } else {
      res = {
        Feedback : "Please write upto minimum word count. Current word count " + String(word_count)
      }
    }
    

    console.log("--->", res);
    setFeedback(res);
    setIsLoading(false);
  };

  const boxRef = useRef(null);
  const handleCopyToClipboard = () => {
    if (boxRef.current) {
      const textToCopy = feedback.Feedback;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast({
            title: "Feedback coppied",
            description: "Learn from this feedback and score more",
            position: "top-right",
            variant: "left-accent",
            status: "success",
            duration: 4500,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error("Error copying to clipboard: ", error);
        });
    }
  };

  const handleAnswerChange = (e) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestionIndex].answer = e.target.value;
    setUserAnswers(updatedAnswers);
    datadogLogs.logger.info('Subjective Quiz', {name: 'Subjective Quiz answers submitted', id: 123, responseTime: t1});
  };

  const handleNextQuestion = () => {
    setShowFeedback(false);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    setShowFeedback(false);

    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmitQuiz = () => {
    setQuizCompleted(true);
    console.log(userAnswers);
  };

  return (
    <Box maxW={"70%"}>
      {isQuizCompleted ? (
        <Text fontSize="lg">
          Thank you for completing the quiz! We will send you an email with your
          performance.
        </Text>
      ) : (
        <>
          <Text fontSize={"xl"}>
            {" "}
            {questions[currentQuestionIndex].question}{" "}
          </Text>

          <Box
            onClick={handleclickFeedback}
            display={"inline-block"}
            transition={"ease-in-out"}
            transitionDuration={"200ms"}
            background={"purple.200"}
            paddingX={3}
            paddingY={2}
            rounded={"lg"}
            mb={1}
            _hover={{
              background: "purple.300",
              cursor: "pointer",
              color: "purple.600",
            }}
            ml={"805px"}
          >
            Feedback Preview
            <Text fontSize={"10px"} m={0} color={"black"} ml={"65px"}>
              Powered by AI
            </Text>
          </Box>

          <Textarea
            backgroundColor={"purple.50"}
            resize={"vertical"}
            value={userAnswers[currentQuestionIndex].answer}
            onChange={handleAnswerChange}
            mt={2}
            placeholder="Type your answer here"
          />
          <Box
            display={"flex"}
            flexDirection={"column"}
            mt={2}
            alignItems={"end"}
            className="b-1"
          >
            <Box
              width={"full"}
              className="b-2"
              display={"flex"}
              justifyContent={"space-between"}
              mt={"6px"}
            >
              <Box>
                <Button
                  onClick={handlePreviousQuestion}
                  size="sm"
                  backgroundColor={"green.100"}
                  borderWidth={"2px"}
                  borderColor={"green.200"}
                  fontWeight={"bold"}
                  letterSpacing={"1px"}
                  _hover={{
                    backgroundColor: "green.50",
                  }}
                  _active={{
                    transform: "translateY(4px)",
                  }}
                  disabled={currentQuestionIndex === 0}
                  alignSelf={"flex-start"}
                  mr={2}
                  display={currentQuestionIndex > 0 ? "" : "none"}
                >
                  Previous
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={handleNextQuestion}
                  size="sm"
                  backgroundColor={"green.100"}
                  borderWidth={"2px"}
                  borderColor={"green.200"}
                  fontWeight={"bold"}
                  letterSpacing={"1px"}
                  _hover={{
                    backgroundColor: "green.50",
                  }}
                  _active={{
                    transform: "translateY(4px)",
                  }}
                  disabled={currentQuestionIndex === questions.length - 1}
                  display={
                    currentQuestionIndex !== questions.length - 1 ? "" : "none"
                  }
                >
                  Next
                </Button>
              </Box>
            </Box>
            <Box alignSelf={"center"} mt={2} className="b-3">
              {currentQuestionIndex === questions.length - 1 && (
                <Button
                  onClick={handleSubmitQuiz}
                  size="sm"
                  backgroundColor={"purple.100"}
                  borderWidth={"2px"}
                  borderColor={"purple.200"}
                  fontWeight={"bold"}
                  letterSpacing={"1px"}
                  _hover={{
                    backgroundColor: "purple.50",
                  }}
                  _active={{
                    transform: "translateY(4px)",
                  }}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        </>
      )}
      {isLoading === true
        ? "Loading ..."
        : showFeedback && (
            <Tooltip
              marginTop={1}
              label="Click to coppy"
              hasArrow
              bg={"purple.100"}
              color={"black"}
            >
              <Box
                marginY={4}
                ref={boxRef}
                onClick={handleCopyToClipboard}
                transition={"ease-in-out"}
                transitionDuration={"200ms"}
                background={"purple.50"}
                padding={2}
                cursor={"pointer"}
                _hover={{
                  padding: "10px",
                  backgroundColor: "purple.100",
                  rounded: "lg",
                }}
              >
                <Text fontSize={"xl"} fontWeight={"bold"}>
                  Feedback generated by AI :
                </Text>
                {feedback.Feedback}
              </Box>
            </Tooltip>
          )}
    </Box>
  );
};

export default SubQuizBox;
