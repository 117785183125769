import React from 'react';
import {Heading, Text} from '@chakra-ui/react'
function Psm(props) {
    return (
      <Text
        color={"claydeck.text.primary"}
        fontWeight={400}
        fontSize="sm"
        {...props}
      >
        {props.children}
      </Text>
    );
}

export default Psm;
