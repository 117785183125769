import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

import {Container, Box, Flex, Icon} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";

import { GoCheckCircleFill } from "react-icons/go";
import {MdDelete, MdEdit} from "react-icons/md";
import {RiCheckboxBlankCircleLine} from "react-icons/ri";
import {AiOutlineClockCircle} from "react-icons/ai";
import Modal from "../../Components/Modal";

import axios from "axios";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import InputOutline from "../../Components/Input/InputOutline";
import {PlusSquareIcon} from "@chakra-ui/icons";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

const colors = ["#B79BFF", "#A6F7E2", "#FFE5A5", "#C7FFA5"];

const LessonPlan = () => {
  
  const navigate = useNavigate();
  const username = useSelector(state => state.user.username);
  // const workspaceId = useSelector(state => state.user.selectedWorkspace.workspace_id);
  const [showAdd, setShowAdd] = useState(false);
  const [subtopicURL, setsubtopicURL] = useState('');
  const [timeRequired, setTimeRequired] = useState('');
  const [subtopic_id, setSubtopic_id] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  let workspaceId = '';
  if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
    workspaceId = selectedWorkspace.workspace_id
  }

  const [fetchLoading, setFetchLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [lessonPlanList, setLessonPlanList] = useState([]);
  const [topicName, setTopicName] = useState('');
  console.log(selectedWorkspace )
  useEffect(() => {
    getLessonPlan();
  }, []);


  const addTopic = async () => {
    try {
      if (topicName.trim() !== "") {
        // setLoading(true)
        const requestBody = {
          name: topicName.trim(),
          time_estimate: 0,
          "url":"NA","type":"reading/test", "topic_question_attempted" : 0, "topic_score": 0
        }
        await axios.post(`${NEW_API_URL}/lessonplan/post_topic/${username}/${workspaceId}/`, requestBody)
            // .then(() => navigation.goBack())
            .catch((e) => {
              console.error(e)
              // Toast.show({
              //   type: 'error',
              //   text1: 'Error',
              //   text2: "Couldn't create topic"
              // })
            })
      } else {
        // Toast.show({
        //   type: 'error',
        //   text1: 'Error',
        //   text2: "Please enter valid details"
        // })
      }
    } catch (e) {
      console.error(e)
      // Toast.show({
      //   type: 'error',
      //   text1: 'Error',
      //   text2: "Something unexpected happened"
      // })
    } finally {
      // setLoading(false)
    }
  }


  const getLessonPlan = async () => {
    setFetchLoading(true);
    try {
      await axios
        .get(
          `${NEW_API_URL}lessonplan/get_lessonplan/${username}/${workspaceId}/`
        )
        .then((response) => {
          // console.log("lesson plan res --->", response.data.lesson_plan);
          if (response.status === 200) {
            setLessonPlanList(response.data.lesson_plan.topics);
          }
          // setTaskList(response?.data?.metrics?.todays_tasks?.tasks);
        })
        .catch((error) => {
          console.error("lesson plan error --->", error);
          // Toast.show({
          //   type: "error",
          //   text1: "Error",
          //   text2: "Couldn't load data",
          // });
        });
    } catch (e) {
      console.error(e);
      // Toast.show({
      //   type: "error",
      //   text1: "Error",
      //   text2: "Something unexpected happened",
      // });
    } finally {
      setFetchLoading(false);
    }
  };

    const styles = {
      task: {
        width: "100%",
        bgColor: "claydeck.white.primary",
        borderRadius: "5px",
        padding: { base: "12px 8px", md: "14px 10px", xl: "16px 12px" },
        cursor: "pointer",
        _hover: {
          bg: "claydeck.brand.hover",
          boxShadow: " 0px 10px 20px -5px rgba(0, 0, 0, 0.05)",
        },
      },
      taskTitle: {
        color: "#1C1B1F",
        fontSize: { base: "14px", lg: "16px" },
        fontWeight: 500,
        lineHeight: "120%",
        letterSpacing: "0.15px",
        marginBottom: { base: "3px", lg: "4px" },
      },
      taskDetail: {
        margin:0,
        color: "claydeck.text.tertiary",
        fontSize: { base: "10px", lg: "12px" },
        fontWeight: 500,
      },
    };

  const deleteTopic = async (topicId) => {
    try {
      await axios.delete(`${NEW_API_URL}/lessonplan/delete_topic/${username}/${workspaceId}/topic/${topicId}/`)
          .then(() => getLessonPlan())
          .catch((e) => console.error(e))
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Container
      minHeight={"100vh"}
      minWidth={{
        base: "max-content",
        md: "350px",
        lg: "500px",
        xl: "650px",
      }}
    >
      <Flex
        width={"100%"}
        gap={{ base: "16px", lg: "24px" }}
        direction={"column"}
        margin={{ base: "36px 0px", lg: "72px 0px" }}
      >
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <H2 fontSize="18px">Lesson Plan</H2>
          <Flex
            as={"button"}
            alignItems={"center"}
            padding="3px"
          >
            <Icon
                onClick={()=>{setShowAdd(true)}}
                as={PlusSquareIcon}
                boxSize="24px"
                color="claydeck.text.tertiary"
            />
            <Icon
                as={MdEdit}
                style={{marginLeft:'10px'}}
                boxSize="24px"
                onClick={()=>{
                  setIsEditing(!isEditing);
                }}
                color="claydeck.text.tertiary"
            />
          </Flex>
        </Flex>
        {lessonPlanList.map((plan, index) => {
          // const expired = moment(plan.dueDate).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY");
          const expired = plan.dueDate === moment().format("DD/MM/YYYY");

          return (
            <Box
              key={index}
              onClick={() => {
                if(!isEditing)
                navigate(`./${plan.topic_id}`, {
                  state: {
                    topics: plan.subtopics
                  }
                })
              }}
              sx={styles.task}
              borderLeft={`9px solid ${colors[index % 4]} !important`}
            >
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Box>
                  <Pmd sx={styles.taskTitle}>{plan.name}</Pmd>
                  <Flex gap={"3px"} align={"center"}>
                    <Icon
                      as={AiOutlineClockCircle}
                      color={"claydeck.text.tertiary"}
                    />
                    <Psm sx={styles.taskDetail}>
                      {plan.time_estimate} hours
                    </Psm>
                  </Flex>
                </Box>
                {/*<p>{plan.time_estimate} Hrs</p>*/}

                <Flex
                  as={"button"}
                  height={"20px"}
                  width="20px"
                  alignItems={"center"}
                  border={plan.completed ? "0px" : "0.5px solid #828282"}
                  borderRadius={"15px"}
                >
                  <Icon
                    as={GoCheckCircleFill}
                    boxSize="22px"
                    color={"#28ad39"}
                    display={plan.completed ? "initial" : "none"}
                    borderRadius="15px"
                  />
                </Flex>

                {
                    isEditing && <div>
                      <ButtonSecondary style={{marginRight:'10px'}} onClick={()=>{
                        // deleteSubtopic(id,plan.subtopic_id);
                        deleteTopic(plan.topic_id)
                      }}><MdDelete/></ButtonSecondary>
                      {/*<ButtonSecondary onClick={()=>{*/}
                      {/*  setShowEdit(true);*/}
                      {/*  setTopicName(plan.name);*/}
                      {/*  setTimeRequired(plan.time_estimate+"");*/}
                      {/*  setsubtopicURL(plan.url);*/}
                      {/*  // setSubtopic_id(plan.subtopic_id)*/}
                      {/*}}><MdEdit/></ButtonSecondary>*/}
                    </div>
                }
              </Flex>
            </Box>
          );
        })}
      </Flex>

      <Modal show={showAdd} handleClose={()=>{setShowAdd(false)}}>
        <H2>Add Lesson Plan</H2>
        <InputOutline value={topicName} onChange={(e)=>{setTopicName(e.target.value)}} style={{marginTop:10,marginBottom:10}} placeholder={'Enter topic name'} />
        <ButtonPrimary onClick={()=>{addTopic()}} style={{width:'100%'}}>Create Plan</ButtonPrimary>


      </Modal>

    </Container>
  );
};

export default LessonPlan;
