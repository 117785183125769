import React from 'react';
import {Box, Flex, Image, LinkBox} from "@chakra-ui/react";
import Pmd from "./Typography/Text/Pmd";
import Pxl from "./Typography/Text/Pxl";
import { Link } from 'react-router-dom';

function HeadCard(props) {
    return (
        <Link to={props.to || '#'}>
        <Box  w={props.w || '280px'} borderRadius={'12px'} backgroundColor={'claydeck.white.primary'} {...props} boxShadow={props.shadow?'0px 4px 10px 0px #0000001A':'none'} border={'1px solid #B6B6B6'}>
            <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'} pt={7} pb={7}>

                <Image src={props.icon} h={'100px'}></Image>
                <Pxl fontWeight={'400'}>{props.title}</Pxl>
            </Flex>
        </Box>
        </Link>
    );
}

export default HeadCard;
