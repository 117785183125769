import React from 'react';
import {Heading, Text} from '@chakra-ui/react'

function H2(props) {
    return (
      <Heading fontWeight={600} fontSize="sm" fontFamily={"Roboto"} {...props}>
        {props.children}
      </Heading>
    );
}

export default H2;
