export const Newsdata = [
  {
    answer: "elected by the Provincial Legislative Assemblies",
    optiona: "directly elected by the people of those Provinces",
    optionb: "nominated by the Indian National Congress and the\nMuslim League",
    optionc: "elected by the Provincial Legislative Assemblies",
    optiond:
      "selected by the Government for their expertise in\nconstitutional matters",
    question:
      "- With reference to Indian History, the Members of the\nConstituent Assembly from the Provinces were",
  },
  {
    answer: "Directive Principles of State Policy",
    optiona: "Fundamental Rights\n",
    optionb: "Fundamental Duties",
    optionc: "Directive Principles of State Policy",
    optiond: "Fundamental Rights and Fundamental Duties",
    question:
      "- According to the Constitution of India, which of the\nfollowing are fundamental for the governance of the\ncountry?",
  },
  {
    answer: "Neither 1 nor 2",
    optiona: "1 only",
    optionb: "2 only",
    optionc: "Both 1 and 2",
    optiond: "Neither 1 nor 2",
    question:
      "- Consider the following statements:\n1. An amendment to the Constitution of India can be\ninitiated by an introduction of a bill in the Lok Sabha only.\n2. If such an amendment seeks to make changes in the\nfederal character of the Constitution, the amendment\nalso requires to be ratified by the legislature of all the\nStates of India.\nWhich of the statements given above is/are correct?",
  },
];

export const SubjectiveData = [
  {
    question:
      "1. Buddhism originated as an alternative tradition to the excessive importance given to rituals and sacrifices in Vedic tradition. Examine. (250 words)",
  },
  {
    question:
      "2. Discuss the impact of climate change on global food security and suggest measures to mitigate its effects. (300 words)",
  },
  {
    question:
      "3. Analyze the role of women in the socio-economic development of rural areas in India. (200 words)",
  },
];
