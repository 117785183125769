import { dataObj } from "../data/data";
import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Radio,
  RadioGroup,
  Stack,
  useToast,
  Button,
  Badge,
  Checkbox,
  Spinner,
} from "@chakra-ui/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BiSolidBookmarkPlus } from "react-icons/bi";
import { MdBookmarkAdded } from "react-icons/md";
import axios from "axios";
import { NEW_API_URL } from "../../../redux/types";

const InteractiveElements = ({ chat }) => {
  const toast = useToast();

  const handleQuizSubmit = (userResponse) => {
    console.log("User's Quiz Response:", userResponse);
  };

  const [oldTasks, setOldTasks] = useState([]);

  useEffect(() => {
    const fetchUserStats = async (username, workspaceId) => {
      try {
        await axios
          .get(
            `${NEW_API_URL}gamification/get_metrics/${username}/${workspaceId}`
          )
          .then((response) => {
            setOldTasks(response.data.metrics.todays_tasks.tasks);
          })
          .catch((error) => {
            console.error("todays task error", error);
          });
      } catch (e) {
        console.error(e);
      }
    };

    fetchUserStats("patelhetvarasada@gmail.com", 2);
  }, []);

  console.log("oldTasks", oldTasks);

  const TableComponent = ({ table }) => {
    const [tableState, setTableState] = useState(dataObj[0]?.table);
    console.log("tableState", tableState);
    function myFunction(table_input) {
      let objects_list = Object.keys(table_input);
      let data_array = [];
      let header_array = [];
      let i = 0;

      while (i < objects_list.length) {
        header_array.push("-");
        data_array.push([table_input[objects_list[i]], objects_list[i]]);
        i++;
      }
      let table_output = {
        headers: header_array,
        data: data_array,
      };
      return table_output;
    }

    if (
      !Object.keys(table).includes("headers") &&
      !Object.keys(table).includes("data")
    ) {
      let modifiedTable = myFunction(table);
      console.log("modified", modifiedTable);
      setTableState(modifiedTable);
    }

    return (
      <Box>
        <table
          _hover={{
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            transform: "scale(1.01)",
          }}
          style={{
            borderCollapse: "collapse",
            margin: "16px",

            border: "1px solid #6750a4",
            borderEndEndRadius: "8px",
          }}
        >
          <thead>
            <tr>
              {tableState?.headers?.map((header, index) => (
                <th
                  key={index}
                  style={{
                    backgroundColor: "#805AD5",
                    color: "white",
                    padding: "12px",
                    textAlign: "left",
                    borderBottom: "2px solid #6750a4",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableState?.data?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{
                      padding: "12px",
                      borderBottom: "1px solid #6750a4",
                    }}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    );
  };

  const LessonPlan = ({ Tasks }) => {
    const [tasks, setTasks] = useState(Tasks);
    const [addedTasks, setAddedTasks] = useState([]);

    const [loadingStates, setLoadingStates] = useState(tasks.map(() => false));

    const handleTaskStatusChange = (index, name) => {
      const task = tasks[index];
      const matchingAddedTaskIndex = addedTasks.findIndex(
        (addedTask) => addedTask.name === name
      );

      const taskToAdd = {
        metrics: {
          todays_tasks: {
            tasks: [...oldTasks, ...addedTasks],
          },
        },
      };

      if (matchingAddedTaskIndex !== -1) {
        const postTasktoLessonPlan = async (username, workspaceId, body) => {
          return await axios
            .post(
              `${NEW_API_URL}gamification/update_metrics/${username}/${workspaceId}/`,
              body
            )
            .then((res) => {
              const updatedTasks = [...tasks];
              if (updatedTasks[index].completed === false) {
                toast({
                  title: `congratulation you have completed the task - ${task.name}`,
                  position: "top-right",
                  isClosable: true,
                  duration: 3000,
                  status: "success",
                });
              }
              updatedTasks[index].completed =
                updatedTasks[index].completed === true ? false : true;
              setTasks(updatedTasks);
              toast({
                title: `${task.name} update successfully`,
                position: "top-right",
                isClosable: true,
                duration: 1000,
                status: "success",
              });

              console.log("sending task to lesson plan", res);

              if (res.status >= 400) {
                toast({
                  title: `Something went wrong, please try again later`,
                  position: "top-right",
                  isClosable: true,
                  duration: 3000,
                  status: "error",
                });
                throw new Error(
                  `Failed to post task to lesson plan. Status: ${res.status}`
                );
              }

              return res;
            })
            .catch((error) => {
              console.error("Failed to post task to lesson plan", error);
              toast({
                title: `Something went wrong, please try again later`,
                position: "top-right",
                isClosable: true,
                duration: 3000,
                status: "error",
              });
            });
        };

        postTasktoLessonPlan("patelhetvarasada@gmail.com", 2, taskToAdd);
      } else {
        toast({
          title: `This task can not be completed, first you have to add it to the your lesson plan`,
          position: "top-right",
          isClosable: true,
          duration: 3000,
          status: "warning",
        });
      }
    };

    const handleAddToTask = (index) => {
      const task = tasks[index];

      if (!addedTasks.includes(task)) {
        const taskToAdd = {
          metrics: {
            todays_tasks: {
              tasks: [
                ...oldTasks,
                ...addedTasks,
                {
                  name: task.name,
                  completed: false,
                  time_estimate: task.time_estimate,
                  pending_date: isoFormatTwoWeeksLater,
                },
              ],
            },
          },
        };
        setLoadingStates((prevLoadingStates) =>
          prevLoadingStates.map((state, i) => (i === index ? true : state))
        );
        const postTasktoLessonPlann = async (username, workspaceId, body) => {
          return await axios
            .post(
              `https://55aaqcxwk7.execute-api.ap-south-1.amazonaws.com/dev/gamification/update_metrics/${username}/${workspaceId}/`,
              body
            )
            .then((res) => {
              setAddedTasks([...addedTasks, task]);
              console.log("sending task to lesson plan", res);
              toast({
                title: `${task.name} added to your lesson plan successfully`,
                position: "top-right",
                isClosable: true,
                duration: 3000,
                status: "success",
              });

              if (res.status >= 400) {
                toast({
                  title: `Something went wrong, please try again later`,
                  position: "top-right",
                  isClosable: true,
                  duration: 3000,
                  status: "error",
                });
                throw new Error(
                  `Failed to post task to lesson plan. Status: ${res.status}`
                );
              }

              return res;
            })
            .catch((error) => {
              console.error("Failed to post task to lesson plan", error);
              toast({
                title: `Something went wrong, please try again later`,
                position: "top-right",
                isClosable: true,
                duration: 3000,
                status: "error",
              });
            })
            .finally(() =>
              setLoadingStates((prevLoadingStates) =>
                prevLoadingStates.map((state, i) =>
                  i === index ? false : state
                )
              )
            );
        };

        postTasktoLessonPlann("patelhetvarasada@gmail.com", 2, taskToAdd);
      } else {
        toast({
          title: `${task.name} already added to your lesson plan`,
          position: "top-right",
          isClosable: true,
          duration: 3000,
          status: "warning",
        });
      }
    };

    const currentDate = new Date();
    const twoWeeksLater = new Date(
      currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
    );

    const isoFormatTwoWeeksLater = twoWeeksLater.toISOString();

    function formatToUserFriendlyDate(isoDate) {
      const date = new Date(isoDate);
      const day = date.getDate();
      const month = date.toLocaleString("en-US", { month: "short" });
      const year = date.getFullYear();

      const userFriendlyDate = `${day}/${month}/${year}`;
      return userFriendlyDate;
    }

    console.log("adddedTask", addedTasks);

    return (
      <Box
        _hover={{
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
          transform: "scale(1.01)",
          transition: "all ease",
          transitionDuration: "0.3s",
        }}
        border="1px solid #6750a4"
        borderRadius="lg"
        p={4}
        marginY={4}
        ml={"15px"}
        maxWidth="70%"
      >
        <Text
          fontSize="xl"
          color="purple.700"
          fontWeight="bold"
          marginBottom="4"
        >
          Lesson Plan
        </Text>
        <Stack spacing={4}>
          {tasks.map((task, index) => {
            console.log(task.completed);
            return (
              <Box
                key={index}
                padding="8px"
                backgroundColor="purple.200"
                borderRadius="lg"
                _hover={{
                  backgroundColor: "purple.300",
                }}
                display={"flex"}
                justifyContent={"space-between"}
                gap={2}
              >
                <Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display={"flex"} gap={3}>
                      <Checkbox
                        isChecked={task.completed === true}
                        onChange={() =>
                          handleTaskStatusChange(index, task.name)
                        }
                      />
                      <Text
                        m={0}
                        textDecoration={
                          task.completed === true ? "line-through" : "none"
                        }
                      >
                        {task.name}
                      </Text>
                    </Box>
                    <Badge
                      colorScheme={task.completed === true ? "green" : "yellow"}
                    >
                      {task.completed === true ? "Completed" : "Pending"}
                    </Badge>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    mt={1.5}
                    gap={3}
                    ml={7}
                  >
                    <Text fontSize={"xs"} m={0}>
                      Estimate time: {task.time_estimate} hours
                    </Text>
                    <Text fontSize={"xs"} m={0}>
                      Pending date:{" "}
                      {formatToUserFriendlyDate(isoFormatTwoWeeksLater)}
                    </Text>
                  </Box>
                </Box>
                <Box
                  rounded={"md"}
                  transition={"all 0.3s ease"}
                  alignSelf={"center"}
                  padding={"5px"}
                  py={"7px"}
                  _hover={{
                    cursor: "pointer",
                    transform: "scale(1.1)",
                    backgroundColor: "purple.400",
                  }}
                  onClick={() => handleAddToTask(index)}
                >
                  {addedTasks.includes(task) ? (
                    <MdBookmarkAdded size={25} />
                  ) : !loadingStates[index] ? (
                    <BiSolidBookmarkPlus
                      size={25}
                      onClick={() => handleAddToTask(index)}
                    />
                  ) : (
                    <Spinner color="purple.500" size={"md"} />
                  )}
                </Box>
              </Box>
            );
          })}
        </Stack>
      </Box>
    );
  };

  const QuizComponent = ({ quiz }) => {
    const [selectedOptions, setSelectedOptions] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const toast = useToast();

    const handleSubmit = (data) => {
      console.log("Submitted Answers:", data);
    };

    const handleOptionChange = (value) => {
      if (!submitted) {
        setSelectedOptions({
          ...selectedOptions,
          [currentQuestionIndex]: value,
        });
      }
    };

    const handleQuizSubmit = () => {
      setSubmitted(true);

      // Create the submit data format
      const submitData = {
        practice_type: "mock paper",
        test_format: "objective",
        subject: "Practice Papers",
        chapter: "chapter 8 2014",
        max_score: 0,
        user_score: 0,
        time_taken: 5,
        question: {},
      };

      quiz.forEach((q, index) => {
        submitData.question[index] = {
          Question: q.question,
          UserSelectedAnswer: selectedOptions[index] || "",
          CorrectAnswer: q.options
            .filter((option) => option === q.correctOption)
            .join(", "),
          IsCorrect: selectedOptions[index] === q.correctOption,
        };
      });

      submitData.TotalCorrectAnswers = Object.values(
        submitData.question
      ).filter((q) => q.IsCorrect).length;
      submitData.TotalAttendedQuestions =
        Object.values(selectedOptions).filter(Boolean).length;

      handleSubmit(submitData);

      toast({
        title: `Quiz submitted successfully`,
        position: "top-right",
        isClosable: true,
        duration: 3000,
        status: "success",
      });
    };

    const handleNextQuestion = () => {
      if (currentQuestionIndex < quiz.length - 1 && !submitted) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    };

    const handlePrevQuestion = () => {
      if (currentQuestionIndex > 0 && !submitted) {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
    };

    const { question, options } = quiz[currentQuestionIndex];

    return (
      <Box
        _hover={{
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
          transform: "scale(1.01)",
          transition: "all ease",
          transitionDuration: "0.3s",
        }}
        border="1px solid #6750a4"
        borderRadius="lg"
        p={4}
        my={4}
        ml={"15px"}
        display={"flex"}
        flexDir={"row"}
        justifyContent={"space-between"}
        gap={5}
        transition={"all 0.3s ease"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          onClick={handlePrevQuestion}
          isDisabled={currentQuestionIndex === 0 || submitted}
          cursor={"pointer"}
        >
          <IoIosArrowBack size={28} />
        </Box>
        <Box display={"flex"} flexDir={"column"} gap={3}>
          <Text
            fontSize="xl"
            color="purple.700"
            fontWeight="bold"
            marginBottom="3"
          >
            {question}
          </Text>

          {!submitted && (
            <RadioGroup
              value={selectedOptions[currentQuestionIndex]}
              onChange={handleOptionChange}
            >
              <Stack spacing={2}>
                {options.map((option, index) => (
                  <Box
                    key={index}
                    borderRadius="lg"
                    _selected={{
                      backgroundColor: "purple.400",
                      color: "white",
                      borderColor: "purple.400",
                    }}
                    padding="8px"
                    backgroundColor={
                      selectedOptions[currentQuestionIndex] === option
                        ? "purple.400"
                        : "purple.200"
                    }
                    transition="background-color 0.9s ease"
                    _hover={{
                      backgroundColor:
                        selectedOptions[currentQuestionIndex] === option
                          ? "purple.400"
                          : "purple.300",
                    }}
                  >
                    <Radio
                      value={option}
                      border="1px solid black"
                      isDisabled={submitted}
                    >
                      {option}
                    </Radio>
                  </Box>
                ))}
              </Stack>
            </RadioGroup>
          )}

          <Button
            colorScheme="purple"
            variant="outline"
            size="sm"
            onClick={handleQuizSubmit}
            isDisabled={!selectedOptions[currentQuestionIndex] || submitted}
          >
            {submitted ? "Quiz Submitted" : "Submit"}
          </Button>
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          onClick={handleNextQuestion}
          isDisabled={currentQuestionIndex === quiz.length - 1 || submitted}
          cursor={"pointer"}
        >
          <IoIosArrowForward size={28} />
        </Box>
      </Box>
    );
  };

  return (
    <Box display={"flex"} flexDir={"column"} maxWidth={"84%"}>
      {chat.table && <TableComponent table={chat?.table} />}
      {chat.quiz && (
        <QuizComponent onQuizSubmit={handleQuizSubmit} quiz={chat?.quiz} />
      )}
      {/* {chat.tasks && <LessonPlan Tasks={chat.tasks} />} */}
    </Box>
  );
};

export default InteractiveElements;
