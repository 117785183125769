import React from 'react';
import {MapContainer, Marker, TileLayer, useMap,Popup} from 'react-leaflet'
import L, { MarkerCluster } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import { addressPoints } from "./realworld";

const customIcon = new L.Icon({
    iconUrl: '/location.svg',
    iconSize: new L.Point(40, 47)
});

const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
        html: `<span>${cluster.getChildCount()}</span>`,
        className: "custom-marker-cluster",
        iconSize: L.point(33, 33, true)
    });
};


function StreetMapView(props) {

    const position = [28.6139, 77.2090]

    return (
        <div style={{height:'600px',width:'100%'}}>
            <MapContainer style={{ height: "100%", minHeight: "100%"}}
                          // center={position} zoom={13}
                          center={position}
                          zoom={4}
                          scrollWheelZoom={true}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <MarkerClusterGroup chunkedLoading>
                    {console.log(props.data[0].locations)}
                    {(props.data).map((address1, index) => (
                        address1.locations.map((address, index) => (
                            <Marker
                                icon={customIcon}
                                key={index}
                                position={[address.latitude, address.longitude]}
                                title={address.title}
                                eventHandlers={{
                                    click: (e) => {
                                        // alert('marker clicked', address[2])
                                    },
                                }}
                            >
                                <Popup>
                                    <h6 style={{margin:0}}>{address.title}</h6>
                                    <br/>
                                    {address.description}
                                </Popup>

                            </Marker>
                        ))

                ))}
                </MarkerClusterGroup>

                {/*<Marker position={position}>*/}
                {/*    /!*<Popup>*!/*/}
                {/*        A pretty CSS3 popup. <br /> Easily customizable.*/}
                {/*    /!*</Popup>*!/*/}
                {/*</Marker>*/}
            </MapContainer>


        </div>
    );
}

export default StreetMapView;
