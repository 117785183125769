import React, {useState, useEffect, useRef} from "react";
import Joyride, { STATUS } from "react-joyride";

import {
    Avatar,
    Box,
    Button, Center,
    Container,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Select,
    Tag, Text, Textarea, useDisclosure,
    VStack,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight, AiOutlineSearch} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import {ArrowBackIcon} from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import NotesCard from "../../Components/NotesCard";
import Markdown from "react-markdown";
import {HighlightMenu, setClipboard} from "react-highlight-menu";
import ButtonText from "../../Components/Button/ButtonText";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
import AskBudAccent from "../../Components/AskBudAccent";
import Psm from "../../Components/Typography/Text/Psm";
import Plg from "../../Components/Typography/Text/Plg";

const markdown_str='---\n' +

    ':::\n'


const IToolsReading = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedText, setSelectedText] = useState('');
  const menuRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const notesModalStates = useDisclosure()
  const {topic,subject,area,noteid} = useParams();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  let workspaceId = '';
  if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
    workspaceId = selectedWorkspace.workspace_id
  }
    const [topicDetail,setTopicDetail] = useState({})

    const [itoolData,setItoolData] = useState({});
    // const [title,setTitle] = useState('');
    // const [desc,setDesc] = useState('');

    const get_map_data = async (username,workspace) => {
        const r = await axios.get(NEW_API_URL+'lessonplan/get_topic_detail/'+username+'/'+workspace+'/')
        setTopicDetail(r.data.topic_detail)
        // setReadings(r.data.topic_detail.reading_list)
        return r.data
    }



    useEffect(()=>{
      axios.post(NEW_API_URL+"lessonplan/update_activity/",{
          "user_id":username,
          "topic_id":topic,
          "activity_type":"itools",
          "activity_id":noteid,
          "read_status":"Read"
      }).then(r=>{})
        get_news_data()
        get_map_data(username,topic)
  },[])


    const get_news_data = async () => {
        const r = await axios.get(NEW_API_URL+'lessonplan/itool/'+(noteid)+'/')

        // setTopicDetail(r.data.topic_detail)
        // setTimelineData(r.data)
        setItoolData(r.data)
        setMaxPages(r.data.pages.length-1)

        console.log(r.data,'news data')


        return r.data
    }


  const [page, setPage] = useState(0);
  const [forceOpen, setForceOpen] = useState(false);
  const [initText, setInitText] = useState('');
  const [maxPages, setMaxPages] = useState(2);
  const [questionData,setQuestionData] = useState({})
    const [resize, setResize] = React.useState('horizontal')
  // const [noteDetail, setNoteDetail] = useState({
  //   pages:['PG1'+markdown_str,'PG2'+markdown_str,'PG3'+markdown_str],
  // });



  return (
      <>
      <Container  justifyContent={'center'} alignItems={'center'} w={'95%'} maxW='container.xl' >
        <Flex flexDirection={'column'} alignItems={'space-between'} justifyContent={'space-between'} minH={'95vh'}>
        <Box>
        <Flex mt={'10vh'} alignItems={'center'}>
            <ArrowBackIcon cursor={'pointer'} onClick={()=>{navigate(-1)}} marginLeft={'-65px'} marginRight={'20px'} boxSize={'45px'}/>

          <Box>
        <P2xl mb={0} ml={0} mr={0}  >
          <strong>{itoolData.title}</strong>

        </P2xl>
        <Pmd color={'#817D7D'} style={{margin:0}}><strong>{itoolData.topic_name}</strong></Pmd>
          </Box>
        </Flex>
        <hr/>

          <Container borderRadius={12} maxW={'container.xl'} ref={menuRef} >
              <HeadCard title={itoolData && itoolData.pages ? itoolData.pages[page].page_title: 'Background'} pb={'10px'}>

                  {
                      itoolData && itoolData.pages && itoolData.pages[page].page_content.map((item,idx)=> {


                          if(item.image_link){
                              return <Flex justifyContent={'center'} flexDirection={'column'}  alignItems={'center'} mt={6} mb={6}>
                                  <Image src={item.image_link} alt="Profile Photo" />
                                  <p>{item.image_description}</p>
                              </Flex>
                          }
                          if(item.text){
                              return <><Textarea
                                  placeholder=''
                                  size='lg'
                                  resize={resize}
                                  onChange={(e)=>{
                                      let cpy = JSON.parse(JSON.stringify(itoolData));
                                      // console.log(cpy,e.target.value)
                                      cpy['pages'][page].page_content[idx].text = e.target.value;
                                      setItoolData(cpy)
                                  }}
                                  mb={6}
                                  px={20}
                                  value={item.text}
                                  sx={{ 'field-sizing': 'content',height:'initial' }}
                              /><Pmd mb={6} px={'20px'}>{item.text}</Pmd></>
                          }

                          if(item.question){
                            return (<Container mb={6} maxW={'70%'}>
                                  <Center mt={12} bgColor={'white'} position={'relative'} borderRadius={'12px'} minH={'180px'}>
                                      <Pxl m={0} fontWeight={'400'}>{item.question}</Pxl>
                                  </Center>
                                 <>
                                      <Flex alignItems={'center'} justifyContent={'space-around'} mt={'20px'}>
                                          <Flex cursor={'pointer'} alignItems={'center'} w={'45%'}
                                                bgColor={questionData[item.question] === 'a' ? item.answer.toLowerCase() === 'a'?'green':'red' :'white'}
                                                borderRadius={'100px'} padding={'12px'}
                                                boxShadow={'0px 2px 2px 0px #00000040'}
                                                onClick={()=>{
                                                    let cpy = JSON.parse(JSON.stringify(questionData))
                                                    cpy[item.question] = 'a'
                                                    setQuestionData(cpy)
                                                }}
                                          >
                                              <Box ml={'12px'} borderRadius={'50px'}>
                                                  <Pxl color={questionData[item.question] === 'a' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>
                                                      A
                                                  </Pxl>
                                              </Box>
                                              <Box w={'100%'}>

                                                  <Pxl color={questionData[item.question] === 'a' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>{
                                                      item.option_a
                                                  }</Pxl>

                                              </Box>

                                          </Flex>

                                          <Flex cursor={'pointer'} alignItems={'center'} w={'45%'}
                                                bgColor={questionData[item.question] === 'b' ? item.answer.toLowerCase() === 'b'?'green':'red' :'white'}
                                                borderRadius={'100px'} padding={'12px'}
                                                boxShadow={'0px 2px 2px 0px #00000040'}
                                                onClick={()=>{
                                                    let cpy = JSON.parse(JSON.stringify(questionData))
                                                    cpy[item.question] = 'b'
                                                    setQuestionData(cpy)
                                                }}
                                          >
                                              <Box ml={'12px'} borderRadius={'50px'}>
                                                  <Pxl color={questionData[item.question] === 'b' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>
                                                      B
                                                  </Pxl>
                                              </Box>
                                              <Box w={'100%'}>

                                                  <Pxl color={questionData[item.question] === 'b' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>{
                                                      item.option_b
                                                  }</Pxl>

                                              </Box>

                                          </Flex>


                                      </Flex>

                                     <Flex alignItems={'center'} justifyContent={'space-around'} mt={'20px'}>
                                         <Flex cursor={'pointer'} alignItems={'center'} w={'45%'}
                                               bgColor={questionData[item.question] === 'c' ? item.answer.toLowerCase() === 'c'?'green':'red' :'white'}
                                               borderRadius={'100px'} padding={'12px'}
                                               boxShadow={'0px 2px 2px 0px #00000040'}
                                               onClick={()=>{
                                                   let cpy = JSON.parse(JSON.stringify(questionData))
                                                   cpy[item.question] = 'c'
                                                   setQuestionData(cpy)
                                               }}
                                         >
                                             <Box ml={'12px'} borderRadius={'50px'}>
                                                 <Pxl color={questionData[item.question] === 'c' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>
                                                     C
                                                 </Pxl>
                                             </Box>
                                             <Box w={'100%'}>

                                                 <Pxl color={questionData[item.question] === 'c' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>{
                                                     item.option_c
                                                 }</Pxl>

                                             </Box>

                                         </Flex>

                                         <Flex cursor={'pointer'} alignItems={'center'} w={'45%'}
                                               bgColor={questionData[item.question] === 'd' ? item.answer.toLowerCase() === 'd'?'green':'red' :'white'}
                                               borderRadius={'100px'} padding={'12px'}
                                               boxShadow={'0px 2px 2px 0px #00000040'}
                                               onClick={()=>{
                                                   let cpy = JSON.parse(JSON.stringify(questionData))
                                                   cpy[item.question] = 'd'
                                                   setQuestionData(cpy)
                                               }}
                                         >
                                             <Box ml={'12px'} borderRadius={'50px'}>
                                                 <Pxl color={questionData[item.question] === 'd' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>
                                                     D
                                                 </Pxl>
                                             </Box>
                                             <Box w={'100%'}>

                                                 <Pxl color={questionData[item.question] === 'd' ? 'white':'black'} textAlign={'center'} ml={'12px'} fontWeight={'400'} m={0}>{
                                                     item.option_d
                                                 }</Pxl>

                                             </Box>

                                         </Flex>


                                     </Flex>

                                  </>







                              </Container>)

                          }

                          return <>{JSON.stringify(item)}</>

                      })
                  }
              </HeadCard>


          {/*{noteDetail.pages.length > page && <Markdown >{noteDetail.pages[page]}</Markdown>}*/}
          </Container>

          <Center>
          {page >0 && <ButtonPrimary
              style={{margin: 10}}
              onClick={() => {
                setPage(page-1)
              }}
          >
            {'Previous'}
          </ButtonPrimary>}

          {page < maxPages && <ButtonPrimary
              style={{margin: 10}}
              onClick={() => {
                setPage(page + 1)
              }}
          >
            Next
          </ButtonPrimary>}
            {page === maxPages && searchParams.get("home") && <ButtonPrimary link={'/home'}
              style={{margin: 10}}
          >
            Go to Home
          </ButtonPrimary>}
          </Center>

          <Center mb={20} mt={18}>
            {
              (itoolData.pages || []).map((item,index)=>{
                return (<>
                  <Box mr={'12px'} borderRadius={50} w={'12px'} h={'12px'} backgroundColor={index===page?'#0962A2':'#A2A2A24D'}></Box>
                </>)
              })
            }
          </Center>
        </Box>
        </Flex>


        <HighlightMenu
            styles={{
              backgroundColor:'#344675',
              borderRadius:'16px'
            }}
            menu={({
                     selectedText,
                     setMenuOpen,
                     setClipboard,
                   })=>{
              return (<Flex bg={'#344675'} padding={0} borderRadius={'30px'}>
                <ButtonText
                    fontWeight={'300'}
                    onClick={() => {
                      setSelectedText(selectedText)
                      onOpen()
                        setMenuOpen(false)
                    }}
                >Quick Explanation</ButtonText>
                <Box border={'0.5px solid white'} h={'100%'}/>

                <ButtonText
                    fontWeight={'300'}

                    onClick={() => {
                        setForceOpen(true)
                        setInitText('You are a UPSC assistant. Try to use succinct and to-the-point stoic language with academic inclination. Give answers in the context of '+selectedText)
                        // setSelectedText(selectedText)
                        // notesModalStates.onOpen()
                        setMenuOpen(false)
                    }}
                >Ask Question</ButtonText>
                <Box border={'0.5px solid white'} h={'100%'}/>
                <ButtonText
                    fontWeight={'300'}

                    onClick={() => {
                        setSelectedText(selectedText)
                        notesModalStates.onOpen()
                        setMenuOpen(false)
                    }}
                >Add to Note</ButtonText>

                      </Flex>)
            }}
            target={menuRef}
           />
    {/*      <div ref={menuRef}>*/}
    {/*  Selecting this text will show the menu!*/}
    {/*</div>*/}

        <CustomStyledModal quick_explain isOpen={isOpen} onOpen={onOpen} onClose={onClose} title={'Quick Explanation'} selectedText={selectedText}/>
        <CustomStyledModal toast={'Note successfully created! You can view it on the Notes page in this topic'} add_note isOpen={notesModalStates.isOpen} onOpen={notesModalStates.onOpen} onClose={notesModalStates.onClose} title={'Notes'} selectedText={selectedText}/>
          <AskBudAccent context={initText} forceOpen={forceOpen} setForceOpen={setForceOpen} initText={initText}/>
          <CustomStyledModal
              add_type_topic={true}
              area={''} subject={''} topic_name={topicDetail.topic_name} topic_id={topic}
              toast={'Note successfully created!'} topicDetail={topicDetail} add_note isOpen={notesModalStates.isOpen} onOpen={notesModalStates.onOpen} onClose={notesModalStates.onClose} title={'Notes'} selectedText={''}/>

          <Flex onClick={()=>{notesModalStates.onOpen()}} cursor={'pointer'} w={'80px'} h={'80px'} alignItems={'center'} justifyContent={'center'} bg={"claydeck.brand.primary"} border={'2px solid white'} boxShadow={'0px 4px 4px 0px #00000040'} position={'fixed'} bottom={'120px'} right={'20px'} borderRadius={'50px'}>
              <Text color={'white'} margin={0} fontSize={'32px'}>+</Text>
          </Flex>

      </Container>
      </>

  );
}

export default IToolsReading;
