import { Box, Grid, GridItem, Text, Image } from "@chakra-ui/react";
import getEventTimeDetails from "../utils/getEventTimeDetails";
import deleteEvent from "../utils/deleteEvent";

const EventCard = ({ event }) => {
  const { month, date, TimeFormate } = getEventTimeDetails(event.timestamp);
  // const { isLoading } = deleteEvent(1, 1, 2442); delete certain event ...'

  return (
    <Box
      w={"auto"}
      m={{ md: "2", base: "6px" }}
      p={{ md: "2", base: "6px" }}
      backgroundColor={"white"}
      position={"relative"}
      _hover={{
        transform: "scale(1.006)",
        transition: "transform 0.2s",
      }}
    >
      <Grid
        templateColumns={{ base: "repeat(16, 1fr)", md: "repeat(16, 1fr)" }}
        gap={"1"}
      >
        <GridItem colSpan={"1"}>
          <Box
            w={{ md: "45px", base: "40px" }}
            h={{ md: "45px", base: "40px" }}
            color={"white"}
            rounded={"md"}
            background={"#21005D"}
            flexDirection={"column"}
            fontSize={{ md: "12px", base: "10px" }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text>{date}</Text>
            <Text>{month}</Text>
          </Box>
        </GridItem>
        <GridItem colStart={"2"} colEnd={"12"} colSpan={"4"}>
          <Box marginLeft={"4px"} display={"flex"} flexDirection={"column"}>
            <Text
              fontSize={{ md: "16px", base: "12px" }}
              fontWeight={"semibold"}
            >
              {event.event_title}
            </Text>
            <Box
              fontSize={{ md: "14px", base: "10px" }}
              fontWeight={"light"}
              color={"#828282"}
              display={"flex"}
            >
              <Text>Organizer: {event.organizer}</Text>
              <Text marginLeft={"8px"}>Time: {TimeFormate}</Text>
            </Box>
          </Box>
        </GridItem>

        <Box
          cursor={"pointer"}
          position={"absolute"}
          right="0"
          top="0"
          w={{ md: "55px", base: "50px" }}
          rounded={"md"}
          height={"full"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          as="div"
          opacity={"84%"}
          _hover={{
            opacity: "100%",
          }}
          backgroundColor={
            event.canEnter
              ? "rgba(215, 255, 139, 0.15)"
              : "rgba(214, 139, 255, 0.15)"
          }
        >
          <Image
            opacity={"100%"}
            src={
              event.canEnter
                ? "assets/icons/rightGreenArrow.svg"
                : "assets/icons/calendar.svg"
            }
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default EventCard;
