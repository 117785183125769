import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider,extendTheme } from '@chakra-ui/react'
import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const root = ReactDOM.createRoot(document.getElementById('root'));



const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(radioAnatomy.keys)

    datadogRum.init({
        applicationId: 'ffd26e9e-caaf-4fe9-a70f-eeab414b9d35',
        clientToken: 'pub9e2017985334295da42129d4113fff9b',
        site: 'datadoghq.eu',
        service:'claydeck-client',

        // Specify a version number to identify the deployed version of your application in Datadog
        version: '1.0.0',
        sampleRate: 100,
        sessionReplaySampleRate: 20,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask-user-input'
    });

    datadogLogs.init({
        clientToken: 'pubac3f09496a5db7c0b7d75285fae47505',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sampleRate: 100
    });

    datadogRum.startSessionReplayRecording();

const baseStyle = definePartsStyle({
    // define the part you're going to style
    control: {
        _checked: {
            _hover: {
                bg: "claydeck.brand.primary", // Replace "#ff9900" with your desired custom color
                borderColor: "transparent", // Replace "#ff9900" with your desired custom color
                outline:'none'

            },
            bg: "claydeck.brand.secondary", // Replace "#ff9900" with your desired custom color
            borderColor: "transparent", // Replace "#ff9900" with your desired custom color
            outline:'none'
        },
        _hover: {
            outline:'none',
            bg: "claydeck.brand.hover",
            borderColor: "claydeck.brand.primary", // Replace "#ff9900" with your desired custom color

        },
        outline:'none'
    },
})
const radioTheme = defineMultiStyleConfig({ baseStyle })

const theme = extendTheme({
    components: { Radio: radioTheme,Checkbox:radioTheme },
    colors: {
        claydeck: {
            brand:{
                "primary": "#0962A2",
                "secondary": "#003D5F",
                "hover":"#1b85d1"
            },
            white:{
                "primary": "#fff",
                "secondary":"#F5F5F5"
            },
            text:{
                "primary":"#1C1B1F",
                "secondary":"#4A4A4A",
                "tertiary":"#828282"
            },
            icon:{
                "active":"#0962A2",
                "primary":"#828282",
                "hover":"#4A4A4A"
            }
        },
    },
})


root.render(
  // <React.StrictMode>
      <ChakraProvider theme={theme}>
          <App />
      </ChakraProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
