import React from 'react';
import {Box, Flex, Image, LinkBox} from "@chakra-ui/react";
import Pmd from "./Typography/Text/Pmd";
import Pxl from "./Typography/Text/Pxl";
import { Link } from 'react-router-dom';

function NotesCard(props) {
    const dumm = () =>{}
    return (
        <Link to={props.to || '#'}>
        <Box position={"relative"} onClick={props.onClick || dumm}  w={props.w || '300px'} borderRadius={'12px'} backgroundColor={'claydeck.white.primary'} {...props} boxShadow={props.shadow?'0px 4px 10px 0px #0000001A':'none'} border={'1px solid #B6B6B6'}>

            {props.read_status === 'Read' && <Image style={{position:'absolute',right:'10px',top:'10px'}} src={'/read_tick.png'} height={'20px'} width={'20px'}/>}
            {/*{props.read_status !== 'Read' && <Image style={{position:'absolute',right:'10px',top:'10px'}} src={'/read_circle.png'} height={'20px'} width={'20px'}/>}*/}

            <Flex justifyContent={'center'} alignItems={'center'} flexDirection={'column'} pt={7} pb={5}>

                <Image src={props.icon || '/notes_preview.png'} h={'100px'}></Image>
                <Pxl textAlign={'center'} mt={5} fontWeight={'500'}>{props.title}</Pxl>
            </Flex>
        </Box>
        </Link>
    );
}

export default NotesCard;
