import axios from "axios";
import { useState } from "react";

const useGetSubTest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchSubTest = async (mail, id) => {
    try {
      const res = await axios.get(
        `https://55aaqcxwk7.execute-api.ap-south-1.amazonaws.com/dev/gamification/subjectivequiz/${mail}/2/`
      );

      console.log(res);
      return res?.data;
    } catch (error) {
      console.error("Error fetching response:", error);
      setError(error);
    }
  };

  return { fetchSubTest, isLoading, setIsLoading, error };
};

export default useGetSubTest;
