import {useState, useEffect} from "react";
import { useParams } from "react-router-dom";

import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

import H1 from "../../Components/Typography/Heading/H1";
import { Box, Container } from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import Plg from "../../Components/Typography/Text/Plg";
import InputPassword from "../../Components/Input/InputPassword";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";

import axios from "axios";
import { API_URL_STAGING } from "../../redux/types";

const PasswordReset = () => {

  const {uid, token} = useParams();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    newPassword: false,
    confirmPassword: false
  })
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setSuccess(false);
  }, []);

  const handleError = (name, state) => {
    if (state === true) onOpen();

    setErrors(prev => {
      console.log({...prev, [name]: state})
      return {
        ...prev,
        [name]: state
      }
    })

  }

  const resetPassword = () => {
    // password validation
    const regexPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const testPassword = regexPassword.test(newPassword);
    if (!testPassword) {
      handleError("newPassword", true);
      return;
    }
    handleError("newPassword", false);
    
    // password match validation
    if (newPassword !== confirmPassword) {
      handleError("confirmPassword", true);
      return;
    }
    handleError("confirmPassword", false);

    const url = `${API_URL_STAGING}user/reset-password/${uid}/${token}/`;
    const body = {
      new_password: newPassword,
      confirm_password: confirmPassword,
    };

    axios
      .post(url, body)
      .then((response) => {
        console.log("password reset response", response);
        setLoading(false);
        setSuccess(true);
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        console.log("password reset response", error);
        setLoading(false);
      });
  }

  return (
    <Container>
      {success ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"24px"}
          marginTop={"72px"}
        >
          <CheckCircleIcon boxSize={24} color={"#6ac37f"} />
          <H1>Password Reset Successfully!</H1>
          <Plg>You can now close this window</Plg>
        </Box>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"24px"}
          marginTop={"72px"}
        >
          <H1 marginBottom={"24px"}>Reset Password</H1>
          <Box>
            <Pmd
              htmlFor="password"
              color={"claydeck.text.tertiary"}
              mb={"16px"}
            >
              New Password
            </Pmd>
            <InputPassword
              name="password"
              value={newPassword}
              borderColor={errors.newPassword ? "#FF0E36" : "#aeaaae"}
              onChange={(event) => setNewPassword(event.currentTarget.value)}
            />
          </Box>
          <Box>
            <Pmd
              htmlFor="confirmPassword"
              color={"claydeck.text.tertiary"}
              mb={"16px"}
            >
              Confirm Password
            </Pmd>
            <InputPassword
              name="confirmPassword"
              value={confirmPassword}
              borderColor={errors.confirmPassword ? "#FF0E36" : "#aeaaae"}
              onChange={(event) =>
                setConfirmPassword(event.currentTarget.value)
              }
            />
          </Box>
          <ButtonSecondary
            onClick={() => resetPassword()}
            marginTop={"12px"}
            isLoading={loading}
            loadingText="Resetting Password"
          >
            Reset Password
          </ButtonSecondary>
        </Box>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invalid Password</ModalHeader>
          <ModalBody>
            {errors.newPassword
              ? "Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
              : errors.confirmPassword
              ? "Passwords do not match"
              : ""}
          </ModalBody>
          <ModalFooter>
            <ButtonSecondary onClick={onClose}>Close</ButtonSecondary>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default PasswordReset;
