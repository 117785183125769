import axios from "axios";
import { useState } from "react";
import { NEW_API_URL } from "../../../redux/types";

const useGetAIAnswer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorAns, setErrorAns] = useState({});

  const fetchAIAnswer = async (username, question) => {
    console.log(question);
    try {
      const res = await axios.post(
        `${NEW_API_URL}community/ai_search/${username}/`,
        {
          question: question,
        }
      );
      return res?.data;
    } catch (error) {
      console.error("Error fetching response:", error);
      setErrorAns(error);
    }
  };

  return { fetchAIAnswer, isLoading, setIsLoading, errorAns };
};

export default useGetAIAnswer;
