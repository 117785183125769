import axios from "axios";
import { useState } from "react";

const useGetSubFeedback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchSubFeedback = async (username, ws_id, body) => {
    try {
      const res = await axios.post(
        `https://55aaqcxwk7.execute-api.ap-south-1.amazonaws.com/dev/community/subjective_feedback/${username}/${ws_id}/`,
        body
      );

      console.log(res);
      return res?.data;
    } catch (error) {
      console.error("Error fetching response:", error);
      setError(error);
    }
  };

  return { fetchSubFeedback, isLoading, setIsLoading, error };
};

export default useGetSubFeedback;
