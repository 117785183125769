import React, { useState, useEffect } from 'react';
import MultiRangeSlider from "multi-range-slider-react";
import {
    Button,
    FormControl,
    FormLabel, Input, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, SimpleGrid, Stack, Switch, Textarea, useDisclosure, useToast
} from "@chakra-ui/react";
import ButtonSecondary from "./Button/ButtonSecondary";

const TimelineQuiz = () => {
    const [minValue, set_minValue] = useState(1600);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const [maxValue, set_maxValue] = useState(1700);
    const handleInput = (e) => {
        set_minValue(e.minValue);
        set_maxValue(e.maxValue);
    };
    const [year, setYear] = useState(new Date().getFullYear());
    const [answers, setAnswers] = useState([]);

    // Dummy questions for the quiz
    const questions = [
        { id: 1, question: 'What year was the internet invented?', correctAnswer: '1983' },
        // Add more questions as needed
    ];

    // Increase the year over time
    useEffect(() => {
        const interval = setInterval(() => {
            setYear((prevYear) => prevYear + 1);
        }, 1000); // Update every second for demonstration, adjust as needed

        return () => clearInterval(interval);
    }, []);

    const handleAnswer = (questionId, answer) => {
        // Implement answer handling, updating answers state, etc.
        setAnswers([...answers, { questionId, answer }]);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <div style={{ flex: 1 }}>
                <MultiRangeSlider
                    min={1600}
                    labels={['UPSC Era','Jurassic Era','Triassic Era','Prehistoric Era','Industrial Era','Modern Era']}
                    max={1900}
                    step={20}
                    minValue={minValue}
                    maxValue={maxValue}
                    onInput={(e) => {
                        handleInput(e);
                    }}
                    barInnerColor={'#6750A4'}
                    style={{
                        boxShadow:'none',
                        border:'none'
                    }}
                />
            </div>
            <div style={{ flex: 3, position: 'relative' }}>
                {/* Bitmap Image */}
                <img src="/map.png" alt="Timeline Image" style={{ width: '100%', height: '100%' }} />
                {/* Overlay Circles */}
                {answers.map((answer, index) => (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            top: `${Math.random() * 100}%`, // Random position, replace with your logic
                            left: `${Math.random() * 100}%`, // Random position, replace with your logic
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: 'red',
                        }}
                    ></div>
                ))}


            </div>
            <div style={{ flex: 1,textAlign:"center",marginTop:10 }}>
                <h2>This is your sample question</h2>
                <SimpleGrid columns={2} spacing={10} mt={10}>
                    <ButtonSecondary onClick={()=>{
                        onOpen();

                    }}>A</ButtonSecondary>
                    <ButtonSecondary onClick={()=>{
                        onOpen();

                    }}>B</ButtonSecondary>
                    <ButtonSecondary onClick={()=>{
                        onOpen();

                    }}>C</ButtonSecondary>
                    <ButtonSecondary onClick={()=>{

                        onOpen();
                    }}>D</ButtonSecondary>

                </SimpleGrid>
                {/* Quiz Section */}
                {/* Render your quiz questions based on the current year or other logic */}
            </div>


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Treaty based question</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody textAlign={'center'} mt={10} mb={10}>
                        <h2>This is your sample question</h2>

                        <SimpleGrid columns={2} spacing={10} mt={10}>
                            <ButtonSecondary onClick={()=>{
                                toast({
                                    title: 'End',
                                    description: "Main Quiz has finished",
                                    status: 'success',
                                    duration: 3000,
                                    isClosable: true,
                                })

                                onClose();
                            }}>A</ButtonSecondary>
                            <ButtonSecondary onClick={()=>{
                                toast({
                                    title: 'End',
                                    description: "Main Quiz has finished",
                                    status: 'success',
                                    duration: 3000,
                                    isClosable: true,
                                })

                                onClose();
                            }}>B</ButtonSecondary>
                            <ButtonSecondary onClick={()=>{
                                toast({
                                    title: 'End',
                                    description: "Main Quiz has finished",
                                    status: 'success',
                                    duration: 3000,
                                    isClosable: true,
                                })

                                onClose();
                            }}>C</ButtonSecondary>
                            <ButtonSecondary onClick={()=>{
                                toast({
                                    title: 'End',
                                    description: "Main Quiz has finished",
                                    status: 'success',
                                    duration: 3000,
                                    isClosable: true,
                                })

                                onClose();
                            }}>D</ButtonSecondary>

                        </SimpleGrid>

                    </ModalBody>
                </ModalContent>
            </Modal>


        </div>
    );
};

export default TimelineQuiz;