import React, {useEffect, useState} from 'react';
import H1 from "../Components/Typography/Heading/H1";
import H2 from "../Components/Typography/Heading/H2";
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Checkbox,
    Container,
    Flex,
    Icon,
    Image,
    Table, Td, Th, Tr,
} from "@chakra-ui/react";
import H3 from "../Components/Typography/Heading/H3";
import Pxl from "../Components/Typography/Text/Pxl";
import Plg from "../Components/Typography/Text/Plg";
import Pmd from "../Components/Typography/Text/Pmd";
import Psm from "../Components/Typography/Text/Psm";
import ButtonPrimary from "../Components/Button/ButtonPrimary";
import ButtonSecondary from "../Components/Button/ButtonSecondary";
import ButtonCheckboxPrimary from "../Components/Button/ButtonCheckboxPrimary";
import ButtonCheckboxSecondary from "../Components/Button/ButtonCheckboxSecondary";
import InputOutline from "../Components/Input/InputOutline";
import InputPassword from "../Components/Input/InputPassword"
import {PiFunnelLight} from "react-icons/pi";
import moment from "moment";
import RoomCard from "../Components/RoomCard";
import axios from "axios";
import {NEW_API_URL} from "../redux/types";
import {useSelector} from "react-redux";
import CreateRoomModal from "../Components/CreateRoomModal";
import ParticipantListModal from "../ParticipantListModal";
import ProfileCard from "../Components/ProfileCard";
import {useNavigate} from "react-router-dom";

function RecentMCQ(props) {

    // const [participants,setParticipants] = useState([])
    // const [blockList,setBlocklist] = useState([])
    // const [reportList,setReportlist] = useState([])
    // const [pendingList,setPendinglist] = useState([])
    // const [forceRefetch,setForceRefetch] = useState(0)
    // const [modalVisible, setModalVisible] = useState(false);
    // const [selectedUser, setSelectedUser] = useState("");
    // const [reportReason, setReportReason] = useState("");
    const currentWorkspace = useSelector((state) => state.user.selectedWorkspace);
    const username = useSelector((state)=>state)
    const [profileData, setProfileData] = useState(null)
    const navigate = useNavigate();


    const [subjects,setSubjects] = useState([
        { subject: 'question 1', score: 'ans 1' },
        { subject: 'question 2', score: 'ans 2' },
        { subject: 'question 3', score: 'ans 3' },
        // Add more subjects as needed
    ]);

    // console.log(currentWorkspace,'cwl')
    const getProfileInfo = async () => {
        try {
            // setFetchLoading(true)
            const axiosConfig = {
                headers: {
                    Authorization: `Bearer ${username.user.accessToken}`,
                },
            }
            await axios.get(`${NEW_API_URL}user/profile/`, axiosConfig)
                .then((res) => setProfileData(res.data))
                .catch((e) => {
                    console.error(e)

                })
        } catch (e) {
            console.error(e)

        } finally {
            // setFetchLoading(false)
        }
    }

    useEffect(()=>{
        getProfileInfo();
    },[])


    console.log(username,'dddw')

    const rooms = useSelector((state) => state.user?.selectedWorkspace?.rooms);
    const workspaces = useSelector((state) => state.user?.workspaces);
    const [roomDetailData, setRoomDetailData] = React.useState([]);

    console.log("SRTAE",rooms)
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenParticipant, setIsOpenParticipant] = useState(false);
    const [openRoom, setOpenRoom] = useState(0);
    const [participantsData,setparticipantsData] = useState([{
        title: "Participants",
        entries: [],
    },
        {
            title: "Blocked",
            entries: [],
        },
        {
            title: "Reported",
            entries: [],
        },
        {
            title: "Pending",
            entries: [],
        },])



    const remove_from_participants = (current_participants,title,entry) => {
        const idx = participantsData.findIndex(i=>i.title===title)
        const elem = participantsData[idx]
        const nelem = elem.entries.filter(i=>i !== entry)
        current_participants[idx] = {
            title:title,
            entries : nelem
        }
        return current_participants
    }

    const append_to_participants = (current_participants,title,entry) => {
        const idx = participantsData.findIndex(i=>i.title===title)
        const elem = participantsData[idx]
        const nelem = elem.entries.concat(entry)
        current_participants[idx] = {
            title:title,
            entries:nelem
        }
        return current_participants
    }

    const set_to_participants = (current_participants,title,entry) => {
        const idx = participantsData.findIndex(i=>i.title===title)
        // const elem = participantsData[idx]
        // const nelem = elem.entries.push(entry)
        current_participants[idx] = {
            title:title,
            entries:entry
        }
        return current_participants
    }

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    // const getRoomDataForRoomId = (id) => {
    //     // console.log("ROOM ID CALLED WITH ",id)
    //     return roomDetailData.find((i) => i.id === id);
    // };
    //
    // const isParticipantOfRoom = (username, roomid) => {
    //     const data = getRoomDataForRoomId(roomid);
    //     if (data && data.participants_list && data.participants_list.length > 0) {
    //         for (let i = 0; i < data.participants_list.length; i++) {
    //             if (data.participants_list[i] === username) return true;
    //         }
    //     }
    //     return false;
    // };

    // useEffect(()=>{
    //     // if(room){
    //         axios.get(NEW_API_URL+"community/get_room/"+currentWorkspace.workspaceId+"/"+1+"/").then(r=>{
    //             console.log(r.data)
    //             // setBlocklist(r.data.room.block_list||[])
    //             // setReportlist(r.data.room.report_list||[])
    //             // setPendinglist(r.data.room.pending_list||[])
    //         }).catch(err=>{
    //             console.log(err)
    //         })
    //     // }
    // },[])

    return (
        <Box
            width={{ base: "100%", xl: "80%" }}
            minWidth={{ base: "100%", xl: "80%" }}
            maxWidth={{ base: "100%", xl: "80%" }}
            minHeight={'100vh'}
        >


            <Card cursor="pointer" style={{margin:20}}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:10}}>
                    <strong style={{margin:0,padding:0,paddingTop:10,}}>
                        General Knowledge
                        <br/>
                        Topic : World capital</strong>
                    <Pmd style={{backgroundColor:'green',borderRadius:'50%',padding:5,color:'white'}}>56</Pmd>
                </div>


                <CardBody>
                    <Table variant="simple">
                        <Tr>
                            <Th>Question</Th>
                            <Th>Answer</Th>
                        </Tr>
                        {subjects.map((subject, index) => (
                            <Tr key={index}>
                                <Td>{subject.subject}</Td>
                                <Td>{subject.score}</Td>
                            </Tr>
                        ))}
                    </Table>
                </CardBody>
            </Card>


        </Box>

    );
}

export default RecentMCQ;
