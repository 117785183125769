const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getEventTimeDetails = (timestamp) => {
  const timeStamp = new Date(timestamp);

  let month = monthNames[timeStamp.getMonth()];
  let date = timeStamp.getDate();
  let hours = timeStamp.getHours();
  let minutes = timeStamp.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  if (hours > 12) {
    hours -= 12;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  let TimeFormate = `${hours}:${minutes} ${ampm}`;
  let MonthDateFormat = `${date} ${month}`;

  return {
    month,
    date,
    TimeFormate,
    MonthDateFormat,
  };
};

export default getEventTimeDetails;
