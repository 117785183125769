import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Image, Spinner, SimpleGrid } from "@chakra-ui/react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import COLORS from "../../../../Components/colors";
import Psm from "../../../../Components/Typography/Text/Psm";
import H1 from "../../../../Components/Typography/Heading/H1";
import H2 from "../../../../Components/Typography/Heading/H2";
import axios from "axios";
import { NEW_API_URL } from "../../../../redux/types";
import { datadogLogs } from '@datadog/browser-logs';
import { Link } from 'react-router-dom';

let t1 =  Math.floor(Date.now());

export default function Books() {
  const location = useLocation();
  const item = location.state.item;

  const [data, setData] = useState([]);
  const [state, setState] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClassIndex, setSelectedClassIndex] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${NEW_API_URL}/${item.api_path}`);

      setData(response.data.book);

    } catch (error) {
      console.error(error);
      alert("Couldn't load data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedSubjectClass = (selectedClass) => {
    const mapped = [];
    selectedClass.Books.map((chapter) => {
      const index = mapped.findIndex(item => item.class === chapter.name);
      if (index === -1) {
        mapped.push({class: chapter.name, chapters: [chapter]})
      } else {
        mapped[index].chapters.push(chapter);
      }
    });
    setSelectedData(mapped)
    console.log("deta", mapped);
  }

  if (state === 0) {
    return (
      <Box w={"100%"} backgroundColor={"claydeck.white.primary"}>
        <H1 margin={4} marginTop={8} marginBottom={3}>
          Subjects
        </H1>
        {isLoading ? (
          <Spinner size="md" color="claydeck.brand.secondary" />
        ) : (
          <Flex flexWrap="wrap" gap={4} p={4}>
            {data.map((item, index) => (
              <Box
                key={index}
                onClick={() => {
                  handleSelectedSubjectClass(item);
                  setState(1);
                  datadogLogs.logger.info('Library', {name: 'Subject reading accessed', id: 123, responseTime: t1});
                }}
                backgroundColor={COLORS.lightPink}
                borderRadius={4}
                paddingY={3}
                paddingX={4}
                cursor={"pointer"}
              >
                <H2 color={COLORS.darkPurple} marginBottom={"0"}>
                  {item.subjectName}
                </H2>
              </Box>
            ))}
          </Flex>
        )}
      </Box>
    );
  }

  if (state===1) {
    return (
      <Box w={"100%"} background={"claydeck.white.primary"} p={6}>
        <Flex gap={4} alignItems="center" marginTop={2} marginBottom={6}>
          <AiOutlineArrowLeft
            className="cursor"
            onClick={() => {
              setState(0);
              setSelectedData({});
              setSelectedClassIndex(null);
            }}
          />
          <H1 marginBottom={"0px"}>Books - {selectedData.subjectName}</H1>
        </Flex>
        <SimpleGrid spacing={8} minChildWidth={"125px"}>
          {selectedData.map((item, index) => (
            <Box
              key={index}
              backgroundColor={COLORS.lightPink}
              borderRadius={4}
              paddingY={3}
              paddingX={4}
              cursor={"pointer"}
              onClick={() => {
                setState(2);
                setSelectedClassIndex(index)
              }}
            >
              <Flex justify={"center"}>
                {/* <Image src={item.book_image} alt={item.book_name} /> */}
                <Psm
                  color={COLORS.darkPurple}
                  fontWeight={"500"}
                  marginBottom={"0px"}
                >
                  {item.class}
                </Psm>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    );
  }

  return (
    <Box w={"100%"} background={"claydeck.white.primary"} p={6}>
      <Flex gap={4} alignItems="center" marginTop={2} marginBottom={6}>
        <AiOutlineArrowLeft
          className="cursor"
          onClick={() => {
            setState(1);
          }}
        />
        <H1 marginBottom={"0px"}>
          Books - {selectedData[selectedClassIndex].class}
        </H1>
      </Flex>
      <SimpleGrid spacing={8} minChildWidth={"100px"}>
        {selectedData[selectedClassIndex].chapters.map((item, index) => (
          <Box
            key={index}
            backgroundColor={COLORS.lightPink}
            borderRadius={4}
            paddingY={3}
            paddingX={4}
          >
            <Link to={item.link} isExternal>
              <Flex>
                {/* <Image src={item.book_image} alt={item.book_name} /> */}
                <Psm
                  color={COLORS.darkPurple}
                  fontWeight={"500"}
                  marginBottom={"0px"}
                >
                  {item.chapter}
                </Psm>
              </Flex>
            </Link>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
}
