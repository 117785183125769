import React, { useState } from "react";
import { Box, Button, Collapse, Image, Spacer, Text } from "@chakra-ui/react";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { MaterialData, joinmemberData } from "../../../data/data";

import MessageModalBox from "./msgModalBox";
import MaterialBox from "./materialBox";
import ParticipantMembers from "./participantMembers";

const ParticipantSidebar = () => {
  const [msgModal, setMsgModal] = useState(false);

  const handleMsgModal = () => {
    setMsgModal(!msgModal);
  };

  return (
    <Box
      minW="400px"
      position="relative"
      display="flex"
      alignItems="center"
      flexDirection="column"
      top="0px"
      gap={"4"}
      right="0px"
      zIndex="20"
      paddingY="27px"
      paddingX="36px"
      shadow="lg"
      backgroundColor="white"
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        p="1"
        gap="4"
      >
        <Text fontSize={{ lg: "18px", base: "15px" }} fontWeight="semibold">
          Have anything to say?
        </Text>
        <Button
          onClick={handleMsgModal}
          fontSize={{ lg: "14px", base: "12px" }}
          paddingX={{ lg: "19px", base: "16px" }}
          paddingY={{ lg: "10px", base: "8px" }}
          fontWeight="500"
          maxWidth="auto"
          gap="2"
          backgroundColor="white"
          border="1.25px solid rgba(33, 0, 93, 1)"
          color="rgba(33, 0, 93, 1)"
        >
          <Image src="assets/icons/pencil.svg" />
          Write a Message
          <ArrowDownIcon
            transition={"0.25s ease-in-out"}
            transform={msgModal && "rotate(180deg)"}
          />
        </Button>
        <Collapse in={msgModal} animateOpacity>
          <MessageModalBox />
        </Collapse>
      </Box>
      <Box alignSelf={"start"} p="1" gap="4" w={"full"} marginBottom={"12px"}>
        <Text
          fontSize={"16px"}
          fontWeight={"500"}
          color={"rgba(74, 74, 74, 1)"}
          marginBottom={"8px"}
        >
          Materials & Question papers
        </Text>
        <MaterialBox data={MaterialData} />
      </Box>
      <Box display={"flex"} w={"full"} flexDirection={"column"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          as="div"
          w={"full"}
          marginBottom={"5px"}
        >
          <Text
            fontSize={"16px"}
            fontWeight={"500"}
            color={"rgba(74, 74, 74, 1)"}
            marginBottom={"8px"}
          >
            Participants ({joinmemberData.length})
          </Text>
          <Image src="assets/icons/plusSign.svg" />
        </Box>
        <ParticipantMembers data={joinmemberData} />
      </Box>
    </Box>
  );
};

export default ParticipantSidebar;
