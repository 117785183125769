const Comment = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        strokeWidth="0.5"
        stroke="black"
        width="20"
        height="20"
        viewBox="0 0 50 50"
      >
        <path d="M 25 3.0703125 C 12.415167 3.0703125 2.0703125 11.925365 2.0703125 23 C 2.0703125 29.427318 5.6219606 35.086611 11 38.712891 C 10.9916 38.928457 11.008101 39.280691 10.726562 40.324219 C 10.377821 41.617047 9.6731852 43.431687 8.2402344 45.464844 A 0.92959293 0.92959293 0 0 0 8.9960938 46.929688 C 15.173361 46.956575 18.748849 42.904801 19.304688 42.251953 C 21.133469 42.659115 23.028437 42.929687 25 42.929688 C 37.583869 42.929688 47.929688 34.074694 47.929688 23 C 47.929688 11.925306 37.583869 3.0703125 25 3.0703125 z M 25 4.9296875 C 36.716131 4.9296875 46.070312 13.088694 46.070312 23 C 46.070312 32.911306 36.716131 41.070312 25 41.070312 C 22.998026 41.070312 21.063469 40.831229 19.230469 40.384766 A 0.92959293 0.92959293 0 0 0 18.285156 40.705078 C 18.285156 40.705078 15.394898 43.984833 10.787109 44.759766 C 11.62014 43.264901 12.235187 41.869931 12.521484 40.808594 C 12.920492 39.329422 12.925781 38.326172 12.925781 38.326172 A 0.92959293 0.92959293 0 0 0 12.494141 37.541016 C 7.2672049 34.227589 3.9296875 28.943265 3.9296875 23 C 3.9296875 13.088635 13.282833 4.9296875 25 4.9296875 z M 14 16 A 1.0001 1.0001 0 1 0 14 18 L 36 18 A 1.0001 1.0001 0 1 0 36 16 L 14 16 z M 10 22 A 1.0001 1.0001 0 1 0 10 24 L 27 24 A 1.0001 1.0001 0 1 0 27 22 L 10 22 z M 33 22 A 1.0001 1.0001 0 1 0 33 24 L 40 24 A 1.0001 1.0001 0 1 0 40 22 L 33 22 z M 14 28 A 1.0001 1.0001 0 1 0 14 30 L 36 30 A 1.0001 1.0001 0 1 0 36 28 L 14 28 z"></path>
      </svg>
    </div>
  );
};

export default Comment;
