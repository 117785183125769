// User reducer types
export const SET_NEW_USER = "SET_NEW_USER";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_REMEMBER_ME = "SET_REMEMBER_ME";
export const SET_USER = "SET_USER";
export const SET_USERNAME = "SET_USERNAME";
export const LOADING_USER = "LOADING_USER";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";
export const SET_GROUPS = "SET_GROUPS";
export const SET_KEY = "SET_KEY";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_ROOMS = "SET_ROOMS";
export const JOIN_ROOM = "JOIN_ROOM";
export const ADD_WORKSPACE = "ADD_WORKSPACE";
export const ADD_WORKSPACES = "ADD_WORKSPACES";
export const SET_WORKSPACE = "SET_WORKSPACE";
export const LEAVE_WORKSPACE = "LEAVE_WORKSPACE";
export const ADD_FEED = "ADD_FEED";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const LOGIN_LOADING_UI = "LOGIN_LOADING_UI"
export const FAILED_SIGNIN = "FAILED_SIGNIN"

export const START_SIGNUP_LOADING_UI = "START_SIGNUP_LOADING_UI";
export const STOP_SIGNUP_LOADING_UI = "STOP_SIGNUP_LOADING_UI"
export const STOP_LOGIN_LOADING_UI = "STOP_LOGIN_LOADING_UI"
// Data reducer types
export const SET_SCREAMS = "SET_SCREAMS";
export const SET_SCREAM = "SET_SCREAM";
export const LIKE_SCREAM = "LIKE_SCREAM";
export const UNLIKE_SCREAM = "UNLIKE_SCREAM";
export const DELETE_SCREAM = "DELETE_SCREAM";
export const POST_SCREAM = "POST_SCREAM";
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
// settings
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_TASK_SOUND = "SET_TASK_SOUND";
// profile
export const SET_PROFILE = "SET_PROFILE";
// forums
export const SET_FORUM_DATA = "SET_FORUM_DATA";
export const SET_FORUM_ACTIVETAGS = "SET_FORUM_ACTIVETAGS";
export const RESET_DETAILED_TEXT = "RESET_DETAILED_TEXT";
export const SET_DETAILED_TEXT = "SET_DETAILED_TEXT";
// subjective quize questions
export const SUBJECTIVE_QUESTIONS = "SUBJECTIVE_QUESTIONS";
// ai search redux
export const ALL_CHATS = "ALL_CHATS";
export const NEW_SEARCHQUERY = "NEW_SEARCHQUERY";

// Backend URL
// export const API_URL = 'https://www.coslabs.org';
export const API_URL =
  "https://30k95rg5ai.execute-api.ap-south-1.amazonaws.com/dev";
// export const API_WS_URL = 'wss://www.coslabs.org';
export const API_WS_URL =
  "wss://t1jak4dcsl.execute-api.ap-south-1.amazonaws.com/dev";
// export const API_WS_URL = 'wss://www.coslabs.org';
//https://53qzr0d6a3.execute-api.ap-south-1.amazonaws.com/dev/ping ${API_WS_URL}/?RoomID=${roomID}
// export const API_URL = 'http://127.0.0.1:8000';
// export const API_WS_URL = 'ws://127.0.0.1:8000';
export const HUBSPOT_REGISTER_URL =
  "https://api.hubapi.com/contacts/v1/contact/";
//https://raw.githubusercontent.com/janishar/mit-deep-learning-book-pdf/master/chapter-wise-pdf/%5B11%5Dpart-2-chapter-6.pdf

// export const NEW_API_URL =
//   "http://43.204.18.2:8000/";
// export const NEW_API_URL =
//   "http://13.201.21.30:8000/";
// export const NEW_API_URL =
//   "http://13.233.244.32:8000/";
// export const NEW_API_URL =
//   "http://3.108.56.132:8000/";
export const NEW_API_URL =
  "https://www.coslabs.org/";
export const API_URL_STAGING =
  "https://kk2z2518c6.execute-api.ap-south-1.amazonaws.com/staging";
