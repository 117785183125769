import {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

import { useGoogleLogin } from "react-google-login";

import {
  Box,
  Grid,
  GridItem,
  Checkbox,
  Image,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure, Center, Text, Spinner,
} from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import H2 from "../../Components/Typography/Heading/H2";
import InputOutline from "../../Components/Input/InputOutline";
import InputPassword from "../../Components/Input/InputPassword";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";

import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {API_URL_STAGING, NEW_API_URL} from "../../redux/types";
import {loginUser, signupUser} from "../../redux/actions/userActions";
import { datadogLogs } from '@datadog/browser-logs';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Plg from "../../Components/Typography/Text/Plg";
import Psm from "../../Components/Typography/Text/Psm";
import ButtonSocial from "../../Components/Button/ButtonSocial";
import Pxl from "../../Components/Typography/Text/Pxl";
let t1 =  Math.floor(Date.now());

const clientId =
  "105604948407-qgjbl3k27icekteikgjq56tehc4tlnuc.apps.googleusercontent.com";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();




  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordLoading, setResetpasswordLoading] = useState(false);
  const [showForgotState, setShowForgotState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const loginLoading = useSelector(state => state.UI.failedSignin);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(()=>{
    if(loginLoading){
      setErr('Unable to login with the provided email/password')
    }

  },[loginLoading])

  const onSuccess = (res) => {
    console.log("Login Success: currentUser:", res.profileObj);
    const prf = res.profileObj;

    dispatch(loginUser({
      email:prf.email,
      password:prf.email+''+prf.googleId,
      username:prf.email,
      isGoogle:true,
      prf:prf
    }, rememberMe, navigate));

    // const userData = {
    //   first_name: '',
    //   last_name: '',
    //   username: email,
    //   email: email,
    //   password: prf.email+''+prf.googleId,
    // };
    //
    // dispatch(signupUser(userData, rememberMe, navigate));

    setIsSigningIn(false);

  };

  const onFailure = (res) => {
    console.log("Login failed res:", res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: "offline",
  });

  const handleSubmit = () => {
    setIsSigningIn(true);
    const userData = {
      username: email,
      password: password,
    };

    datadogLogs.logger.info('Signin', {name: 'Signin button clicked', id: 123, responseTime: t1});
    // console.log("BSIGN",userData,rememberMe,navigate)
    dispatch(loginUser(userData, rememberMe, navigate));
    setIsSigningIn(false);
  };

  // reset password functions
  const closeModal = () => {
    onClose();
    setResetPasswordEmail("");
  };

  const submitResetPasswordEmail = () => {
    setResetpasswordLoading(true);

    // email validation
    const regexEmail =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmail = regexEmail.test(resetPasswordEmail);
    if (!testEmail) {
      alert("Please enter a valid email address.");
      return;
    }

    const url = `${API_URL_STAGING}user/send-reset-password-email/`;
    const body = {
      email: email,
    };
    axios
      .post(url, body)
      .then((res) => {
        console.log("email reset res", res.data);
        alert("Email sent successfully! Please check your mail.");
        setResetpasswordLoading(false);
        closeModal();
      })
      .catch((error) => {
        console.log("email reset error", error);
        alert("An error occured, please try again.");
        setResetpasswordLoading(false);
      });
  };

  return (
    <Grid
      columns={{ base: 1, lg: 2 }}
      templateColumns={{ base: "1fr", lg: "2fr 1.5fr" }}
    >
      <GridItem
        w="100%"
        h="100vh"
        display={{ base: "flex", lg: "flex" }}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >

        <Image
            height={"80px"}
            objectFit={"contain"}
            src={"claydeck_logo_full.png"}
            alt={"site-logo"}
            // alignSelf={"flex-start"}
        />

        <Carousel autoPlay interval={4000} infiniteLoop showArrows={false} showStatus={false} showThumbs={false}
                  renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const indicatorStyle = {
                      // width: isSelected?12:6,
                      // height: isSelected?12:6,
                      width: 12,
                      height: 12,
                      borderRadius: '50%',
                      backgroundColor: isSelected ? 'black' : 'rgba(0, 0, 0, 0.3)', // Black color for selected, lighter color for unselected
                      display: 'inline-block',
                      margin: '8px',
                      // marginTop:isSelected?0:-5,
                      cursor: 'pointer',
                    };
                    return (
                        <li key={index} style={indicatorStyle} onClick={onClickHandler} title={label} />
                    );
                  }}
        >

          <Box height="80vh">
            <Center height="80%">
              <img src={"/intro-s4.png"} alt={`Slide`} style={{ maxHeight: '80%', maxWidth: '40%' }} />
            </Center>
            <Plg textAlign="center" mt={4}>
              Get daily task
              recommendations and
              <br/>
              complete milestones to
              track your progress
            </Plg>
          </Box>
          <Box height="80vh">
            <Center height="80%">
              <img src={"/intro-s1.png"} alt={`Slide`} style={{ maxHeight: '80%', maxWidth: '40%' }} />
            </Center>
            <Plg textAlign="center" mt={4}>
              An app that adapts
              to your learning <br/>style
              and progress
            </Plg>
          </Box>
          <Box height="80vh">
            <Center height="80%">
              <img src={"/intro-s2.png"} alt={`Slide`} style={{ maxHeight: '80%', maxWidth: '40%' }} />
            </Center>
            <Plg textAlign="center" mt={4}>
              Learn with interactive
              notes and correlate <br/>
              topics with visual timelines
            </Plg>
          </Box>
          <Box height="80vh">
            <Center height="80%">
              <img src={"/intro-s3.png"} alt={`Slide`} style={{ maxHeight: '80%', maxWidth: '40%' }} />
            </Center>
            <Plg textAlign="center" mt={4}>
              Stay aware of
              most important current <br/>affairs
              with Claydeck’s AI priority
            </Plg>
          </Box>
          {/*<div>*/}
          {/*  <img src="/intro-s1.png" height={'200px'}/>*/}
          {/*  <p className="legend">Legend 2</p>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <img src="/intro-s2.png" height={'200px'}/>*/}
          {/*  <p className="legend">Legend 3</p>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <img src="/intro-s3.png" height={'200px'}/>*/}
          {/*  <p className="legend">Legend 3</p>*/}
          {/*</div>*/}
        </Carousel>


        {/*<Image*/}
        {/*  src={"./assets/images/mockup-with-bg.png"}*/}
        {/*  objectFit={"contain"}*/}
        {/*  alt={"mobile-app-mockup"}*/}
        {/*  width={"100%"}*/}
        {/*  maxHeight={"770px"}*/}
        {/*/>*/}
      </GridItem>
      <GridItem
        w="100%"
        h="100vh"
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        bgColor={"claydeck.brand.secondary"}

      >
        <Box
          height={"100%"}
          width={{ base: "100%", lg: "50%" }}
          maxWidth={"620px"}
          display={"flex"}
          gap={{ base: "32px", md: "0px" }}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
          padding={{ base: "16px", md: "32px", lg: "0px" }}
        >
          {showForgotState === 0 && <Box>
            <Pxl color={'white'}  mt={5} textAlign={'left'} fontWeight={'400'}>Login</Pxl>

            {/* <ButtonSecondary width={"150px"}>Facebook</ButtonSecondary> */}
            <Grid gap={"32px"}>
              <GridItem>
                <Pmd
                  htmlFor="email"
                  color={"red"}
                  mb={"16px"}
                >
                  {err && err}
                </Pmd>
                <InputOutline
                    type="email"
                    name="email"
                    placeholder={"Username"}
                    value={email}
                    onChange={(event) => setEmail(event.currentTarget.value)}
                />
              </GridItem>
              <GridItem>
                {/*<Pmd*/}
                {/*  htmlFor="password"*/}
                {/*  color={"claydeck.text.tertiary"}*/}
                {/*  mb={"16px"}*/}
                {/*>*/}
                {/*  Password*/}
                {/*</Pmd>*/}
                <InputPassword
                    value={password}
                    onChange={(event) => setPassword(event.currentTarget.value)}
                />
              </GridItem>
            </Grid>
            <Psm color={'white'}  mt={5} textAlign={'right'} fontWeight={'400'}><span style={{cursor:'pointer'}} onClick={()=>{setShowForgotState(1)}}>Forgot Password?</span></Psm>
            <ButtonPrimary
                width={"100%"}
                // minWidth={"250px"}
                // maxWidth={"400px"}
                // height={"56px"}
                color={"white"}
                bg={"claydeck.brand.primary"}
                // borderRadius={"10px"}
                marginBottom={0}
                isSigningIn={isSigningIn}
                loadingText={"Signing In"}
                isDisabled={isSigningIn}
                onClick={() => handleSubmit()}
            >
              {isSigningIn ? <Spinner/>:<>Sign In</>}
            </ButtonPrimary>

            <Flex alignItems={"center"} justifyContent={"center"} marginTop={3}>
              <hr style={{width: '40%', border: '1px solid #838383'}}/>
              <Psm margin={0} color={'white'} marginLeft={'20px'} marginRight={'20px'}>Or</Psm>
              <hr style={{width: '40%', border: '1px solid #838383'}}/>

            </Flex>

            {/*<Flex*/}
            {/*  direction={{ base: "column", md: "row" }}*/}
            {/*  gap={{ base: "12px", md: "0px" }}*/}
            {/*  justify={"space-between"}*/}
            {/*  alignItems={{ base: "flex-start", md: "center" }}*/}
            {/*  marginTop={"18px"}*/}
            {/*>*/}
            {/*  <Checkbox*/}
            {/*    isChecked={rememberMe}*/}
            {/*    onChange={(e) => setRememberMe(e.target.checked)}*/}
            {/*  >*/}
            {/*    <H2>Remember Me</H2>*/}
            {/*  </Checkbox>*/}
            {/*  <ButtonPrimary onClick={onOpen}>Forgot Password?</ButtonPrimary>*/}
            {/*</Flex>*/}

            <ButtonSocial width={'100%'} mt={3} onClick={()=>{
              signIn()
            }}>
              <Flex alignItems={"center"} justifyContent={"space-around"} w={'100%'}>
                <Image
                    height={"20px"}
                    objectFit={"contain"}
                    src={"google.png"}
                    alt={"site-logo"}
                    // alignSelf={"flex-start"}
                />
                Continue with Google
                <Image
                    height={"10px"}
                    objectFit={"contain"}
                    src={"right-arrow.png"}
                    alt={"site-logo"}
                    // alignSelf={"flex-start"}
                />
              </Flex>
            </ButtonSocial>


          </Box>}
          {showForgotState === 1 && <Box>
            <Pxl color={'white'}  mt={5} mb={0} textAlign={'left'} fontWeight={'400'}>Forgot Password</Pxl>
            <Psm color={'#CCCCCC'} mt={0}>Enter the email address you used to create the account, and we will email you instructions to reset the password</Psm>

            {/* <ButtonSecondary width={"150px"}>Facebook</ButtonSecondary> */}
            <Grid gap={"32px"}>
              <GridItem>
                <Pmd
                  htmlFor="email"
                  color={"white"}
                  mb={"16px"}
                >
                  Email Address
                </Pmd>
                <InputOutline
                    type="email"
                    name="email"
                    placeholder={"Username"}
                    value={email}
                    onChange={(event) => setEmail(event.currentTarget.value)}
                />
              </GridItem>

            </Grid>
            <ButtonPrimary
                width={"100%"}
                mt={'20px'}
                // minWidth={"250px"}
                // maxWidth={"400px"}
                // height={"56px"}
                color={"white"}
                isDisabled={isLoading}
                bg={"claydeck.brand.primary"}
                // borderRadius={"10px"}
                marginBottom={0}
                isSigningIn={isSigningIn}
                loadingText={"Signing In"}
                onClick={() => {

                  const url = `${NEW_API_URL}user/send-reset-password-email/`;
                  const body = {
                    username: email,
                  };
                  setIsLoading(true)

                  axios
                      .post(url, body)
                      .then((res) => {
                        console.log("email reset res", res.data);
                        // Alert.alert("Email sent successfully! Please check your mail.");
                        setIsLoading(false);
                        // handleCloseModal();
                        setShowForgotState(2)

                      })
                      .catch((error) => {
                        console.log("email reset error", error);
                        // Alert.alert("An error occured, please try again.");
                        setIsLoading(false);
                      });


                }}
            >
              Send Email
            </ButtonPrimary>

            <Pxl color={'white'}  mt={5} mb={0} textAlign={'left'} fontWeight={'400'}>Remember Password ? <span style={{color:'#75BFF5',cursor:'pointer'}} onClick={()=>{setShowForgotState(0)}}>Login</span></Pxl>



          </Box>}

          {showForgotState === 2 && <Box>
            <Pxl color={'white'}  mt={5} mb={0} textAlign={'left'} fontWeight={'400'}>Email Sent</Pxl>
            <Psm color={'#CCCCCC'} mt={0}>We have sent you email at <strong>example@email.com</strong>. check your inbox and follow the instructions to reset the password</Psm>

            {/* <ButtonSecondary width={"150px"}>Facebook</ButtonSecondary> */}

            <ButtonPrimary
                width={"100%"}
                mt={'20px'}
                // minWidth={"250px"}
                // maxWidth={"400px"}
                // height={"56px"}
                color={"white"}
                bg={"claydeck.brand.primary"}
                // borderRadius={"10px"}
                marginBottom={0}
                isSigningIn={isSigningIn}
                loadingText={"Signing In"}
                onClick={() => setShowForgotState(3)}
            >
              Next (for testing only)
            </ButtonPrimary>

            <Pmd cursor={'pointer'} color={'white'}  mt={5} mb={0} textAlign={'left'} fontWeight={'400'}>Didn’t receive the mail?  <span style={{color:'#75BFF5'}}>Resend</span></Pmd>
            <Pmd cursor={'pointer'} color={'white'}  mt={5} mb={0} textAlign={'left'} fontWeight={'400'} onClick={()=>{setShowForgotState(1)}}>Wrong Email Address?  <span style={{color:'#75BFF5'}}>Change Email Address</span></Pmd>



          </Box>}
          {showForgotState === 3 && <Box>
            <Pxl color={'white'}  mt={5} mb={0} textAlign={'left'} fontWeight={'400'}>Create New Password</Pxl>
            <Psm color={'#CCCCCC'} mt={0}>Your new password must follow the password policy</Psm>

            {/* <ButtonSecondary width={"150px"}>Facebook</ButtonSecondary> */}


            <Grid gap={"32px"}>
              <GridItem>
                <Pmd
                    htmlFor="email"
                    color={"white"}
                    mb={"16px"}
                >
                  New Password
                </Pmd>
                <InputOutline
                    type="password"
                    name="password"
                    placeholder={"password"}
                    value={email}
                    onChange={(event) => setEmail(event.currentTarget.value)}
                />
              </GridItem>
              <GridItem>
                <Pmd
                    htmlFor="password"
                    color={"white"}
                    mb={"16px"}
                >
                  Confirm Password
                </Pmd>
                <InputOutline
                    type="password"
                    name="email"
                    placeholder={"password"}
                    value={password}
                    onChange={(event) => setEmail(event.currentTarget.value)}
                />
              </GridItem>

            </Grid>

            <ButtonPrimary
                width={"100%"}
                mt={'20px'}
                // minWidth={"250px"}
                // maxWidth={"400px"}
                // height={"56px"}
                color={"white"}
                bg={"claydeck.brand.primary"}
                // borderRadius={"10px"}
                marginBottom={0}
                isSigningIn={isSigningIn}
                loadingText={"Signing In"}
                onClick={() => setShowForgotState(3)}
            >
              Reset Password
            </ButtonPrimary>



          </Box>}

        </Box>
      </GridItem>
      <Modal isCentered isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Password</ModalHeader>
          <ModalBody>
            <Pmd
              color={"claydeck.text.primary"}
              letterSpacing={"0.2px"}
              mb={"16px"}
            >
              You will recieve a password reset link on the email you enter
              below
            </Pmd>
            <InputOutline
              type="email"
              name="reset-password-email"
              placeholder={"example@email.com"}
              value={resetPasswordEmail}
              onChange={(event) =>
                setResetPasswordEmail(event.currentTarget.value)
              }
            />
          </ModalBody>
          <ModalFooter>
            <ButtonPrimary colorScheme="blue" mr={3} onClick={closeModal}>
              Close
            </ButtonPrimary>
            <ButtonSecondary variant="ghost" onClick={submitResetPasswordEmail}>
              Send Email
            </ButtonSecondary>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Grid>
  );
};

export default SignIn;
