import axios from "axios";
import { useState } from "react";

const useForumPostData = () => {
  const [error, setError] = useState(null);

  const postForumData = async (body, ws_id) => {
    try {
      setError(null);

      const response = await axios.post(
        `https://kk2z2518c6.execute-api.ap-south-1.amazonaws.com/staging/forum/create_forum_post/${ws_id}/`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("post error:", error);

      setError("An error occurred while posting.");
      throw error;
    }
  };

  return { postForumData, error };
};

export default useForumPostData;
