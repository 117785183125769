import React, { useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  Button, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner, useDisclosure
} from "@chakra-ui/react";

import HeaderLayout from "./Elements/HeaderLayout";

import DesignSheet from "./Pages/DesignSheet";
import LandingPage from "./Pages/Landing/LandingPage";
import SignUp from "./Pages/auth/SignUp";
import SignIn from "./Pages/auth/SignIn";
import PasswordReset from "./Pages/auth/PasswordReset";
import Plans from "./Pages/plans/Plans";
import JoinWorkspace from "./Pages/workspaces/JoinWorkspace";
import SurveryQuestions from "./Pages/survery/SurveyQuestions";
import JoinFeed from "./Pages/feeds/JoinFeed";
import TermAndConditions from "./Pages/Landing/TermAndConditions";
import Feed from "./Pages/Feed/Feed";
import Library from "./Pages/library/Library";
import Books from "./Pages/library/StudyResourceComps/components/Books";
import Notes from "./Pages/library/StudyResourceComps/components/Notes";
import MustReads from "./Pages/library/StudyResourceComps/components/MustReads";
import SavedFeeds from "./Pages/library/StudyResourceComps/components/SavedFeeds";
import MockPaperSubjects from "./Pages/library/ExamPracticeMAterialComps/components/MockPaperSubjects";
import MockPaperChapters from "./Pages/library/ExamPracticeMAterialComps/components/MockPaperChapters";
import MokePaperTestPage from "./Pages/library/ExamPracticeMAterialComps/components/MokePaperTestPage";
import SubjectiveTestComp from "./Pages/library/ExamPracticeMAterialComps/components/SubjectivTestPage";
import PracticeQuizeSubjects from "./Pages/library/ExamPracticeMAterialComps/components/PracticePaperSubjects";
import Home from "./Pages/home/Home";
import Tasks from "./Pages/home/Tasks";
import LessonPlan from "./Pages/home/LessonPlan";
import LessonPlanDetails from "./Pages/home/LessonPlanDetails";
import VideoCallNGComponent from "./Components/VideoCallNGComponent";
import MentorWorkspacePage from "./Pages/mentor-workspace/mentor-workspace";
import Formus from "./Pages/forums/forums";
import Compose from "./Pages/compose/compose";
import DetailedForumPage from "./Pages/forums/detailedForumpage";
import SearchedDataPage from "./Pages/search/search";

import { useSelector, useDispatch } from "react-redux";
import VideoCallRTM from "./Components/VideoCallRTM";
import { useEffect } from "react";
import Room from "./Pages/Room";

import { eventData, roomData } from "./data/data";
import SubQuize from "./Pages/Landing/components/Subjective-Quiz";
import NewsQuiz from "./Pages/Landing/components/News-Quiz";
import SubjectiveTest from "./Pages/library/ExamPracticeMAterialComps/components/SubjectiveTest";
import PracticePaperChapters from "./Pages/library/ExamPracticeMAterialComps/components/PracticePaperChapters";
import PracticePaperTestPage from "./Pages/library/ExamPracticeMAterialComps/components/PracticePaperTestPage";
import MapView from "./Pages/library/MapView";
import Books2 from "./Pages/library/StudyResourceComps/components/Books2";
import NotesDetail from "./Pages/library/StudyResourceComps/components/NotesDetail";
import Profile from "./Pages/Profile";
import Stats from "./Pages/Stats";
import RecentMCQ from "./Pages/RecentMCQ";
import RecentSub from "./Pages/RecentSub";
import Home2 from "./Pages/home/Home2";
import Tests from "./Pages/home/Tests";
import TopicDetail from "./Pages/home/TopicDetail";
import TopicNote from "./Pages/home/TopicNote";
import NotesReading from "./Pages/home/NotesReading";
import News from "./Pages/home/News";
import Readings from "./Pages/home/Readings";
import NewsReading from "./Pages/home/NewsReading";
import Learn from "./Pages/home/Learn";
import TestsQuiz from "./Pages/home/TestsQuiz";
import TimelineReading from "./Pages/home/TimelineReading";
import NewsTopic from "./Pages/home/NewsTopic";
import LearnTests from "./Pages/home/LearnTests";
import ITools from "./Pages/home/Itools";
import Pmd from "./Components/Typography/Text/Pmd";
import IToolsReading from "./Pages/home/IToolsReading";
import CustomStyledModal from "./Components/Modal/CustomStyledModal";
import TopicReading from "./Pages/home/TopicReading";

const commonRoutes = [
  {
    path: "/",
    element: <LandingPage />,
  },

  {
    path: "/design",
    element: <DesignSheet />,
  },
  {
    path: "/password-reset/:uid/:token",
    element: <PasswordReset />,
  },
  {
    path: "/plans",
    element: <Plans />,
  },
  {
    path: "/join-workspace",
    element: <JoinWorkspace />,
  },
  {
    path: "/survey-questions",
    element: <SurveryQuestions />,
  },
  {
    path: "/join-feed",
    element: <JoinFeed />,
  },
  {
    path: "/feeds",
    element: <Feed />,
  },
  {
    path: "/library",
    element: <Library />,
  },
];

const authRouter = createBrowserRouter([
  ...commonRoutes,
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
]);

const router = createBrowserRouter([
  {
    path: "/sub-quiz",
    element: <SubQuize />,
  },
  {
    path: "/news-quiz",
    element: <NewsQuiz />,
  },
  {
    path: "/tandc",
    element: <TermAndConditions />,
  },
  {
    element: <HeaderLayout />,
    children: [
      ...commonRoutes,
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "/signin",
        element: <SignIn />,
      },
      {
        path: "/home",
        element: <Home2 />,
      },
      {
        path: "/learn/:subject/:area/:topic/news/:noteid",
        element: <NewsReading />,
      },
      {
        path:"/learn/:subject/:area/:topic/timeline/:noteid",
        element:<TimelineReading/>
      },
      {
        path:"/learn/:subject/:area/:topic/timeline",
        element:<TopicNote isTimeline={true}/>
      },
      {
        path: "/learn/:subject/:area/:topic/news",
        element: <NewsTopic />,
      },

      {
        path: "/learn/:subject/:area/:topic/tests/:noteid",
        element: <TestsQuiz />,
      },
      {
        path: "/learn/:subject/:area/:topic/tests",
        element: <LearnTests />,
      },
      {
        path:"/learn/:subject/:area/:topic/notes/:noteid",
        element:<NotesReading/>
      },
      {
        path:"/learn/:subject/:area/:topic/notes_reading/:indext",
        element:<NotesReading/>
      },
      {
        path:"/learn/:subject/:area/:topic/itools/:noteid",
        element:<IToolsReading/>
      },
      {
        path:"/learn/:subject/:area/:topic/readings/:noteid",
        element:<TopicReading isTimeline={false}/>
      },
      {
        path:"/learn/:subject/:area/:topic/itools",
        element:<ITools/>
      },{
        path:"/learn/:subject/:area/:topic/notes",
        element:<TopicNote isTimeline={false}/>
      },{
        path:"/learn/:subject/:area/:topic/readings",
        element:<Readings/>
      },
      {
        path:"/learn/:subject/:area/:topic",
        element:<TopicDetail/>
      },
      {
        path: "/learn",
        element: <Learn />
      },
      {
        path: "/tests",
        element: <Tests />
      },
      {
        path: "/tests/:id",
        element: <TestsQuiz />
      },
      {
        path: "/tasks",
        element: <Tasks />,
      },
      {
        path: "/news/:id",
        element: <NewsReading />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/lesson-plan",
        children: [
          {
            path: "",
            element: <LessonPlan />,
          },
          {
            path: ":id",
            element: <LessonPlanDetails />,
          },
        ],
      },
      // {
      //   path: "/video",
      //   element: <VideoCallNGComponent />,
      // },
      // {
      //   path: "/video_rtm",
      //   element: <VideoCallRTM />,
      // },
      {
        path: "/search",
        element: <SearchedDataPage />,
      },
      {
        path: "/room",
        element: <Room />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/room/:workspace/:roomid",
        element: <VideoCallNGComponent />,
      },
      {
        path: "/mentor-workspace",
        element: (
          <MentorWorkspacePage roomdata={roomData} eventdata={eventData} />
        ),
      },
      {
        path: "/feeds",
        element: <Feed />,
      },
      {
        path: "/my-stats",
        children:[
          {
            path: "",
            element: <Stats />,
          },
          {
            path: "recent-mcq",
            element: <RecentMCQ />,
          },
          {
            path: "recent-sub",
            element: <RecentSub />,
          },
        ]
      },
      {
        path: "/library",
        children: [
          {
            path: "",
            element: <Library />,
          },
          {
            path: "books",
            element: <Books2 />,
          },
          {
            path: "notes",
            element: <Notes />,
          },
          {
            path: "notes/:id",
            element: <NotesDetail />,
          },
          {
            path: "map",
            element: <MapView />,
          },
          {
            path: "must-reads",
            element: <MustReads />,
          },
          {
            path: "saved-feeds",
            element: <SavedFeeds />,
          },
          {
            path: "mock-papers",
            element: <MockPaperSubjects />,
          },
          {
            path: "mock-papers/:subject/:id",
            element: <MockPaperChapters />,
          },
          {
            path: "mock-papers/:subject/:test/:id",
            element: <MokePaperTestPage />,
          },
          {
            path: "subjective-test",
            element: <SubjectiveTestComp />,
          },
          {
            path: "subjective-test/:id",
            element: <SubjectiveTest />,
          },
          {
            path: "practice-quize",
            element: <PracticeQuizeSubjects />,
          },
          {
            path: "practice-quize/:subject/:id",
            element: <PracticePaperChapters />,
          },
          {
            path: "practice-quize/:subject/:test/:id",
            element: <PracticePaperTestPage />,
          },
        ],
      },
      {
        path: "/forums",
        children: [
          {
            path: "",
            element: <Formus />,
          },
          {
            path: "compose",
            element: <Compose />,
          },
          {
            path: ":id/detailedforum",
            element: <DetailedForumPage />,
          },
        ],
      },
    ],
  },
]);

const Routes = () => {
  const dispatch = useDispatch();
  const rememberMe = useSelector((state) => state.user.rememberMe);
  const token = useSelector((state) => state.user.accessToken);




  // const token = "a token";
  // console.log(token);

  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const username = useSelector(state => state.user.username);

  const subscriptionFound = false

  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, [token]);
  useEffect(() => {
    if(!subscriptionFound && token){
      onOpen()
    }
  }, [subscriptionFound,token]);

  return isLoading ? (
    <Spinner size={"sm"} color="claydeck.brand.primary" />
  ) : (
      <>
    <RouterProvider router={router} />

        <CustomStyledModal username={username} subscription isOpen={isOpen} onOpen={onOpen} onClose={onClose} title={'No Active Subscription found'} />


      </>
  );
};

export default Routes;
