import { Text, Box, Center, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useGetPracticeTestSubs from "../utils/getPracticeTestSubs";

const PracticeQuizeSubjects = () => {
  const { fetchPracticeTestSubs, isLoading, setIsLoading, errorsub } =
    useGetPracticeTestSubs();

  const [testSubs, setTestSubs] = useState([]);

  useEffect(() => {
    const fetchSubs = async () => {
      setIsLoading(true);
      const res = await fetchPracticeTestSubs(2, "rahulk30s@gmail.com");
      setTestSubs(res?.test);
      setIsLoading(false);
    };

    fetchSubs();
  }, []);

  const renderSubs = () => {
    return testSubs?.map((test, i) => (
      <Box
        as="a"
        key={i}
        href={`/library/practice-quize/${test?.subjectName}/${i}`}
        fontSize={"md"}
        fontWeight={"semibold"}
        boxShadow={"sm"}
        display={"block"}
        borderWidth={"2px"}
        borderRadius={"lg"}
      >
        <Text backgroundColor={"purple.200"} padding={2} margin={0}>
          {i + 1 + ". "}
          {test.subjectName}
        </Text>
      </Box>
    ));
  };

  console.log(Object.keys(errorsub).length);

  return (
    <Box
      display={"flex"}
      width={"full"}
      flexDir={"column"}
      padding={8}
      margin={8}
    >
      <Box width={"full"}>
        <Box
          backgroundColor={"purple.100"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={2}
          borderRadius={"md"}
          borderWidth={"2px"}
          borderTopColor={"purple.300"}
          boxShadow={"md"}
        >
          <Text
            fontSize={"xl"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            margin={"0"}
          >
            Practice Quize Subjects
          </Text>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        flexDir={"row"}
        gap={4}
        marginTop={8}
      >
        {isLoading ? (
          <Center ml={"455px"} mt={8}>
            <Spinner color="purple.500" />
          </Center>
        ) : Object.keys(errorsub).length === 0 ? (
          renderSubs()
        ) : (
          "subjects not found !!"
        )}
      </Box>
    </Box>
  );
};

export default PracticeQuizeSubjects;
