import React, { useState } from "react";
import {
  GridItem,
  Grid,
  Box,
  Text,
  Input,
  Heading,
  Button,
  Spinner,
} from "@chakra-ui/react";
import Nav from "../Nav";
import Pmd from "../../../Components/Typography/Text/Pmd";
import Psm from "../../../Components/Typography/Text/Psm";
import H1 from "../../../Components/Typography/Heading/H1";
import COLORS from "../../../Components/colors";
import "./../LandingPage.css";
import QuizeBox from "./NewsQuizeBox";
import { Newsdata, SubjectiveData } from "../data/data";
import SubQuizBox from "./SubQuizBox";
import { datadogLogs } from '@datadog/browser-logs';

let t1 =  Math.floor(Date.now());


const SubQuiz = () => {
  const [email, setEmail] = useState("");
  const [isQuizCompleted, setQuizCompleted] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [Submition, setSubmition] = useState({
    isSubmitted: false,
    submitedEmail: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    setTimeout(() => {
      try {
        console.log("Submitted email:", email);
        setLoading(false);
        setSubmition({ isSubmitted: true, submitedEmail: email });
        setEmail("");
      } catch (error) {
        console.log(error);
        setLoading(false);
        setEmail("");
      }
    }, 2000);
    datadogLogs.logger.info('Subjective Quiz', {name: 'Subjective Quiz email submitted', id: 123, responseTime: t1});

  };

  return (
    <Box as="div" width={"full"} minHeight={'100vh'}>
      <Nav />
      <Box
        width={"full"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={7}
        padding={"80px"}
      >
        <Heading>Start Subjective Quiz ...</Heading>
        <>
          {Submition.isSubmitted ? (
            <>
              <Text fontSize={"lg"}>
                {isQuizCompleted ? (
                  <>
                    <b>Congratulations You have completed quiz 🎉</b>
                  </>
                ) : (
                  <>
                    All the best <b>{Submition.submitedEmail}</b> for quiz 👍
                  </>
                )}
              </Text>

              <SubQuizBox
                questions={SubjectiveData}
                isQuizCompleted={isQuizCompleted}
                setQuizCompleted={setQuizCompleted}
              />
            </>
          ) : (
            <>
              <Text>To start the quiz please enter your email...</Text>
              <Input
                required
                type="email"
                placeholder="lucky777@gmail.com"
                display={"inline-block"}
                width={"40%"}
                background={"purple.50"}
                borderWidth={"1px"}
                borderColor={"purple.200"}
                _focusVisible={{
                  transform: "scale(1.03)",
                  borderColor: "purple.500",
                }}
                value={email}
                _placeholder={{
                  opacity: 0.7,
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                size="sm"
                backgroundColor={"purple.100"}
                borderWidth={"2px"}
                borderColor={"purple.200"}
                fontWeight={"bold"}
                letterSpacing={"1px"}
                _hover={{
                  backgroundColor: "purple.50",
                }}
                _active={{
                  transform: "translateY(4px)",
                }}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <Spinner size={"sm"} color="purple.500" />
                ) : (
                  "Submit"
                )}
              </Button>
            </>
          )}
        </>
      </Box>
      <Grid
        className="mt-5"
        templateAreas={{
          lg: `"logo logo product company social legal"`,
          md: `"logo logo" 
          "product company"
          "social legal"`,
          sm: `"logo" 
          "logo" 
          "product" 
          "company" 
          "social" 
          "legal"`,
        }}
        gap="1"
        style={{
          padding: 100,
          marginTop: 100,
          backgroundColor: COLORS.darkBlue,
        }}
      >
        <GridItem
          area={"logo"}
          style={{ padding: "10%", paddingLeft: "20%", paddingRight: "20%" }}
          className="d-flex justify-content-center flex-column"
        >
          <H1 color={COLORS.white}>Claydeck</H1>
          <Pmd color={COLORS.white} className="mt-4">
            Top learning experiences that create more talent in the world.
          </Pmd>
        </GridItem>
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"product"}
        >
          <Psm color={COLORS.grey} className="mb-1">
            Product
          </Psm>
          <Pmd color={COLORS.white} className="my-1">
            Overview
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Features
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Solutions
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Tutorial
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Pricing
          </Pmd>
        </GridItem>
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"company"}
        >
          <Psm color={COLORS.grey} className="mb-1">
            Company
          </Psm>
          <Pmd color={COLORS.white} className="my-1">
            About Us
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Careers
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Press
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            News
          </Pmd>
        </GridItem>
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"social"}
        >
          <Psm color={COLORS.grey} className="mb-1">
            Social
          </Psm>
          <Pmd color={COLORS.white} className="my-1">
            Twitter
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            LinkedIn
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Github
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Dribble
          </Pmd>
        </GridItem>
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"legal"}
        >
          <Psm color={COLORS.grey} className="mb-1">
            Legal
          </Psm>
          <Pmd color={COLORS.white} className="my-1">
            Terms
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Privacy
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Cookies
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Contact
          </Pmd>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default SubQuiz;
