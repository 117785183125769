import {
    Input,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftElement,
    FormErrorMessage,
    Code,
    Icon,
    InputRightElement
} from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import {useEffect, useRef, useState} from "react";
import ButtonPrimary from "./Button/ButtonPrimary";

const FileUpload = ({ name, placeholder, acceptedFileTypes, control, children, isRequired=false,style,setImageData }) => {
    const inputRef = useRef();
    const[value,setValue] = useState(undefined);

    useEffect(() => {
        if(value)
        setImageData(value);
    }, [value]);

    return (
        <FormControl style={style} isInvalid={false}>
            <FormLabel htmlFor="writeUpFile">{children}</FormLabel>
            <InputGroup>
                {/*<InputRightElement*/}
                {/*    pointerEvents="none"*/}
                {/*    children={<Icon as={FiFile} />}*/}
                {/*/>*/}
                <input multiple="multiple" onChange={(e)=>{
                    setValue(e.target.files)}
                } type='file' accept={acceptedFileTypes} name={name} ref={inputRef} style={{ display: 'none' }}></input>
                <Input
                    variant={'filled'}
                    background={'#EBEBEB'}
                    borderRadius={'12px 0px 0px 12px'}
                    placeholder={placeholder || "Upload an Image"}
                    onClick={() => inputRef.current.click()}
                    value={value}
                />
                <ButtonPrimary width={'200px'} onClick={() => inputRef.current.click()} borderRadius={'0px 12px 12px 0px'}>Choose Image</ButtonPrimary>
            </InputGroup>
        </FormControl>
    );
}

export default FileUpload;
