import { Skeleton, Box } from "@chakra-ui/react";
const EventListSkeleton = () => {
  return (
    <Box>
      <Skeleton
        opacity={"0.25"}
        height={"35px"}
        m={{ md: "2", base: "6px" }}
        w={"auto"}
        h={{ md: "50px", base: "45px" }}
      />
      <Skeleton
        opacity={"0.25"}
        height={"35px"}
        m={{ md: "2", base: "6px" }}
        w={"auto"}
        h={{ md: "50px", base: "45px" }}
      />
      <Skeleton
        opacity={"0.25"}
        height={"35px"}
        m={{ md: "2", base: "6px" }}
        w={"auto"}
        h={{ md: "50px", base: "45px" }}
      />
      <Skeleton
        opacity={"0.25"}
        height={"35px"}
        m={{ md: "2", base: "6px" }}
        w={"auto"}
        h={{ md: "50px", base: "45px" }}
      />
      <Skeleton
        opacity={"0.25"}
        height={"35px"}
        m={{ md: "2", base: "6px" }}
        w={"auto"}
        h={{ md: "50px", base: "45px" }}
      />
      <Skeleton
        opacity={"0.25"}
        height={"35px"}
        m={{ md: "2", base: "6px" }}
        w={"auto"}
        h={{ md: "50px", base: "45px" }}
      />
      <Skeleton
        opacity={"0.25"}
        height={"35px"}
        m={{ md: "2", base: "6px" }}
        w={"auto"}
        h={{ md: "50px", base: "45px" }}
      />
      <Skeleton
        opacity={"0.25"}
        height={"35px"}
        m={{ md: "2", base: "6px" }}
        w={"auto"}
        h={{ md: "50px", base: "45px" }}
      />
    </Box>
  );
};

export default EventListSkeleton;
