import React, {useEffect, useRef, useState} from 'react';
import {Select} from "@chakra-ui/react";
import {DataSet, Timeline} from "vis-timeline/standalone";
import StreetMapView from "./StreetMapView";
import food from "../data/timeline/food_security_water_disputes.json"
import green from "../data/timeline/global_green_revolution.json"
import indian from "../data/timeline/indian_food_security.json"
import indiangreen from "../data/timeline/indian_green_revolution.json"
import mughal from "../data/timeline/mughal_timeline.json"
import axios from "axios";
import {NEW_API_URL} from "../redux/types";
import {useSelector} from "react-redux";


function TimelineViz(props) {
    const timelineContainer = useRef(null);
    const [timeline, setTimeline] = useState(undefined);
    console.log(props,'timelinedata')
    const [filterItems,setFilterItems] = useState([
        {'label':'Food security water disputes',value:JSON.stringify(food)},
        {'label':'Global Green Revolution',value:JSON.stringify(green)},
        {'label':'Indian Food security',value:JSON.stringify(indian)},
        {'label':'Indian Green Revolution',value:JSON.stringify(indiangreen)},
        {'label':'Mughal timeline',value:JSON.stringify(mughal)},
    ]);
    const username = useSelector((state)=>state)

    // const [selectedTimeline, setSelectedTimeline] = useState(filterItems[0].value);
    const [selectedTimeline, setSelectedTimeline] = useState(props.timelineData.events);
    // console.log(selectedTimeline)



    useEffect(()=>{
        // const axiosConfig = {
        //     headers: {
        //         Authorization: `Bearer ${username.user.accessToken}`,
        //     },
        // }
        // axios.get(`${NEW_API_URL}interaction/interactions/`, axiosConfig)
        //     .then((res) => {
        //         setFilterItems(res.data.map((i,ind)=>{
        //             return {
        //                 'label':i.topic,
        //                 'value':JSON.stringify(i.interaction_json)
        //             }
        //         }))
        //         setSelectedTimeline(JSON.stringify(res.data[0].interaction_json))
        //     })
        //     .catch((e) => {
        //         console.error(e)
        //
        //     })

    },[])

    useEffect(() => {

        // const items = new DataSet([
        //     { id: 1, content: "<div>" +
        //             "<hr/>" +
        //             "<h6>May 10, 1993</h6>"+
        //             " <h3>Today</h3> " +
        //             "<p>Today is an historic day because of ... </p>" +
        //             "</div>", start: "2014-04-20",className: "color-top" },
        //     { id: 2, content: "item 2", start: "2014-04-14" },
        //     { id: 3, content: "item 3", start: "2014-04-18" },
        //     // { id: 4, content: "item 4", start: "2014-04-16", end: "2014-04-19" },
        //     { id: 5, content: "<div>" +
        //             "<span></span>" +
        //             "<img src='/img.png' width='150px' height='100px'/>" +
        //             "<h6>Jan 10, 1993</h6>" +
        //             "<hr style='margin: 0'>" +
        //             " <h3>Today</h3> " +
        //             "<p>Today is an historic day because of ... </p>" +
        //             "</div>", start: "2014-04-25",className:'img-vis-crd' },
        //     { id: 6, content: "<div>" +
        //             "<span></span>" +
        //             "<h6>Earth Formation</h6>" +
        //             "<p>Scientists now think the Earth’s story began around 4.6 billion\n" +
        //             " years ago in a disk-shaped cloud of dust and gas rotating\n" +
        //             " around the early sun, made up of material left behind after \n" +
        //             "the sun’s formation.</p>" +
        //             "</div>", start: "2014-04-20",className:'vis-note-card' }
        //     // { id: 6, content: "item 6", start: "2014-04-27", type: "point" }
        // ]);

        const timelineItems = selectedTimeline.map((item,id)=>{
            return {
                id:id+1,
                start: item.event_date,
                className:'vis-note-card',
                content: "<div>" +
                    "<span></span>" +
                    "<h6>"+item.title+"</h6>" +
                    "<p>"+item.description+"</p>" +
                    "</div>"
            }
        })


        const items = new DataSet(

            timelineItems
        );


        const options = {
            width: '100%',
            height: '800px',
            margin: {
                item: 20,
                axis: 40,
            },
        };
        var customDate = new Date();

        const timeline = new Timeline(timelineContainer.current, items, options);
        setTimeline(timeline);

        // timeline.addCustomTime(new Date('2014-04-20'), 'id1');
        // timeline.setCustomTimeMarker("From", 'id1', true);
        // timeline.addCustomTime(new Date('2014-04-25'), 'id2');
        // timeline.setCustomTimeMarker("To", 'id2', true);

        return () => {
            timeline.destroy();
        };
    }, []);


    return (
        <div>
            {/*<Select value={selectedTimeline} onChange={(e)=>{*/}
            {/*    // console.log(e.target.value);*/}
            {/*    setSelectedTimeline(e.target.value);*/}

            {/*    const items = JSON.parse(e.target.value);*/}

            {/*    const timelineItems = items.map((item,id)=>{*/}
            {/*        return {*/}
            {/*            id:id+1,*/}
            {/*            start: item.event_date,*/}
            {/*            className:'vis-note-card',*/}
            {/*            content: "<div>" +*/}
            {/*                "<span></span>" +*/}
            {/*                "<h6>"+item.title+"</h6>" +*/}
            {/*                "<p>"+item.description+"</p>" +*/}
            {/*                "</div>"*/}
            {/*        }*/}
            {/*    })*/}


            {/*    for(let i=0;i<timelineItems.length;i++){*/}
            {/*        timeline.itemsData.update({...timelineItems[i]});*/}
            {/*    }*/}


            {/*}}>*/}
            {/*    {*/}
            {/*        filterItems.map((item,index)=>{*/}

            {/*            return (*/}
            {/*                <option value={item.value}>{item.label}</option>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</Select>*/}

            <div ref={timelineContainer} />
            <div style={{marginTop:'300px'}}/>
            <StreetMapView data={selectedTimeline}/>


        </div>
    );
}

export default TimelineViz;
