import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Link,
  Image,
  Spinner,
  SimpleGrid,
  Wrap,
  WrapItem,
  Center,
  ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, useDisclosure, Modal
} from "@chakra-ui/react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import COLORS from "../../../../Components/colors";
import Psm from "../../../../Components/Typography/Text/Psm";
import H1 from "../../../../Components/Typography/Heading/H1";
import H2 from "../../../../Components/Typography/Heading/H2";
import axios from "axios";
import { NEW_API_URL } from "../../../../redux/types";
import { datadogLogs } from '@datadog/browser-logs';
import Pmd from "../../../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../../../Components/Button/ButtonPrimary";
import ButtonSecondary from "../../../../Components/Button/ButtonSecondary";
import {useSelector} from "react-redux";
import InputOutline from "../../../../Components/Input/InputOutline";
import Pxl from "../../../../Components/Typography/Text/Pxl";
let t1 =  Math.floor(Date.now());

export default function Books2() {
  const location = useLocation();
  // const item = location.state.item;

  const [data, setData] = useState([]);
  const [state, setState] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedClassIndex, setSelectedClassIndex] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(undefined);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  let workspaceId = '';
  if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
    workspaceId = selectedWorkspace.workspace_id
  }
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${NEW_API_URL}library/get_all_books/${workspaceId}/${username}/`);

      setData(response.data.book);
      
    } catch (error) {
      console.error(error);
      alert("Couldn't load data");
    } finally {
      setIsLoading(false);
    }
  };

    return (
      <Box width={{ base: "100%", xl: "80%" }}
           maxWidth={{ base: "100%", xl: "80%" }} backgroundColor={"claydeck.white.primary"}>
        <H1 margin={4} marginTop={8} marginBottom={3}>
          Subjects
        </H1>
        <ButtonSecondary margin={4} onClick={()=>{onOpen()}}>Modal</ButtonSecondary>

        {isLoading ? (
          <Spinner size="md" color="claydeck.brand.secondary" />
        ) : (
            <>
          <Flex flexWrap="wrap" gap={4} p={4}>
            {data.map((item, index) => (
                <ButtonSecondary onClick={()=>{setSelectedSubject(item.subjectName)}}>{item.subjectName}</ButtonSecondary>

            ))}
          </Flex>
          <Wrap>



          {data.map((item, index) => {

            return (
<>

                {
                  ((selectedSubject && item.subjectName === selectedSubject) || !selectedSubject) && item.Books.map((book,inde)=>(
                      <WrapItem className={'shadow-primary'}>
                        <Flex
                            sx={{

                              minHeight: "250px",
                              width:'300px',

                              bgColor: "claydeck.white.primary",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "5px",
                              padding: "9px 15px",
                              cursor: "pointer",
                            }}

                            onClick={() => window.open(book.link)}
                        >
                          <Pmd color={"claydeck.brand.secondary"} textAlign="center">
                            {book.name} - {item.subjectName} - Chapter {book.chapter}
                          </Pmd>
                          <Image
                              overflow={"hidden"}
                              objectFit={"contain"}
                              src={
                                book?.image ? book.image : "../assets/images/NCERT.png"
                              }
                              height={"135px"}
                              width={"103px"}
                              marginTop={"8px"}
                              marginBottom={"6px"}
                          />
                          <Pmd color={"claydeck.text.tertiary"} textAlign={"center"}>
                            {book.author}
                          </Pmd>
                        </Flex>
                      </WrapItem>
                  ))
                }
</>
            )
          })}

          </Wrap>

              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  {/*<ModalCloseButton />*/}
                  <ModalBody style={{padding:'40px'}}>
                    <Flex alignItems={'center'} >
                      <Image width={"103px"} src={'/assets/images/NCERT.png'}/>
                      <Box ml={'20px'}>
                        <Pxl style={{margin:0}}>Book title here</Pxl>
                        <Pmd style={{margin:0}}>Author/Publisher: NCERT</Pmd>
                        <Pmd style={{margin:0}}>Class: 6</Pmd>
                        <Pmd style={{margin:0}}>Chapters: 21</Pmd>

                      </Box>
                    </Flex>
                    <Pxl color={'claydeck.brand.secondary'} mt={'20px'}>Chapters</Pxl>



                    <Box p={5} className={'shadow-primary'} borderRadius="14px">
                      <Pmd margin={0}>01 <span style={{color:"#6750A4",fontWeight:'800px',textDecoration:'underline'}}>Chapter Name Goes Here</span></Pmd>
                    </Box>
                    <Box p={5} className={'shadow-primary'} borderRadius="14px">
                      <Pmd margin={0}>02 <span style={{color:"#6750A4",fontWeight:'800px',textDecoration:'underline'}}>Chapter Name Goes Here</span></Pmd>
                    </Box>
                    <Box p={5} className={'shadow-primary'} borderRadius="14px">
                      <Pmd margin={0}>03 <span style={{color:"#6750A4",fontWeight:'800px',textDecoration:'underline'}}>Chapter Name Goes Here</span></Pmd>
                    </Box>
                    <Box p={5} className={'shadow-primary'} borderRadius="14px">
                      <Pmd margin={0}>04 <span style={{color:"#6750A4",fontWeight:'800px',textDecoration:'underline'}}>Chapter Name Goes Here</span></Pmd>
                    </Box>

                  </ModalBody>
                </ModalContent>
              </Modal>

            </>

        )}
      </Box>
    );


}
