import React, {useEffect, useRef, useState} from 'react';
import ButtonCheckboxPrimary from "../Button/ButtonCheckboxPrimary";
import ButtonPrimary from "../Button/ButtonPrimary";
import {BiMicrophone} from "react-icons/bi";
import {Icon} from "@chakra-ui/react";
import {MdFullscreen} from "react-icons/md";

//create your forceUpdate hook
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here
    // is better than directly setting `setValue(value + 1)`
}
function SteamWindow(props) {
    console.log(props.stream,"STEAM!")
    const container = useRef(null);
    const forceUpdate = useForceUpdate();

    useEffect(()=>{
        console.log("STEAM! use effect",props.stream,props.stream['videoTrack'],props.stream.audioTrack,props.video,props.stream['_video_added_'])

        console.log("STEAM! ENABLE",props.stream?1:0)
        if(props.stream){
            console.log("STEAM! ENABLE",props.stream['_audio_added_'])

            if(props.stream['_audio_added_'] && props.stream.audioTrack){
                console.log("STEAM! PLAYING AUDIO",props.stream['_audio_added_'])

                props.stream.audioTrack.play()
            }
            if(props.stream['_video_added_'] && props.stream.videoTrack && props.visible && !props.numberWindow){
                console.log("STEAM! PLAYING VIDEO",props.stream['_video_added_'])

                props.stream.videoTrack.play(container.current)
            }
        }

        // const h = (attempt)=>{
        //     if(attempt>3){
        //         return
        //     }
        //     if(props.stream){
        //
        //         const vid = async () => {
        //             if(props.stream.videoTrack){
        //                 let attempt = 0
        //                 while(!props.stream['_video_added_'] || attempt < 20){
        //                     console.log("STEAM! LOOPING FOR VIDEO ADD!",props.stream)
        //                     await new Promise(r => setTimeout(r, 1000));
        //                     attempt++
        //                 }
        //                 props.stream.videoTrack.play(container.current)
        //             }
        //         }
        //
        //         const aud = async () => {
        //             if(props.stream.audioTrack){
        //                 let attempt = 0
        //                 while(!props.stream['_audio_added_'] || attempt < 20){
        //                     await new Promise(r => setTimeout(r, 1000));
        //                     attempt++
        //                 }
        //                 props.stream.audioTrack.play()
        //             }
        //         }
        //         Promise.all([vid,aud]).then(r=>{console.log("connect success")})
        //         // forceUpdate()
        //     }else {
        //         setTimeout(h,2000,[attempt+1])
        //     }
        // }

        // setTimeout(h,1000,[0])
        // if(container.current){
        //     container.current.innerHTML = ''
        // }

    },[props.visible,props.numberWindow,props.stream,props.stream['_video_added_'],props.stream['_audio_added_'],props.stream['_audio_enabled_'],props.stream['_video_added_'],props.stream.audioTrack,props.stream.videoTrack])

    if(!props.visible && !props.numberWindow){
        return <></>
    }

    if(props.numberWindow){
        return (<div>+ {props.total_users-8} more people!</div>)
    }

    return (
        <div ref={container} style={{backgroundColor:'grey',borderRadius:12,gridColumn:props?.stream?.uid===1?"span 2":"auto / auto",gridRow:props?.stream?.uid===1?"span 2":"auto / auto"}}>
            <ButtonPrimary style={{position:'absolute',zIndex:2}} onClick={()=>{
                if (container?.current.requestFullscreen) {
                    container?.current.requestFullscreen();//aa
                }

            }}><Icon
                as={MdFullscreen}
                boxSize={{ base: "16px", lg: "20px" }}
                color={"#6750A4"}
            /></ButtonPrimary>
        </div>
    );
}

export default SteamWindow;
