import React, {useState, useEffect, useRef} from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag, Text, useDisclosure,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import {useNavigate, useParams} from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import {ArrowBackIcon} from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import {news_map_data} from "../../data/new_generated/news_map";
import Plg from "../../Components/Typography/Text/Plg";
import {news_detail} from "../../data/new_generated/news_detail";
import ButtonCircularIcon from "../../Components/Button/ButtonCircularIcon";
import AskBudAccent from "../../Components/AskBudAccent";
import CustomStyledModal from "../../Components/Modal/CustomStyledModal";
import ButtonText from "../../Components/Button/ButtonText";
import {HighlightMenu} from "react-highlight-menu";
import Markdown from "react-markdown";

const NewsReading = () => {
  const {topic,subject,area,noteid,id} = useParams();

  const [newsData,setNewsData] = useState({});
  const [isFav,setIsFav] = useState(false);
  const [savedNewsData,setSavedNewsData] = useState({});
  const navigate = useNavigate()

  const [forceOpen, setForceOpen] = useState(false);
  const [initText, setInitText] = useState('');

  const username = useSelector(state => state.user.username);
  const notesModalStates = useDisclosure()

  const [topicDetail,setTopicDetail] = useState({})
  const get_map_data = async (username,workspace) => {
    const r = await axios.get(NEW_API_URL+'lessonplan/get_topic_detail/'+username+'/'+workspace+'/')

    setTopicDetail(r.data.topic_detail)

    return r.data
  }

    const get_news_data = async (username,workspace) => {
        const r = await axios.get(NEW_API_URL+'feed/news_detail/'+username+'/'+(noteid||id)+'/')

        // setTopicDetail(r.data.topic_detail)
        // setTimelineData(r.data)
        setNewsData(r.data)
        console.log(r.data,'news data')

        return r.data
    }

    const get_saved_news = async (username) => {
        const r = await axios.get(NEW_API_URL+'feed/get_saved_news/'+username+'/')

        // setTopicDetail(r.data.topic_detail)
        // setTimelineData(r.data)
        setSavedNewsData(r.data)

        if(r.data){
            for (let i = 0; i < r.data.length; i++) {
                console.log(r.data[i].id === noteid,'favd',r.data[i],noteid)
                if(r.data[i].id === noteid || r.data[i].id === id)
                    setIsFav(true)
                else
                    setIsFav(false)
            }
            if(r.data.length === 0){
                setIsFav(false)
            }
        }

        console.log(r.data,'saved news data')

        return r.data
    }

    const save_news = async (username) => {
        const r = await axios.get(NEW_API_URL+'feed/get_saved_news/'+username+'/')

        // setTopicDetail(r.data.topic_detail)
        // setTimelineData(r.data)
        setSavedNewsData(r.data)
        console.log(r.data,'saved news data')

        return r.data
    }





  const [selectedText, setSelectedText] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure()
  const menuRef = useRef();


  useEffect(() => {
      if(!id)
    get_map_data(username,topic)
      get_news_data(username,topic)

      get_saved_news(username)
  }, []);

  const [newsHeadline, setNewsHeadline] = useState(newsData.title);
  const [newsContent, setNewsContent] = useState(newsData.content);
  useEffect(()=>{
      if(!id)
    axios.post(NEW_API_URL+"lessonplan/update_activity/",{
      "user_id":username,
      "topic_id":topic,
      "activity_type":"news",
      "activity_id":noteid,
      "read_status":"Read"
    }).then(r=>{})
  },[])


    const handleSave = async () => {
        try {
            const postedData = {
                user_id: username,
                news_id: newsData.news_id,
                title: newsData.title,
                summary: newsData.summary,
                icon: "",
                priority: newsData.priority,
                news_type: "International",
                source: newsData.source,
                cluster_id:newsData.cluster_id,
                pub_date:newsData.pub_date
            };
            console.log("post data", postedData);
            const res = await axios.post(
                `${NEW_API_URL}feed/post-saved-news/`,
                postedData
            );
            get_saved_news(username)
        } catch (error) {
            console.error("Error saving news:", error);

        }
    };
    const handleSaveDelete = async () => {
        try {
            const postedData = {
                user_id: username,
                news_id: newsData.news_id,
            };
            console.log("post data", postedData);
            const res = await axios.delete(
                `${NEW_API_URL}feed/delete-saved-news/`,{
                    data:postedData
                }
            );
            get_saved_news(username)

        } catch (error) {
            console.error("Error saving news:", error);

        }
    };


  return (
      <>
        <Container  justifyContent={'center'} alignItems={'center'} w={'95%'} maxW='container.xl' >
          <Flex flexDirection={'column'} alignItems={'space-between'} justifyContent={'space-between'} minH={'95vh'}>
            <Box>

              <HighlightMenu
                  styles={{
                    backgroundColor:'#344675',
                    borderRadius:'16px'
                  }}
                  menu={({
                           selectedText,
                           setMenuOpen,
                           setClipboard,
                         })=>{
                    return (<Flex bg={'#344675'} padding={0} borderRadius={'30px'}>
                      <ButtonText
                          fontWeight={'300'}
                          onClick={() => {
                            setSelectedText(selectedText)
                            onOpen()
                            setMenuOpen(false)
                          }}
                      >Quick Explanation</ButtonText>
                      <Box border={'0.5px solid white'} h={'100%'}/>

                      <ButtonText
                          fontWeight={'300'}

                          onClick={() => {
                            setForceOpen(true)
                            setInitText('You are a UPSC assistant. Try to use succinct and to-the-point stoic language with academic inclination. Give answers in the context of '+selectedText)
                            // setSelectedText(selectedText)
                            // notesModalStates.onOpen()
                            setMenuOpen(false)
                          }}
                      >Ask Question</ButtonText>
                      <Box border={'0.5px solid white'} h={'100%'}/>
                      <ButtonText
                          fontWeight={'300'}

                          onClick={() => {
                            setSelectedText(selectedText)
                            notesModalStates.onOpen()
                            setMenuOpen(false)
                          }}
                      >Add to Note</ButtonText>

                    </Flex>)
                  }}
                  target={menuRef}
              />

              <Flex mt={'10vh'} alignItems={'center'}>
                <ArrowBackIcon cursor={'pointer'} onClick={()=>{navigate(-1)}} marginLeft={'-65px'} marginRight={'20px'} boxSize={'45px'}/>



                <Box flex={1}>
                  <P2xl mb={0} ml={0} mr={0}>
                    <strong>News Article</strong>

                  </P2xl>
                </Box>

                  {
                      isFav && <Image cursor={'pointer'} onClick={()=>{
                          handleSaveDelete()
                      }} src={'/favon.svg'} width={28} height={27}/>
                  }
                  {
                      !isFav && <Image cursor={'pointer'} onClick={()=>{
                          handleSave()
                      }} src={'/favoff.svg'} width={28} height={27}/>
                  }



              </Flex>
              <hr/>

              <Container maxW={'60%'}>
                <Flex justifyContent={'space-around'} alignItems={'center'}>
                <ButtonCircularIcon icon={'/mpriority.png'}>M Priority</ButtonCircularIcon>
                <ButtonCircularIcon icon={'/related_news.png'}>Related News</ButtonCircularIcon>
                <ButtonCircularIcon icon={'/related_notes.png'}>Related Notes</ButtonCircularIcon>
                <ButtonCircularIcon icon={'/askbud.png'}>Ask Bud</ButtonCircularIcon>
                </Flex>

                <Box mt={'20px'} w={'100%'} bgColor={'white'} minH={'200px'} borderRadius={12}>
                  <Container ref={menuRef} maxW={'95%'}>
                  <Pmd pt={'20px'}><strong>{newsData.title}</strong></Pmd>
                  <Pmd><Markdown>{newsData.generated_summary}</Markdown></Pmd>
                  </Container>

                </Box>


              </Container>
              <AskBudAccent context={newsHeadline} forceOpen={forceOpen} setForceOpen={setForceOpen} initText={'You are a UPSC assistant. Try to use succinct and to-the-point stoic language with academic inclination. Give answers in the context of '+newsHeadline+':'+newsData}/>
              <CustomStyledModal force_rel={'news'} force_topic={newsHeadline} toast={'Note successfully created! You can view it on the Notes page in this topic'} topicDetail={topicDetail} add_note isOpen={notesModalStates.isOpen} onOpen={notesModalStates.onOpen} onClose={notesModalStates.onClose} title={'Notes'} selectedText={''}/>
              <Flex onClick={()=>{notesModalStates.onOpen()}} cursor={'pointer'} w={'80px'} h={'80px'} alignItems={'center'} justifyContent={'center'} bg={"claydeck.brand.primary"} border={'2px solid white'} boxShadow={'0px 4px 4px 0px #00000040'} position={'fixed'} bottom={'120px'} right={'20px'} borderRadius={'50px'}>
                <Text color={'white'} margin={0} fontSize={'32px'}>+</Text>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </>

  );
}

export default NewsReading;
