import {Box, Flex, List, ListItem, ListIcon} from "@chakra-ui/react";

import {CheckCircleIcon} from "@chakra-ui/icons";

import H1 from "../../Components/Typography/Heading/H1";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";

const PlanDetails = ({ data, period, userDetails, selectedPlan, choosePlan }) => {

  const styles = {
    card: {
      h: "365px",
      w: "375px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      borderWidth: "2px",
      borderColor: selectedPlan === data.type ? "claydeck.brand.secondary" : "#ededed",
      borderRadius: "4px",
      p: "16px",
      cursor: 'pointer'
    },
  };

  return (
    <Box sx={styles.card} onClick={() => choosePlan(data.type)}>
      <H1 sx={{ fontSize: "24px" }}>{data.type}</H1>
      {data?.monthlyPrice ? <Flex alignItems={"center"}>
        {period == "oneTime" ? (
          <Pmd textDecoration={"line-through"} marginRight={"8px"}>
            &#8377; {data.oneTimePrice}
          </Pmd>
        ) : (
          <></>
        )}
        <Pmd alignSelf={"flex-start"}>&#8377;</Pmd>
        <H1>
          {period == "monthly"
            ? data.monthlyPrice
            : data.oneTimeDiscountedPrice}
        </H1>
        {period == "monthly" ? <Psm>/month</Psm> : <></>}
      </Flex> : null}
      <List>
        {data.benefits.map((item, index) => {
          return (
            <ListItem
              key={index}
              display={"flex"}
              alignItems={"center"}
              gap={"4px"}
              paddingY={"8px"}
            >
              <ListIcon as={CheckCircleIcon} color="green.500" />
              <Pmd color={"claydeck.text.tertiary"} marginBottom={"0px"}>
                {item}
              </Pmd>
            </ListItem>
          );
        })}
      </List>
      {/* <Flex justifyContent={"flex-end"}>
        <ButtonSecondary
          // onClick={() => selectedPlan === "Free Trial" ? freeTrialSubscription() : getSubscriptionId()}
          onClick={() => freeTrialSubscription()}
        >
          Choose Plan
        </ButtonSecondary>
      </Flex> */}
    </Box>
  );
};

export default PlanDetails;