import { Avatar, Box, Heading, Flex, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  Text,
  CardBody,
  CardFooter,
  Divider,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import PostDuration from "../../../svg/PostDuration";
import Comment from "../../../svg/Comment";
import LikeAndDisLikeBox from "../../../Components/LikeDisLike/LikeAndDisLikeBox";
import { getTimeAgo } from "../../../utils/getDurationFromTimeStamp";
import { useSelector, useDispatch } from "react-redux";
import CommentSection from "../../../Components/Modal/CommentModal";
import { capitalizeFirstLetter } from "../../../utils/stringManipulation";

const CardItems = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tags = useSelector((state) => state.forumData.activeTags);

  const Data0 = data?.tags;
  const splittedData = Data0?.length > 0 ? Data0[0]?.split(",") : [];

  return (
    <VStack spacing={4} align="stretch">
      <>
        <CommentSection isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      </>
      <Card
        boxShadow="xl"
        _hover={{
          transition: "all 0.3s ease-in-out",
          transform: "scale(1.02)",
        }}
      >
        <CardHeader borderRadius={"10px"}>
          <Flex spacing="4">
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
              <Avatar src={data.avatarSrc} />

              <Box>
                <Heading size={"sm"}>{data.name}</Heading>
                {data.position && <Text fontSize={"xs"}>{data.position}</Text>}
              </Box>
            </Flex>
            <Flex
              direction={"row"}
              gap="2"
              justifyContent={"center"}
              alignItems="center"
            >
              <Heading size={"sm"}>
                <Box color={"#6750A4"} size={"xs"} fontWeight={"semibold"}>
                  {splittedData === undefined ? (
                    <Text
                      as={"div"}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      alignContent={"center"}
                      gap={1}
                    >
                      other
                      <Box
                        rounded={"full"}
                        bg={"#6750A4"}
                        width={"8px"}
                        height={"8px"}
                        border={"0.5px solid #ecfff4"}
                      />
                    </Text>
                  ) : (
                    splittedData.map((item, index) => (
                      <Text
                        key={index}
                        as={"div"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        alignContent={"center"}
                        gap={1}
                      >
                        {capitalizeFirstLetter(item.toLowerCase())}
                        <Box
                          rounded={"full"}
                          bg={"#6750A4"}
                          width={"8px"}
                          height={"8px"}
                          border={"0.5px solid #ecfff4"}
                        />
                      </Text>
                    ))
                  )}
                </Box>
              </Heading>
            </Flex>
          </Flex>
        </CardHeader>
        <Box as="div" paddingX={"20px"}>
          <Divider
            orientation="horizontal"
            borderColor={"#7b61c2"}
            borderRadius={"1px"}
            margin={"0px"}
          />
        </Box>

        <CardBody
          as={"a"}
          href={`/forums/${data.id}/detailedforum`}
          cursor={"pointer"}
        >
          <Text textColor={"#828282"}>{data.title}</Text>
        </CardBody>

        <CardFooter justifyContent="flex-start" gap={2}>
          <Flex gap={4} direction={{ base: "column", md: "row" }}>
            <LikeAndDisLikeBox
              likes={data.upvotes.length}
              id={data.id}
              ws_id={data.ws_id}
            />
            <Flex gap={4}>
              <Box
                as="span"
                borderRadius="md"
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <PostDuration />
                <span>{getTimeAgo(data.timestamp)}</span>
              </Box>
              <Box
                as="button"
                borderRadius="md"
                display={"flex"}
                alignItems={"center"}
                gap={1}
                onClick={onOpen}
              >
                <Comment />
                <span>84</span>
              </Box>
            </Flex>
          </Flex>
        </CardFooter>
      </Card>
    </VStack>
  );
};

export default CardItems;
