import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select,
  Tag,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight, AiOutlineSearch} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import {ArrowBackIcon} from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import AskBudAccent from "../../Components/AskBudAccent";
import {useParams} from "react-router-dom";
const TopicDetail = () => {


  const {topic,subject,area} = useParams();
  console.log(topic,'topic')
  const navigate = useNavigate();
  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  let workspaceId = 1;

  const [topicDetail,setTopicDetail] = useState({})


  const get_map_data = async (username,workspace) => {
    const r = await axios.get(NEW_API_URL+'lessonplan/get_topic_detail/'+username+'/'+workspace+'/')
    setTopicDetail(r.data.topic_detail)
    return r.data
  }


  useEffect(() => {
    get_map_data(username,topic)


  }, []);




  return (
      <>
      <Container  justifyContent={'center'} alignItems={'center'} w={'95%'} maxW='container.xl' >
        <Flex flexDirection={'column'} alignItems={'space-between'} justifyContent={'space-between'} minH={'95vh'}>
        <Box>
        <Flex mt={'10vh'} alignItems={'center'}>
          <ArrowBackIcon cursor={'pointer'} onClick={()=>{navigate(-1)}} marginLeft={'-65px'} marginRight={'20px'} boxSize={'45px'}/>

          <Box>
        <P2xl mb={0} ml={0} mr={0}  >
          <strong>{topicDetail.topic_name}</strong>

        </P2xl>
        <Pmd color={'#817D7D'} style={{margin:0}}><strong>History</strong></Pmd>
          </Box>
        </Flex>
        <hr/>

          <Flex gap={'40px'} alignItems={'flex-start'} justifyContent={'space-between'}>
            <Box>
        <P2xl mt={20}><strong>Learn</strong></P2xl>
        <Wrap spacing={'20px'}>
          <WrapItem>
          <TopicCard to={'/learn/'+subject+'/'+area+'/'+topic+'/readings'} title={'Readings'} icon={'/detail_reading.png'}/>
          </WrapItem>
          <WrapItem>

          <TopicCard title={'Notes'} icon={'/detail_notes.png'} to={'/learn/'+subject+'/'+area+'/'+topic+'/notes'}/>
          </WrapItem>


        </Wrap>
            </Box>

            <Box>
            <P2xl mt={20}><strong>Master</strong></P2xl>
              <Wrap spacing={'20px'}>

            <WrapItem>

              <TopicCard title={'Maps & Timelines'} icon={'/detail_map.png'} to={'/learn/'+subject+'/'+area+'/'+topic+'/timeline'}/>
            </WrapItem>

            <WrapItem>

              <TopicCard title={'i-Tools'} icon={'/itools.png'} to={'/learn/'+subject+'/'+area+'/'+topic+'/itools'}/>
            </WrapItem>

              </Wrap>
            </Box>

          </Flex>

        <P2xl mt={10}><strong>Apply</strong></P2xl>
        <Wrap spacing={'20px'}>
          <WrapItem>
            <TopicCard to={'/learn/'+subject+'/'+area+'/'+topic+'/news'} title={'News'} icon={'/detail_news.png'}/>
          </WrapItem>
          <WrapItem>

            <TopicCard to={'/learn/'+subject+'/'+area+'/'+topic+'/tests'} title={'Tests'} icon={'/detail_test.png'}/>
          </WrapItem>
        </Wrap>
        </Box>
          <Box mb={'20px'}>
        <Pmd fontWeight={'300px'} mt={'50px'}>To access all the features go to the app</Pmd>
        <ButtonPrimary>App Button Here</ButtonPrimary>
          </Box>
        </Flex>

        {topicDetail.topic_name && <AskBudAccent context={topicDetail.topic_name}
                       initText={"You are a UPSC assistant. Try to use succinct and to-the-point stoic language with academic inclination. Give answers in the context of " + topicDetail.topic_name + " topic of UPSC"}/>

        }
      </Container>
      </>

  );
}

export default TopicDetail;
