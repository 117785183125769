import React from 'react';
import {Box, Flex, Image, Select} from "@chakra-ui/react";
import Pmd from "./Typography/Text/Pmd";

function HeadCard(props) {
    const filterData = [
        {
            label: "No filter",
            value: "7",
        },
        // { label: "Last Day", value: "last_day" },
        { label: "Last 1 week", value: "7" },
        { label: "Last 1 month", value: "30" },
        { label: "Last 3 months", value: "90" },
        { label: "Last 6 months", value: "180" },
        { label: "Last 1 Year", value: "365" },
        { label: "Last 2 Years", value: "730" },
    ];

    return (
        <Box borderRadius={'12px'} backgroundColor={'claydeck.white.primary'} {...props} border={props.border?'1px solid #B7B7B7':'none'} boxShadow={props.shadow?'0px 4px 4px 0px #00000040':'none'}>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Flex alignItems={'center'} mt={'13px'}>
                    <Box width={'4px'} height={'20px'} backgroundColor={'claydeck.brand.primary'} borderRadius={'0px 12px 12px 0px'}/>

                    <Pmd color={'#343B40'} margin={0} pl={'10px'}>
                        <strong>{props.title}</strong>
                    </Pmd>
                </Flex>

                <Flex alignItems={'center'} mt={'13px'} mr={'13px'}>
                <Pmd color={'claydeck.brand.secondary'} margin={0} pr={'8px'}>

                {
                    props.titleButton ? props.titleButton : ''
                }
                </Pmd>
                    {
                        props.select && <Select onChange={(e)=>{
                            if(props.selectChange){
                                props.selectChange(e)
                            }
                        }} borderRadius={6} w={'150px'} border={'1px solid #0962A2'} color={'#0962A2'}>
                            {
                                filterData.map((item,idx)=> (
                                    <option value={item.value}>{item.label}</option>
                                ))
                            }
                        </Select>
                    }

                {
                    props.titleButton && <Image src={'/bluearrow.png'} height={'15px'}></Image>
                }
                </Flex>
            </Flex>


            {props.children}

        </Box>
    );
}

export default HeadCard;
