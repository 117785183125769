import {useState} from "react";
import { Link, useNavigate } from "react-router-dom";

import {useGoogleLogin} from "react-google-login";

import { Box, Grid, GridItem, Checkbox, Image } from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import H2 from "../../Components/Typography/Heading/H2";
import InputOutline from "../../Components/Input/InputOutline";
import InputPassword from "../../Components/Input/InputPassword";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";

import {useDispatch, useSelector} from "react-redux";
import {loginUser, signupUser} from "../../redux/actions/userActions";

const clientId =
  "105604948407-qgjbl3k27icekteikgjq56tehc4tlnuc.apps.googleusercontent.com";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signuploading = useSelector((state) => state.UI.signupLoading);

  const [firstName, setFirstName] = useState("");
  const [isAccountCreateLoading, setIsAccountCreateLoading] = useState(false);
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const onSuccess = (res) => {
    console.log('Login Success: currentUser:', res.profileObj);
    const prf = res.profileObj;

    dispatch(loginUser({
      email:prf.email,
      password:prf.email+''+prf.googleId,
      username:prf.email,
      isGoogle:true,
      prf:prf
    }, rememberMe, navigate));

    // const userData = {
    //   first_name: '',
    //   last_name: '',
    //   username: email,
    //   email: email,
    //   password: prf.email+''+prf.googleId,
    // };
    //
    // dispatch(signupUser(userData, rememberMe, navigate));

    // setIsSigningIn(false);
  }

  const onFailure = (res) => {
    console.log('Login failed res:', res);
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: "offline",
  });

  const validation = () => {
    // name validation
    const regexName = /^[a-zA-Z ]+$/;
    const testFirstName = regexName.test(firstName);
    const testLastName = regexName.test(lastName);

    if (!testFirstName) {
      alert("Please enter a first name");
      return;
    }
    if (!testLastName) {
      alert("Please enter a last name");
      return;
    }


    // email validation
    const regexEmail =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmail = regexEmail.test(email);
    if (!testEmail) {
      alert("Please enter a valid email address.");
      return;
    }

    // password validation
    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const testPassword = regexPassword.test(password);
    if (!testPassword) {
      alert(
        "Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:"
      );
      return;
    }

    // password match validation
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    return (
      email !== "" &&
      password !== "" &&
      confirmPassword !== ""
    );
  };

  const handleSubmit = () => {
    setIsAccountCreateLoading(true)

    if (!validation()) {
      setIsAccountCreateLoading(false)

      return;
    }

    const userData = {
      first_name: firstName,
      last_name: lastName,
      username: email,
      email: email,
      password: password,
    };

    dispatch(signupUser(userData, rememberMe, navigate));
    // setIsAccountCreateLoading(false)
  }

  return (
    <Grid columns={2} templateColumns={"1.5fr 2fr"}>
      <GridItem
        w="100%"
        h="100vh"
        bgColor={"claydeck.brand.secondary"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image
          src={"./assets/images/mockup-with-bg.png"}
          objectFit={"contain"}
          alt={"mobile-app-mockup"}
          width={"100%"}
          maxHeight={"770px"}
        />
      </GridItem>
      <GridItem
        w="100%"
        h="100vh"
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        // paddingTop={"66px"}
      >
        <Box
          height={"100%"}
          width={"80%"}
          maxWidth={"620px"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
        >
          <Image
            height={"80px"}
            objectFit={"contain"}
            src={"./assets/images/claydeck-logo.png"}
            alt={"site-logo"}
            alignSelf={"flex-start"}
            // marginBottom={"68px"}
          />
          <Box>
            <Box marginBottom={"39px"}>
              <Pmd color={"claydeck.text.tertiary"} marginBottom={"16px"}>
                Sign up with?
              </Pmd>
              <ButtonSecondary
                onClick={()=>signIn}
                width={"150px"}
                marginRight={"24px"}
              >
                Google
              </ButtonSecondary>
            </Box>
            {/* <ButtonSecondary width={"150px"}>Facebook</ButtonSecondary> */}
            <Grid gap={"24px"}>
              <GridItem
                display={"flex"}
                justifyContent={"space-between"}
                gap={"24px"}
              >
                <Box width={"100%"}>
                  <Pmd
                    htmlFor="firstName"
                    color={"claydeck.text.tertiary"}
                    mb={"16px"}
                  >
                    First Name
                  </Pmd>
                  <InputOutline
                    type="text"
                    name="firstName"
                    placeholder={"John"}
                    value={firstName}
                    onChange={(event) =>
                      setFirstName(event.currentTarget.value)
                    }
                  />
                </Box>
                <Box width={"100%"}>
                  <Pmd
                    htmlFor="lastName"
                    color={"claydeck.text.tertiary"}
                    mb={"16px"}
                  >
                    Last Name
                  </Pmd>
                  <InputOutline
                    type="text"
                    name="lastName"
                    placeholder={"Doe"}
                    value={lastName}
                    onChange={(event) => setLastName(event.currentTarget.value)}
                  />
                </Box>
              </GridItem>
              <GridItem>
                <Pmd
                  htmlFor="email"
                  color={"claydeck.text.tertiary"}
                  mb={"16px"}
                >
                  Email
                </Pmd>
                <InputOutline
                  type="email"
                  name="email"
                  placeholder={"example@email.com"}
                  value={email}
                  onChange={(event) => setEmail(event.currentTarget.value)}
                />
              </GridItem>
              <GridItem>
                <Pmd
                  htmlFor="password"
                  color={"claydeck.text.tertiary"}
                  mb={"16px"}
                >
                  Password
                </Pmd>
                <InputPassword
                  value={password}
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
              </GridItem>
              <GridItem>
                <Pmd
                  htmlFor="confirmPassword"
                  color={"claydeck.text.tertiary"}
                  mb={"16px"}
                >
                  Confirm Password
                </Pmd>
                <InputPassword
                  value={confirmPassword}
                  onChange={(event) =>
                    setConfirmPassword(event.currentTarget.value)
                  }
                />
              </GridItem>
            </Grid>
            <Box marginTop={"18px"}>
              <Checkbox
                isChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              >
                <H2>Remember Me</H2>
              </Checkbox>
            </Box>
          </Box>
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            // marginTop={"48px"}
          >
            <ButtonSecondary
                isLoading={signuploading}
                width={"60%"}
              minWidth={"250px"}
              maxWidth={"400px"}
              height={"56px"}
              color={"white"}
              bg={"claydeck.brand.primary"}
              borderRadius={"10px"}
              marginBottom={0}
              onClick={() => handleSubmit()}
            >
              Create Account
            </ButtonSecondary>
            <Box display={"flex"} marginTop={"12px"}>
              <Pmd color={"claydeck.text.tertiary"}>Already created?</Pmd>
              <Link to={"/signin"} style={{ margin: "0px 12px" }}>
                <H2 color={"claydeck.text.primary"}>Sign In</H2>
              </Link>
            </Box>
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default SignUp;
