import React, {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";
import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button, Center,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Select, Tab, TabList, TabPanel, TabPanels, Tabs,
  Tag,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";
import {capsFirst} from "../../Components/utils";
// import ChakraCarousel from "../../Components/ChakraCarousel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import HeadCard from "../../Components/HeadCard";
import Pxl from "../../Components/Typography/Text/Pxl";
import P2xl from "../../Components/Typography/Text/P2xl";
import {ArrowBackIcon} from "@chakra-ui/icons";
import TopicCard from "../../Components/TopicCard";
import {news_map_data} from "../../data/new_generated/news_map";
import Plg from "../../Components/Typography/Text/Plg";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const News = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const [startDate, setStartDate] = useState(new Date());
  const [dateFilter, setDatefilter] = useState('7');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
  const user = useSelector(state => state.user);
  let workspaceId = '';
  if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
    workspaceId = selectedWorkspace.workspace_id
  }

  function getFormattedDate(dt) {
    const today = dt;
    const year = today.getFullYear();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed

    return `${year}-${month}-${day}`;
    // return '2024-09-26'
  }

  useEffect(() => {
    getDailyNews(username)
  }, [startDate]);

  const getDailyNews = async (username) => {
    const r = await axios.get(NEW_API_URL+'feed/daily-news/'+getFormattedDate(startDate)+'/'+username).catch(err=>{})

    // setTopicDetail(r.data.topic_detail)
    // setTimelineData(r.data)
    console.log(r?.data,'timeline')
    if(r?.data?.NewsArticles)
    setDailyNews(r.data.NewsArticles)

    if(r?.data?.DailyQuiz){
      setdailyQuiz(r.data.DailyQuiz)
    }

    return r?.data
  }
  const getSavedNews = async (username) => {
    const r = await axios.get(NEW_API_URL+'feed/get_saved_news/'+username).catch(err=>{})

    // setTopicDetail(r.data.topic_detail)
    // setTimelineData(r.data)
    console.log(r?.data,'timeline')
    if(r?.data?.saved_news)
      setSavedNews(r.data)

    return r?.data
  }

  const getTrendingNews = async (username) => {
    const r = await axios.get(NEW_API_URL+'feed/trending-news/'+username+'/'+dateFilter).catch(err=>{})

    // setTopicDetail(r.data.topic_detail)
    // setTimelineData(r.data)
    console.log(r.data,'trending')
    if(r?.data){
      setClusters(r.data[0].clusters)
      setTrendingNews([])
      setSelectedCluster([])
    }
    return r?.data
  }

  useEffect(()=>{
    getDailyNews(username)
    getSavedNews(username)
  },[])

  useEffect(()=>{
    getTrendingNews(username)
  },[dateFilter])

  const [dailyNews,setDailyNews] = useState([]);
  const [dailyQuiz,setdailyQuiz] = useState([]);
  const [tredingNews,setTrendingNews] = useState([]);
  const [clusters,setClusters] = useState([]);
  const [saved_news,setSavedNews] = useState([]);
  const [selectedCluster,setSelectedCluster] = useState([]);
  const [tab,setTab] = useState('daily_news');


  return (
      <>
        <Container  justifyContent={'center'} alignItems={'center'} w={'95%'} maxW='container.xl' >
          <Flex flexDirection={'column'} alignItems={'space-between'} justifyContent={'space-between'} minH={'95vh'}>
            <Box>
              <Flex mt={'10vh'} alignItems={'center'}>
                <ArrowBackIcon cursor={'pointer'} onClick={()=>{navigate(-1)}} marginLeft={'-65px'} marginRight={'20px'} boxSize={'45px'}/>

                <Box>
                  <P2xl mb={0} ml={0} mr={0}  >
                    <strong>News</strong>

                  </P2xl>
                </Box>
              </Flex>









              <Tabs align={'center'}>
                <TabList>
                  <Tab onClick={()=>{setTab("daily_news")}}>
                    <Pxl style={{fontWeight:tab=="daily_news"?'600':'300'}} ml={'30px'} mr={'30px'} cursor={'pointer'}  color={tab=="daily_news"?'#0962A2':'black'}>Daily</Pxl>


                  </Tab>
                  <Tab onClick={()=>{setTab("trending")}}>
                    <Pxl style={{fontWeight:tab=="trending"?'600':'300'}} ml={'30px'} mr={'30px'} cursor={'pointer'}  color={tab=="trending"?'#0962A2':'black'}>Trending</Pxl>


                  </Tab><Tab  onClick={()=>{setTab("saved")}}>
                  <Pxl style={{fontWeight:tab=="saved"?'600':'300'}} ml={'30px'} mr={'30px'} cursor={'pointer'} color={tab=="saved"?'#0962A2':'black'}>Saved</Pxl>


                </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Flex marginBottom={'20px'} justifyContent={'flex-end'}>
                    <Flex className="date-picker-container-1" bgColor={'white'} borderRadius={'12px'} height={'20px'} padding={'20px'} alignItems={'center'} justifyContent={'center'}>
                    <Image marginRight={'20px'} src={'/calendar.png'} width={'20px'} height={'20px'}></Image>
                    <DatePicker style={{width:'20px'}} selected={startDate} onChange={(date) => setStartDate(date)} />
                    </Flex>
                    </Flex>
                    <HeadCard title={'News Articles'}  paddingRight={'10px'} paddingBottom={'30px'}>


                      <Slider {...settings} draggable={true} arrows={false}>
                        {
                          dailyNews.map((item) => {
                            return (<>
                              <Link to={'/news/'+item.id}>
                                <Box  backgroundColor={'#ededed'} borderRadius={'12px'} overflow={'hidden'} h={'380px'}
                                     w={'300px'} boxShadow={'0px 1px 2px 0px #00000040'} border={'1px solid #FFFFFF80'}
                                     mt={'15px'}>

                                  <Image src={item.icon} w={'100%'}></Image>

                                  <Flex alignItems={'center'} justifyContent={'space-around'}>
                                    <Box>

                                      <Pmd textAlign={'left'} pt={'14px'} margin={0}><strong>{item.title}</strong></Pmd>
                                      <Psm textAlign={'left'} >{item.summary}</Psm>
                                    </Box>
                                    {item.read_status === 'Read' && <Image src={'/tick_news.png'} h={'20px'} w={'20px'}></Image>}

                                  </Flex>


                                </Box>
                              </Link>
                            </>)
                          })
                        }


                      </Slider>


                    </HeadCard>

                    <HeadCard title={"News test"} mt={'30px'}>

                      <Flex ml={'12px'} pb={'15px'}>

                        {
                          dailyQuiz.map((item,index)=>(
                              <Box position={'relative'} mr={'15px'} mt={'30px'} w={'200px'} p={'12px'} boxShadow={'0px 3px 3px 0px #00000040'}
                                   borderRadius={'12px'} borderLeft={'4px solid #2A6291'}>
                                {item.read_status === 'Read' && <Image style={{position:'absolute',right:'10px',top:'10px'}} src={'/read_tick.png'} height={'20px'} width={'20px'}/>}

                                <Psm>{item.title}</Psm>
                                <Flex alignItems={'center'}>
                                  <Image height={'64px'} width={'53px'} src={'/news_test.png'}></Image>
                                  <Plg margin={0} pl={'20px'}>{item.score} / 10</Plg>
                                </Flex>
                              </Box>
                          ))
                        }




                      </Flex>


                    </HeadCard>
                  </TabPanel>
                  <TabPanel>
                    <Container maxWidth={'70%'}>
                      <HeadCard selectChange={(e)=>{
                        // console.log(e.target.value)
                        setDatefilter(e.target.value)
                      }} select title={selectedCluster.title || 'News Articles'} pb={'12px'}>
                        <Container mt={'20px'}>
                          {
                              tredingNews.length <=0 && clusters && clusters.map((item,index)=>{

                              return <>
                                {/*<Link to={'/news/'+item.news_id}>*/}

                                  <Flex alignItems={'center'} justifyContent={'space-between'}>
                                    <Pmd margin={0}><strong>{item.title}</strong></Pmd>
                                    <Image  onClick={()=>{setTrendingNews(item.related_news);setSelectedCluster(item)}} cursor={'pointer'} src={'/go_button.png'} h={'27px'} w={'27px'}></Image>
                                  </Flex>
                                  <hr/>
                                {/*</Link>*/}
                              </>
                            })

                          }
                        </Container>

                        <Container mt={'20px'}>
                          {
                              tredingNews.length >0 && tredingNews.map((item,index)=>{

                                return <>
                                  <Link to={'/news/'+item.news_id}>

                                  <Flex  alignItems={'center'} justifyContent={'space-between'}>
                                    <Pmd margin={0}><strong>{item.title}</strong></Pmd>
                                    <Image src={'/go_button.png'} h={'27px'} w={'27px'}></Image>
                                  </Flex>
                                  <hr/>
                                  </Link>
                                </>
                              })

                          }
                        </Container>

                      </HeadCard>
                    </Container>
                  </TabPanel>
                  <TabPanel>
                    <Container maxWidth={'70%'}>
                      <HeadCard select title={'News Articles'} pb={'12px'}>
                        <Container mt={'20px'}>
                          {
                            saved_news && saved_news.map((item,index)=>{

                              return <>
                                <Link to={'/news/'+item.id}>

                                  <Flex alignItems={'center'} justifyContent={'space-between'}>
                                    <Pmd margin={0}><strong>{item.title}</strong></Pmd>
                                    <Image src={'/go_button.png'} h={'27px'} w={'27px'}></Image>
                                  </Flex>
                                  <hr/>
                                </Link>
                              </>
                            })

                          }
                        </Container>

                      </HeadCard>
                    </Container>
                  </TabPanel>
                </TabPanels>
              </Tabs>



            </Box>
          </Flex>
        </Container>
      </>

  );
}

export default News;
