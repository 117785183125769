export const dataObj = [
  {
    id: 1,
    text: "This is a sample text for the 'text' field.",
    table: {
      headers: ["Name", "Age", "City"],
      rows: [
        ["John Doe", 30, "New York"],
        ["Jane Doe", 28, "Los Angeles"],
        ["Jim Doe", 32, "Chicago"],
      ],
    },
    quiz: [
      {
        question: "What is the capital of France?",
        options: ["Paris", "London", "Berlin", "Madrid"],
        answer: "Paris",
      },
      {
        question: "Who is the president of the United States?",
        options: [
          "Joe Biden",
          "Donald Trump",
          "Barack Obama",
          "George Washington",
        ],
        answer: "Joe Biden",
      },
    ],
    tasks: [
      {
        name: "Buy groceries",
        completed: false,
        time_estimate: 2,
        pending_date: new Date().toLocaleDateString(),
      },
      {
        name: "Walk the dog",
        completed: true,
        time_estimate: 2,
        pending_date: null,
      },
      {
        name: "Read a book",
        completed: false,
        time_estimate: 2,
        pending_date: new Date().toLocaleDateString(),
      },
    ],
  },
  // {
  //   id: 2,
  //   text: "Another sample text for the 'text' field.",
  //   table: {
  //     headers: ["Name", "Age", "City"],
  //     rows: [
  //       ["Alice Smith", 25, "San Francisco"],
  //       ["Bob Johnson", 35, "Seattle"],
  //       ["Catherine Brown", 28, "Denver"],
  //     ],
  //   },
  //   quiz: [
  //     {
  //       question: "Who is the author of 'To Kill a Mockingbird'?",
  //       options: [
  //         "Harper Lee",
  //         "Ernest Hemingway",
  //         "J.K. Rowling",
  //         "F. Scott Fitzgerald",
  //       ],
  //       answer: "Harper Lee",
  //     },
  //     {
  //       question: "What is the capital of Canada?",
  //       options: ["Toronto", "Ottawa", "Vancouver", "Montreal"],
  //       answer: "Ottawa",
  //     },
  //   ],
  //   tasks: [
  //     {
  //       name: "Write report",
  //       completed: true,
  //       time_estimate: 2,
  //       pending_date: null,
  //     },
  //     {
  //       name: "Exercise",
  //       completed: false,
  //       time_estimate: 2,
  //       pending_date: new Date().toLocaleDateString(),
  //     },
  //     {
  //       name: "Attend meeting",
  //       completed: false,
  //       time_estimate: 2,
  //       pending_date: new Date().toLocaleDateString(),
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   text: "A third sample text for the 'text' field.",
  //   table: {
  //     headers: ["Name", "Age", "City"],
  //     rows: [
  //       ["Ella Davis", 22, "Boston"],
  //       ["Frank White", 40, "Miami"],
  //       ["Grace Taylor", 33, "Austin"],
  //     ],
  //   },
  //   quiz: [
  //     {
  //       question: "What is the largest planet in our solar system?",
  //       options: ["Earth", "Mars", "Jupiter", "Saturn"],
  //       answer: "Jupiter",
  //     },
  //     {
  //       question: "In which year did World War II end?",
  //       options: ["1943", "1945", "1950", "1939"],
  //       answer: "1945",
  //     },
  //   ],
  //   tasks: [
  //     {
  //       name: "Clean the house",
  //       completed: true,
  //       time_estimate: 2,
  //       pending_date: null,
  //     },
  //     {
  //       name: "Prepare presentation",
  //       completed: false,
  //       time_estimate: 2,
  //       pending_date: new Date().toLocaleDateString(),
  //     },
  //     {
  //       name: "Go for a run",
  //       completed: true,
  //       time_estimate: 2,
  //       pending_date: null,
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   text: "Yet another sample text for the 'text' field.",
  //   table: {
  //     headers: ["Name", "Age", "City"],
  //     rows: [
  //       ["Henry Williams", 28, "Portland"],
  //       ["Ivy Green", 36, "Phoenix"],
  //       ["Jack Robinson", 31, "Las Vegas"],
  //     ],
  //   },
  //   quiz: [
  //     {
  //       question: "What is the capital of Japan?",
  //       options: ["Beijing", "Seoul", "Tokyo", "Bangkok"],
  //       answer: "Tokyo",
  //     },
  //     {
  //       question: "Who wrote 'Romeo and Juliet'?",
  //       options: [
  //         "William Shakespeare",
  //         "Jane Austen",
  //         "Charles Dickens",
  //         "Mark Twain",
  //       ],
  //       answer: "William Shakespeare",
  //     },
  //   ],
  //   tasks: [
  //     {
  //       name: "Plan vacation",
  //       completed: false,
  //       time_estimate: 2,
  //       pending_date: new Date().toLocaleDateString(),
  //     },
  //     {
  //       name: "Fix computer issues",
  //       completed: true,
  //       time_estimate: 2,
  //       pending_date: null,
  //     },
  //     {
  //       name: "Learn a new language",
  //       completed: false,
  //       time_estimate: 2,
  //       pending_date: new Date().toLocaleDateString(),
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   text: "One more sample text for the 'text' field.",
  //   table: {
  //     headers: ["Name", "Age", "City"],
  //     rows: [
  //       ["Karen Miller", 27, "Houston"],
  //       ["Leo Scott", 33, "Dallas"],
  //       ["Mia Carter", 29, "San Diego"],
  //     ],
  //   },
  //   quiz: [
  //     {
  //       question: "Which continent is known as the 'Land of the Rising Sun'?",
  //       options: ["Asia", "Europe", "Africa", "Australia"],
  //       answer: "Asia",
  //     },
  //     {
  //       question: "What is the currency of Brazil?",
  //       options: ["Peso", "Yen", "Real", "Dollar"],
  //       answer: "Real",
  //     },
  //   ],
  //   tasks: [
  //     {
  //       name: "Buy birthday gift",
  //       completed: true,
  //       time_estimate: 2,
  //       pending_date: null,
  //     },
  //     {
  //       name: "Attend workshop",
  //       completed: false,
  //       time_estimate: 2,
  //       pending_date: new Date().toLocaleDateString(),
  //     },
  //     {
  //       name: "Cook dinner",
  //       completed: true,
  //       time_estimate: 2,
  //       pending_date: null,
  //     },
  //   ],
  // },
];
