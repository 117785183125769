import { Box, Text, Spinner, Center } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import useGetTest from "../utils/getTests";
import useGetMockTestSubs from "../utils/getMockTestSubs";

const MockPaperSubjects = () => {
  const { fetchTest } = useGetTest();
  const { fetchMockTestSubs, isLoading, setIsLoading, errorsub } =
    useGetMockTestSubs();
  const [test, setTest] = useState([]);
  const [testSubs, setTestSubs] = useState([]);
  const [testClass, setTestClass] = useState([]);

  console.log("hello mock test");

  const id = 4;
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res1 = await fetchTest(id);
      setTest(res1?.test?.questions);

      const res2 = await fetchMockTestSubs(2, "rahulk30s@gmail.com");
      setTestSubs(res2?.test);
      setIsLoading(false);
    };

    fetchData();
  }, [id]);

  console.log("sub", isLoading);

  const RenderSubs = () => {
    return testSubs?.map((test, i) => (
      <Box
        as="a"
        key={i}
        href={`/library/mock-papers/${test.subjectName}/${i}`}
        fontSize={"md"}
        fontWeight={"semibold"}
        boxShadow={"sm"}
        display={"block"}
        borderWidth={"2px"}
        borderRadius={"lg"}
      >
        <Text backgroundColor={"purple.200"} padding={2} margin={0}>
          {i + 1 + ". "}
          {test.subjectName}
        </Text>
      </Box>
    ));
  };

  return (
    <>
      <Box
        display={"flex"}
        width={"full"}
        flexDir={"column"}
        padding={8}
        margin={8}
      >
        <Box width={"full"}>
          <Box
            backgroundColor={"purple.100"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            padding={2}
            borderRadius={"md"}
            borderWidth={"2px"}
            borderTopColor={"purple.300"}
            boxShadow={"md"}
          >
            <Text
              fontSize={"xl"}
              fontWeight={"bold"}
              letterSpacing={"2px"}
              margin={"0"}
            >
              Mock Test Subjects
            </Text>
          </Box>
        </Box>

        <Box
          display={"flex"}
          flexWrap={"wrap"}
          flexDir={"row"}
          gap={4}
          marginTop={8}
        >
          {isLoading ? (
            <Center ml={"455px"} mt={8}>
              <Spinner color="purple.500" />
            </Center>
          ) : Object.keys(errorsub).length > 0 ? (
            "subjects not found"
          ) : (
            <RenderSubs />
          )}
        </Box>
      </Box>
    </>
  );
};

export default MockPaperSubjects;
