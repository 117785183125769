import React, {useEffect, useState} from "react";
import {Box, Container, Flex, Heading, Image, Select, Wrap, WrapItem} from "@chakra-ui/react";
import H1 from "../../../../Components/Typography/Heading/H1";
import Psm from "../../../../Components/Typography/Text/Psm";
import InputOutline from "../../../../Components/Input/InputOutline";
import ButtonPrimary from "../../../../Components/Button/ButtonPrimary";
import ButtonSecondary from "../../../../Components/Button/ButtonSecondary";
import H3 from "../../../../Components/Typography/Heading/H3";
import Slider from "react-slick";
import Pmd from "../../../../Components/Typography/Text/Pmd";
import H2 from "../../../../Components/Typography/Heading/H2";
import Modal from "../../../../Components/Modal";
import InputTextAreaOutline from "../../../../Components/Input/InputTextAreaOutline";
import axios from "axios";
import {NEW_API_URL} from "../../../../redux/types";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Markdown from 'react-markdown'
import PracticeQuizeSubjects from "../../ExamPracticeMAterialComps/components/PracticePaperSubjects";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import PracticePaperTestPage from "../../ExamPracticeMAterialComps/components/PracticePaperTestPage";
import P2xl from "../../../../Components/Typography/Text/P2xl";
import FlipCard from "../../../../Components/FlipCard";
const dummyNotes = [
    { topic: "JavaScript Basics", subtopic: "Variables and Data Types" },
    { topic: "HTML", subtopic: "Document Structure" },
    { topic: "CSS", subtopic: "Styling and Layout" },
    { topic: "React", subtopic: "Component Lifecycle" },
    { topic: "Node.js", subtopic: "Server-side JavaScript" },
];

var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


const markdown_str='---\n' +
    '__Advertisement :)__\n' +
    '\n' +
    '- __[pica](https://nodeca.github.io/pica/demo/)__ - high quality and fast image\n' +
    '  resize in browser.\n' +
    '- __[babelfish](https://github.com/nodeca/babelfish/)__ - developer friendly\n' +
    '  i18n with plurals support and easy syntax.\n' +
    '\n' +
    'You will like those projects!\n' +
    '\n' +
    '---\n' +
    '\n' +
    '# h1 Heading 8-)\n' +
    '## h2 Heading\n' +
    '### h3 Heading\n' +
    '#### h4 Heading\n' +
    '##### h5 Heading\n' +
    '###### h6 Heading\n' +
    '\n' +
    '\n' +
    '## Horizontal Rules\n' +
    '\n' +
    '___\n' +
    '\n' +
    '---\n' +
    '\n' +
    '***\n' +
    '\n' +
    '\n' +
    '## Typographic replacements\n' +
    '\n' +
    'Enable typographer option to see result.\n' +
    '\n' +
    '(c) (C) (r) (R) (tm) (TM) (p) (P) +-\n' +
    '\n' +
    'test.. test... test..... test?..... test!....\n' +
    '\n' +
    '!!!!!! ???? ,,  -- ---\n' +
    '\n' +
    '"Smartypants, double quotes" and \'single quotes\'\n' +
    '\n' +
    '\n' +
    '## Emphasis\n' +
    '\n' +
    '**This is bold text**\n' +
    '\n' +
    '__This is bold text__\n' +
    '\n' +
    '*This is italic text*\n' +
    '\n' +
    '_This is italic text_\n' +
    '\n' +
    '~~Strikethrough~~\n' +
    '\n' +
    '\n' +
    '## Blockquotes\n' +
    '\n' +
    '\n' +
    '> Blockquotes can also be nested...\n' +
    '>> ...by using additional greater-than signs right next to each other...\n' +
    '> > > ...or with spaces between arrows.\n' +
    '\n' +
    '\n' +
    '## Lists\n' +
    '\n' +
    'Unordered\n' +
    '\n' +
    '+ Create a list by starting a line with `+`, `-`, or `*`\n' +
    '+ Sub-lists are made by indenting 2 spaces:\n' +
    '  - Marker character change forces new list start:\n' +
    '    * Ac tristique libero volutpat at\n' +
    '    + Facilisis in pretium nisl aliquet\n' +
    '    - Nulla volutpat aliquam velit\n' +
    '+ Very easy!\n' +
    '\n' +
    'Ordered\n' +
    '\n' +
    '1. Lorem ipsum dolor sit amet\n' +
    '2. Consectetur adipiscing elit\n' +
    '3. Integer molestie lorem at massa\n' +
    '\n' +
    '\n' +
    '1. You can use sequential numbers...\n' +
    '1. ...or keep all the numbers as `1.`\n' +
    '\n' +
    'Start numbering with offset:\n' +
    '\n' +
    '57. foo\n' +
    '1. bar\n' +
    '\n' +
    '\n' +
    '## Code\n' +
    '\n' +
    'Inline `code`\n' +
    '\n' +
    'Indented code\n' +
    '\n' +
    '    // Some comments\n' +
    '    line 1 of code\n' +
    '    line 2 of code\n' +
    '    line 3 of code\n' +
    '\n' +
    '\n' +
    'Block code "fences"\n' +
    '\n' +
    '```\n' +
    'Sample text here...\n' +
    '```\n' +
    '\n' +
    'Syntax highlighting\n' +
    '\n' +
    '``` js\n' +
    'var foo = function (bar) {\n' +
    '  return bar++;\n' +
    '};\n' +
    '\n' +
    'console.log(foo(5));\n' +
    '```\n' +
    '\n' +
    '## Tables\n' +
    '\n' +
    '| Option | Description |\n' +
    '| ------ | ----------- |\n' +
    '| data   | path to data files to supply the data that will be passed into templates. |\n' +
    '| engine | engine to be used for processing templates. Handlebars is the default. |\n' +
    '| ext    | extension to be used for dest files. |\n' +
    '\n' +
    'Right aligned columns\n' +
    '\n' +
    '| Option | Description |\n' +
    '| ------:| -----------:|\n' +
    '| data   | path to data files to supply the data that will be passed into templates. |\n' +
    '| engine | engine to be used for processing templates. Handlebars is the default. |\n' +
    '| ext    | extension to be used for dest files. |\n' +
    '\n' +
    '\n' +
    '## Links\n' +
    '\n' +
    '[link text](http://dev.nodeca.com)\n' +
    '\n' +
    '[link with title](http://nodeca.github.io/pica/demo/ "title text!")\n' +
    '\n' +
    'Autoconverted link https://github.com/nodeca/pica (enable linkify to see)\n' +
    '\n' +
    '\n' +
    '## Images\n' +
    '\n' +
    '![Minion](https://octodex.github.com/images/minion.png)\n' +
    '![Stormtroopocat](https://octodex.github.com/images/stormtroopocat.jpg "The Stormtroopocat")\n' +
    '\n' +
    'Like links, Images also have a footnote style syntax\n' +
    '\n' +
    '![Alt text][id]\n' +
    '\n' +
    'With a reference later in the document defining the URL location:\n' +
    '\n' +
    '[id]: https://octodex.github.com/images/dojocat.jpg  "The Dojocat"\n' +
    '\n' +
    '\n' +
    '## Plugins\n' +
    '\n' +
    'The killer feature of `markdown-it` is very effective support of\n' +
    '[syntax plugins](https://www.npmjs.org/browse/keyword/markdown-it-plugin).\n' +
    '\n' +
    '\n' +
    '### [Emojies](https://github.com/markdown-it/markdown-it-emoji)\n' +
    '\n' +
    '> Classic markup: :wink: :cry: :laughing: :yum:\n' +
    '>\n' +
    '> Shortcuts (emoticons): :-) :-( 8-) ;)\n' +
    '\n' +
    'see [how to change output](https://github.com/markdown-it/markdown-it-emoji#change-output) with twemoji.\n' +
    '\n' +
    '\n' +
    '### [Subscript](https://github.com/markdown-it/markdown-it-sub) / [Superscript](https://github.com/markdown-it/markdown-it-sup)\n' +
    '\n' +
    '- 19^th^\n' +
    '- H~2~O\n' +
    '\n' +
    '\n' +
    '### [\\<ins>](https://github.com/markdown-it/markdown-it-ins)\n' +
    '\n' +
    '++Inserted text++\n' +
    '\n' +
    '\n' +
    '### [\\<mark>](https://github.com/markdown-it/markdown-it-mark)\n' +
    '\n' +
    '==Marked text==\n' +
    '\n' +
    '\n' +
    '### [Footnotes](https://github.com/markdown-it/markdown-it-footnote)\n' +
    '\n' +
    'Footnote 1 link[^first].\n' +
    '\n' +
    'Footnote 2 link[^second].\n' +
    '\n' +
    'Inline footnote^[Text of inline footnote] definition.\n' +
    '\n' +
    'Duplicated footnote reference[^second].\n' +
    '\n' +
    '[^first]: Footnote **can have markup**\n' +
    '\n' +
    '    and multiple paragraphs.\n' +
    '\n' +
    '[^second]: Footnote text.\n' +
    '\n' +
    '\n' +
    '### [Definition lists](https://github.com/markdown-it/markdown-it-deflist)\n' +
    '\n' +
    'Term 1\n' +
    '\n' +
    ':   Definition 1\n' +
    'with lazy continuation.\n' +
    '\n' +
    'Term 2 with *inline markup*\n' +
    '\n' +
    ':   Definition 2\n' +
    '\n' +
    '        { some code, part of Definition 2 }\n' +
    '\n' +
    '    Third paragraph of definition 2.\n' +
    '\n' +
    '_Compact style:_\n' +
    '\n' +
    'Term 1\n' +
    '  ~ Definition 1\n' +
    '\n' +
    'Term 2\n' +
    '  ~ Definition 2a\n' +
    '  ~ Definition 2b\n' +
    '\n' +
    '\n' +
    '### [Abbreviations](https://github.com/markdown-it/markdown-it-abbr)\n' +
    '\n' +
    'This is HTML abbreviation example.\n' +
    '\n' +
    'It converts "HTML", but keep intact partial entries like "xxxHTMLyyy" and so on.\n' +
    '\n' +
    '*[HTML]: Hyper Text Markup Language\n' +
    '\n' +
    '### [Custom containers](https://github.com/markdown-it/markdown-it-container)\n' +
    '\n' +
    '::: warning\n' +
    '*here be dragons*\n' +
    ':::\n'

export default function NotesDetail() {
    const navigate = useNavigate();

    const [showAdd, setShowAdd] = useState(false);
    const username = useSelector(state => state.user.username);
    const [lessonPlanList, setLessonPlanList] = useState([]);
    const [topicIdx, setTopicIdx] = useState(-1);
    const [subtopicIdx, setSubtopicIdx] = useState(-1);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [note, setNote] = useState('');
    const [page, setPage] = useState(0);
    const [maxPages, setMaxPages] = useState(0);

    const [noteDetail, setNoteDetail] = useState({
        pages:['PG1'+markdown_str,'PG2'+markdown_str,'PG3'+markdown_str],
        qa:[{question:'aaaa',answer:'test'},{question:'bbbb',answer:'aa'}]
    });


    useEffect(() => {
        if(noteDetail){
            setMaxPages(noteDetail.pages.length+1)
        }
    }, [noteDetail]);

    const selectedWorkspace = useSelector(state => state.user.selectedWorkspace);
    let workspaceId = '';
    if(selectedWorkspace && selectedWorkspace.hasOwnProperty('workspace_id')){
        workspaceId = selectedWorkspace.workspace_id
    }
    const getLessonPlan = async () => {
        setFetchLoading(true);
        try {
            await axios
                .get(
                    `${NEW_API_URL}lessonplan/get_lessonplan/${username}/${workspaceId}/`
                )
                .then((response) => {
                    // console.log("lesson plan res --->", response.data.lesson_plan);
                    if (response.status === 200) {
                        setLessonPlanList(response.data.lesson_plan.topics);
                    }
                    // setTaskList(response?.data?.metrics?.todays_tasks?.tasks);
                })
                .catch((error) => {
                    console.error("lesson plan error --->", error);
                    // Toast.show({
                    //   type: "error",
                    //   text1: "Error",
                    //   text2: "Couldn't load data",
                    // });
                });
        } catch (e) {
            console.error(e);
            // Toast.show({
            //   type: "error",
            //   text1: "Error",
            //   text2: "Something unexpected happened",
            // });
        } finally {
            setFetchLoading(false);
        }
    };

    useEffect(() => {
        getLessonPlan();
    }, []);


    const addNote = async () => {
        try {

            const url = `${NEW_API_URL}notes/create_note/${workspaceId}/`;

            const body = {
                content: JSON.stringify({
                    note: note,
                    subject: lessonPlanList[topicIdx],
                    subtopic: lessonPlanList[topicIdx][subtopicIdx],
                }),
                username: username,
            };

            console.log("url:", url, "\nbody:", body);


            const response = await axios.post(url, body);
            console.log("add note response", response);

        } catch (error) {
            console.log("add note error:", error);
        }
    };


    console.log('topics',lessonPlanList)

    return (
        <Flex
            width={{base: "100%", xl: "80%"}}
            maxWidth={{base: "100%", xl: "80%"}}

            background={"claydeck.white.primary"} justifyContent={'center'}>

            <Box w={'80%'}>

                {page >0 && <ButtonSecondary
                    style={{margin: 10}}
                    onClick={() => {
                        setPage(page-1)
                    }}
                >
                    Prev {'<'}
                </ButtonSecondary>}

                {page < maxPages && <ButtonSecondary
                    style={{margin: 10}}
                    onClick={() => {
                        setPage(page + 1)
                    }}
                >
                    Next >
                </ButtonSecondary>}


                {noteDetail.pages.length > page && <Markdown>{noteDetail.pages[page]}</Markdown>}
                {console.log(page,noteDetail.pages.length,maxPages)}
                {noteDetail.pages.length === page && <>
                    <P2xl>QA</P2xl>


                    {
                        noteDetail.qa.map((item)=>(
                            <div style={{padding:10}}>
                            <FlipCard
                                frontContent={item.question}
                                backContent={item.answer}
                            />
                            </div>
                        ))
                    }
                    </>}
                {noteDetail.pages.length+1 === page && <PracticePaperTestPage/>}



                <Modal show={showAdd} handleClose={() => {
                    setShowAdd(false)
                }}>
                    <H2>Add Note</H2>
                    <InputTextAreaOutline onChange={(e) => {
                        // setTopicName(e.target.value)
                        setNote(e.target.value)
                    }} style={{marginTop: 10, marginBottom: 10}} placeholder={'Enter sub topic name'}/>

                    <Select placeholder='Topic' mt={2} onChange={(item)=>{
                        setTopicIdx(lessonPlanList.findIndex(x=>x.name===item.target.value));
                        // console.log()

                    }}>
                        {
                            !fetchLoading && lessonPlanList.map((i=>(
                                <option value={i.name}>{i.name}</option>

                            )))
                        }
                    </Select>

                    <Select placeholder='Sub Topic' mt={2} disabled={topicIdx<0}>
                        {
                            !fetchLoading && topicIdx >=0 && lessonPlanList[topicIdx].subtopics.map((i=>(
                                <option value={i.name}>{i.name}</option>

                            )))
                        }
                    </Select>

                    <ButtonSecondary
                        style={{width:'100%',marginTop:'10px'}}

                        onClick={() => {
                            addNote()
                            // alert("Don't press me :(");
                        }}
                    >
                        Create Note
                    </ButtonSecondary>

                </Modal>


            </Box>
        </Flex>
    );
}
