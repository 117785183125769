import { Box, Button, Container, Flex, Input, Text } from "@chakra-ui/react";
import Editor from "./Editor.js";
import { useState } from "react";
import RemoveBin from "../../svg/RemoveBin.jsx";
import useForumPostData from "../forum/utils/useForumPostData.js";
import ButtonTag from "../../Components/Button/ButtonTag.js";
import { useSelector, useDispatch } from "react-redux";
import { nanoid } from "nanoid";
import { Spinner } from "@chakra-ui/react";
import { SET_FORUM_ACTIVETAGS } from "../../redux/types.js";

const Compose = () => {
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const tags = useSelector((state) => state.forumData.activeTags);

  const { postForumData, error } = useForumPostData();

  const detailedText = `<h1>${title}</h1>${content}`;

  const dateISOString = new Date().toISOString();

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const body = new FormData();
  if (files.length > 0) {
    files.forEach((file) => {
      body.append("pdfs", file);
    });
  }
  const imageFile = new File([""], "/assets/images/profile.png");
  body.append("image", imageFile);

  body.append("timestamp", dateISOString);

  body.append("author_id", nanoid());

  body.append("detailed_text", detailedText);

  body.append("name", "rahul7@gmail.com");

  body.append("tags", tags);

  body.append("text", title);

  const handlePostData = async () => {
    try {
      setIsLoading(true);
      const response = await postForumData(body);
      setIsLoading(false);

      console.log("response:", response);

      setContent("");
      setFiles([]);
      setTitle("");
      dispatch({ type: SET_FORUM_ACTIVETAGS, payload: [] });
    } catch (err) {
      console.log(err.message, error);
      setIsLoading(false);
    }
  };

  return (
    <Container minWidth={"70%"} paddingX={"22px"} paddingY={"48px"}>
      <Input
        required
        marginBottom={"12px"}
        type="text"
        placeholder="Title here..."
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />

      <Editor
        setEditorState={setContent}
        editorState={content}
        placeholder={"Write something..."}
      />
      <Box display={"flex"} justifyContent={"space-between"} padding={"12px"}>
        <Flex direction={"column"}>
          <Button
            as="label"
            bg={"#eaecec"}
            cursor={"pointer"}
            _hover={{
              shadow: "md",
            }}
            htmlFor="file"
          >
            Upload Files
            <Input
              multiple
              id="file"
              type="file"
              display={"none"}
              onChange={handleFileChange}
            />
          </Button>
          <Box>
            <Text marginTop={"4px"} fontWeight="bold">
              Selected Files:
            </Text>
            {files.length > 0 ? (
              <Box>
                {files.map((file, index) => (
                  <Flex
                    fontSize={"xs"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginTop={"4px"}
                    padding={"4px"}
                    border="1px solid #eaecec"
                    borderRadius={"10px"}
                    key={index}
                  >
                    <Text>{file.name}</Text>
                    <Button
                      variant="link"
                      onClick={() => handleRemoveFile(index)}
                    >
                      <RemoveBin />
                    </Button>
                  </Flex>
                ))}
              </Box>
            ) : (
              "No files selected"
            )}
          </Box>
        </Flex>
        <Container display={"flex"} flexWrap={"wrap"} gap={"2"}>
          <ButtonTag variant="outline" buttonText={"All"} />
          <ButtonTag variant="outline" buttonText={"Trending"} />
          <ButtonTag variant="outline" buttonText={"Questions"} />
          <ButtonTag variant="outline" buttonText={"Ideas"} />
          <ButtonTag variant="outline" buttonText={"Solutions"} />
          <ButtonTag variant="outline" buttonText={"UPSC"} />
          <ButtonTag variant="outline" buttonText={"Current Affair"} />
          <ButtonTag variant="outline" buttonText={"NEWS"} />
          <ButtonTag variant="outline" buttonText={"Political"} />
          <ButtonTag variant="outline" buttonText={"SENSEX"} />
          <ButtonTag variant="outline" buttonText={"International"} />
          <ButtonTag variant="outline" buttonText={"Other"} />
        </Container>
        <Button
          onClick={() => handlePostData()}
          paddingX={"16px"}
          bg={"#eaecec"}
          _hover={{
            shadow: "md",
          }}
        >
          {isLoading ? <Spinner size={"md"} /> : "Publish"}
        </Button>
      </Box>
    </Container>
  );
};

export default Compose;
