import moment from "moment";

export const sortProfileData = (data) => {
    const studyHabits = data?.study_hours_week;

    return {
      firstName: data?.first_name ? data.first_name : "",
      lastName: data?.last_name ? data.last_name : "",
      phoneNumber: data?.phone_number ? data.phone_number : "",
      studyHabits: {
        monday: studyHabits?.monday ? studyHabits.monday : "0",
        tuesday: studyHabits?.tuesday ? studyHabits.tuesday : "0",
        wednesday: studyHabits?.wednesday ? studyHabits.wednesday : "0",
        thursday: studyHabits?.thursday ? studyHabits.thursday : "0",
        friday: studyHabits?.friday ? studyHabits.friday : "0",
        saturday: studyHabits?.saturday ? studyHabits.saturday : "0",
        sunday: studyHabits?.sunday ? studyHabits.sunday : "0",
        // monday: studyHabits?.monday
        //   ? moment(studyHabits.monday).toDate()
        //   : new Date(),
        // tuesday: studyHabits?.tuesday
        //   ? moment(studyHabits.tuesday).toDate()
        //   : new Date(),
        // wednesday: studyHabits?.wednesday
        //   ? moment(studyHabits.wednesday).toDate()
        //   : new Date(),
        // thursday: studyHabits?.thursday
        // ? moment(studyHabits.thursday).toDate()
        //   : new Date(),
        // friday: studyHabits?.friday
        //   ? moment(studyHabits.friday).toDate()
        //   : new Date(),
        // saturday: studyHabits?.saturday
        //   ? moment(studyHabits.sunday).toDate()
        //   : new Date(),
        // sunday: studyHabits?.sunday
        //   ? moment(studyHabits.sunday).toDate()
        //   : new Date(),
      },
      examDate: data?.exam_date ? moment(data.exam_date).toDate() : "",
    };
}
