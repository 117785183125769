import "./Modal.css";
import close from "../assets/close.png";

const Modal = ({ handleClose, show, children,style }) => {
  const showHideClassName = show ? "display-block" : "display-none";
  return (
    <div
      className={showHideClassName}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        width: "100%",
        top: 0,
        right: 0,
        height: "100%",
        position: "fixed",
        zIndex: 2,

      }}
    >
      <section className="modal-main" style={{maxHeight: '80vh',...style}}>
        <img
          onClick={handleClose}
          src={close}
          className="close-img cursor"
          alt="dot"
        />
        {children}
      </section>
    </div>
  );
};
export default Modal;
